import React, { useContext, useMemo } from 'react';
import { useTheme } from '@material-ui/styles';
import { useStoreState } from 'easy-peasy';
import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import { formatCurrency } from '../../utils/numbers-utils';

const TotalGain = ({ isMobile, orderApproved }) => {
  const { translate } = useContext(I18nContext);
  const { originalData } = useStoreState((state) => state.sales);
  const { color } = useTheme();

  const totalGain = useMemo(() => {
    let totalGainLocal = 0;
    if (originalData && originalData.orders) {
      originalData.orders.forEach((item) => {
        // eslint-disable-next-line max-len
        const hasPaidStatus = Array.isArray(item.statusHistory) && item.statusHistory.some((historyStatus) => historyStatus.includes('91 Paid'));
        if (item.statusOrder[0].toString() !== '8' && !item.statusOrder.includes('FAILED') && hasPaidStatus) {
          totalGainLocal += parseFloat(item.cnProfitValue, 10);
        }
      });
    }
    return totalGainLocal;
  }, [originalData.orders]);

  return (
    <>
      <FlexBox
        borderRight={!isMobile && `1px solid ${color.lowEmphasis}`}
        borderBottom={isMobile && `1px solid ${color.lowEmphasis}`}
        m={!isMobile && '10px 0'}
        p={isMobile ? '10px 0' : '0 16px 0 0'}
        flex="1"
        mediaQ="totalGain"
      >
        <FlexBox flexDirection="column">
          <FlexBox flex="1" justifyContent="space-between" alignItems="center">
            <Typography margin="0px 0px 30px 0px" flex="1" minWidth="400px">
              {translate('sales.resume.total_gain.title')}
            </Typography>
          </FlexBox>
          <Typography>
            {`${orderApproved} `}
            {translate('sales.resume.total_gain.approved_sales')}
          </Typography>
        </FlexBox>
        <FlexBox flexDirection="column" alignItems={!isMobile ? 'center' : 'flex-end'} justifyContent="center">
          <Typography
            whiteSpace="nowrap"
            mediaQ
            variant={isMobile ? 'h6' : 'h4'}
          >
            {formatCurrency(totalGain)}
          </Typography>
          <Typography whiteSpace="nowrap">{translate('sales.resume.total_gain.subtitle')}</Typography>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default TotalGain;
