import React, { useContext, useState } from 'react';
import { makeStyles } from '@naturacosmeticos/natds-web';
import Card from '../../../components/card/Card';
import Typography from '../../../components/typography/Typography';
import FlexBox from '../../../components/flex-box/FlexBox';
import { I18nContext } from '../../../config/i18n';
import { formatCurrency } from '../../../utils/numbers-utils';
import { formatDateIso } from '../../../utils/date-utils';

const useStyles = makeStyles((theme) => ({
  styledCard: {
    backgroundColor: theme.color.background,
  },
}));

const OrderData = ({
  data,
  paymentTypesTranslate,
  trackingStatus,
}) => {
  const { styledCard } = useStyles();
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);

  return (
    <Card m={isMobile ? '5px 0' : '5px 0 5px 5px'} className={styledCard} noShadow>
      <Typography variant="h5">{translate('sales.order_data.title')}</Typography>
      <FlexBox m="10px 0" flexDirection={isMobile && 'column'}>
        <FlexBox flexDirection="column">
          <Typography>{translate('sales.order_data.payment')}</Typography>
          <Typography>{translate(paymentTypesTranslate[data.paymentMethodId])}</Typography>
        </FlexBox>
      </FlexBox>
      <FlexBox m="10px 0" flexDirection={isMobile && 'column'}>
        <FlexBox flexDirection="column">
          <Typography>{translate('sales.order_data.sale_date')}</Typography>
          <Typography>{formatDateIso(data.createDate)}</Typography>
        </FlexBox>
        <FlexBox flexDirection="column">
          <Typography>{translate('sales.order_data.price_without_delivery')}</Typography>
          <Typography>
            {formatCurrency(data.totalProductValue)}
          </Typography>
        </FlexBox>
      </FlexBox>
      <FlexBox m="10px 0" flexDirection={isMobile && 'column'}>
        <FlexBox flexDirection="column">
          <Typography>{translate('sales.order_data.coupon')}</Typography>
          <Typography>{translate(data.couponUsed ? 'yes_confirm' : 'no_confirm')}</Typography>
        </FlexBox>
        <FlexBox flexDirection="column">
          <Typography>{translate('sales.order_data.order_state')}</Typography>
          <Typography>
            {translate(trackingStatus.titleLabel)}
            {/* {translate(orderStatusTranslate[data.stateOrder] && orderStatusTranslate[data.stateOrder].text)} */}
          </Typography>
        </FlexBox>
      </FlexBox>
    </Card>
  );
};

export default OrderData;
