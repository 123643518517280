import React, {
  lazy, Suspense, useEffect, useState,
} from 'react';
import { useStoreState } from 'easy-peasy';
import { Carousel } from 'react-responsive-carousel';
import { makeStyles } from '@material-ui/core';

// eslint-disable-next-line no-return-await
const Item = lazy(async () => await import('./Item'));

const useStyles = makeStyles(() => ({
  carousel: {
    width: '100%',
    '& > .carousel': {
      display: 'none',
    },
    '& > .carousel-slider': {
      display: 'block',
    },
    '& > .carousel .control-next.control-arrow:before': {
      'border-left': ({ theme }) => (theme === 'dark' ? '8px solid #fff' : '8px solid black'),
    },
    '& > .carousel .control-prev.control-arrow:before': {
      'border-right': ({ theme }) => (theme === 'dark' ? '8px solid #fff' : '8px solid black'),
    },
    '& > .carousel.carousel-slider .control-arrow:hover': {
      background: 'transparent',
    },
    '& > .slider-wrapper .axis-horizontal': {
      'margin-bottom': '25px',
    },
  },
}));

const CarouselBanner = ({ items }) => {
  const [itensState, setItensState] = useState(items);
  const [isMobile] = useState(window.innerWidth < 1080);
  const { theme } = useStoreState((actions) => actions.main);
  const classes = useStyles({ theme });

  useEffect(() => {
    if (isMobile) {
      const newListItens = items.map((e) => {
        if (!e.buttonText && !e.image.mobile) {
          return null;
        }
        return e;
      }).filter((a) => a);
      setItensState(newListItens);
    }
  },
  [items]);

  if (items.length === 0) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <Carousel
        autoPlay
        infiniteLoop
        className={classes.carousel}
        showStatus={false}
        showThumbs={false}
      >
        {itensState.map((item) => (
          <Item
            image={{ desktop: item?.image?.desktop, mobile: item?.image?.mobile }}
            url={item.url}
            title={item.title}
            text={item.text}
            buttonText={item.buttonText}
            isDesktop={!isMobile}
            key={item.url}
          />
        ))}
      </Carousel>
    </Suspense>
  );
};

export default CarouselBanner;
