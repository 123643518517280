import { useEffect } from 'react';

const GoogleTagManager = () => {
  useEffect(() => {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtm.js?id=GTM-5978ZCG';
    script.async = true;
    document.head.appendChild(script);

    window.dataLayer.push({
      dataLayerVersion: '1.2',
    });
  }, []);

  return null;
};

export default GoogleTagManager;
