import { makeStyles } from '@naturacosmeticos/natds-web';

export const filterBarStyle = makeStyles((theme) => ({
  periodButton: {
    '& .MuiFormHelperText-root': {
      color: theme.color.alert,
    },
    flexWrap: 'wrap',
  },
  clearBtn: {
    padding: '0',
    minWidth: '35px',
    height: '29px',
    marginLeft: '5px',
  },
  bottomButtonHolder: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
    borderRadius: '0',
    justifyContent: 'space-between',
    '& .MuiButton-root': {
      borderRadius: 0,
    },
  },
  bottomButton: {
    flex: '1',
    width: '100%',
  },
}));
