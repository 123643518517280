import React, { useEffect } from 'react';
import {
  Route, Switch, useLocation, useHistory,
} from 'react-router-dom';
import { useStoreActions } from 'easy-peasy';
import PrivateRoute from './PrivateRoute';
import Customers from '../../pages/customers/Customers';
import configBlockedRoutes from './ConfigBlockedRoutes';
import Visits from '../../pages/visits/Visits';
import Profile from '../../pages/profile/Profile';
import NotFound from '../../pages/general/NotFound';
import Coupons from '../../pages/coupons/Coupons';
import Home from '../../pages/home';
import NewCoupon from '../../pages/coupons/new-coupon/NewCoupon';
import Sales from '../../pages/sales/Sales';
import SalesDetail from '../../pages/sales/sales-detail/SalesDetail';
import Callback from './Callback';
import AbandonedOrders from '../../pages/abandoned-orders/AbandonedOrders';
import EditDigitalSpace from '../../pages/edit-digital-space/EditDigitalSpace';
import EarningsCalendar from '../../pages/earnings-calendar';
import config from '../../config/config';
import { handleAuthentication } from './auth';
import { pushVirtualPageView } from '../../data-layer/pageView';
import Login from '../../pages/auth/Login';
import ForgotPassword from '../../pages/auth/ForgotPassword';

const blockedRoutes = configBlockedRoutes();

const AppRoutes = () => {
  const location = useLocation();
  const history = useHistory();
  const { handleLogin, handleNaturaLogin } = useStoreActions((actions) => actions.main);
  useEffect(() => {
    pushVirtualPageView(location.pathname);
    // do not show main screen, redirect to sales
    if (!config.general.showHome && location.pathname === '/') {
      history.push('/sales');
    }
  }, [location]);

  const routes = [
    {
      path: '/customers',
      component: Customers,
    },
    {
      path: '/coupons',
      component: Coupons,
    },
    {
      path: '/coupons/new',
      component: NewCoupon,
    },
    {
      path: '/visits',
      component: Visits,
    },
    {
      path: '/sales/:orderNumber',
      component: SalesDetail,
    },
    {
      path: '/sales',
      component: Sales,
    },
    {
      path: '/abandoned',
      component: AbandonedOrders,
    },
    {
      path: '/profile',
      component: Profile,
    },
    {
      path: '/digital-space',
      component: EditDigitalSpace,
    },
    {
      path: '/earning-calendar',
      component: EarningsCalendar,
    },
  ];

  const showRoutes = routes.map((route) => {
    if (blockedRoutes && blockedRoutes.some((item) => item === route.path)) return false;
    return <PrivateRoute key={route.path} exact path={route.path} component={route.component} />;
  });
  return (
    <Switch>
      <PrivateRoute exact path="/" component={Home} />
      {showRoutes}
      <Route
        path="/callback"
        render={(props) => {
          handleAuthentication(props, handleLogin, handleNaturaLogin);
          return <Callback {...props} />;
        }}
      />
      <Route
        path="/login"
        component={Login}
      />
      <Route
        path="/logout"
        component={() => {
          window.location.href = config.auth.logoutUrl;
          return null;
        }}
      />
      <Route
        path="/forgot-password"
        component={ForgotPassword}
      />
      <Route
        path="/first-access"
        component={ForgotPassword}
      />
      <Route path="*" component={NotFound} />
    </Switch>
  );
};

export default AppRoutes;
