import React, { useContext, useState } from 'react';
import { useTheme } from '@material-ui/styles';
import Card from '../../../components/card/Card';
import Typography from '../../../components/typography/Typography';
import { I18nContext } from '../../../config/i18n';
import FlexBox from '../../../components/flex-box/FlexBox';
import TrackingRealizedIco from '../../../assets/tracking-realized-ico.svg';
import TrackingWaitingIco from '../../../assets/tracking-waiting-ico.svg';
import TrackingAuthorizedIco from '../../../assets/tracking-authorized-ico.svg';
import TrackingInvoicedIco from '../../../assets/tracking-invoiced-ico.svg';
import TrackingTransportationIco from '../../../assets/tracking-transportation-ico.svg';
import TrackingDeliveredIco from '../../../assets/tracking-delivered-ico.svg';
import TrackingGreenBg from '../../../assets/tracking-green-bg.svg';
import TrackingGrayBg from '../../../assets/tracking-gray-bg.svg';
import { orderStatusCardStyle } from './OrderStatusCard.style';
import { formatDateIso } from '../../../utils/date-utils';
// trackingState
const steps = ['aa', 'bb', 'cc', 'dd', 'ee', 'ff'];

const OrderStatusCard = ({ data, trackingStatus }) => {
  const { translate } = useContext(I18nContext);
  const { color } = useTheme();

  const green = '#8ebe2f';
  const grey = '#bbb';

  const icons = [
    TrackingRealizedIco,
    TrackingWaitingIco,
    TrackingAuthorizedIco,
    TrackingInvoicedIco,
    TrackingTransportationIco,
    TrackingDeliveredIco,
  ];

  const [isMobile] = useState(window.innerWidth < 1080);

  const { styledCard, xCancel } = orderStatusCardStyle();
  const actualColor = trackingStatus.canceled ? grey : green;

  return (
    <Card m={isMobile ? '5px 0' : '5px 5px 5px 0px'} className={styledCard} noShadow>
      <Typography variant="h5">{translate('sales.order_status.title')}</Typography>
      <FlexBox m="10px 0" flexDirection={isMobile && 'column'}>
        <FlexBox flexDirection="column">
          <Typography>{translate('sales.order_status.order_date_label')}</Typography>
          <Typography>{formatDateIso(data.createDate)}</Typography>
        </FlexBox>
        <FlexBox flexDirection="column">
          <Typography>{translate('sales.order_status.delivery_date_label')}</Typography>
          <Typography>{formatDateIso(data.deliveryDate)}</Typography>
        </FlexBox>
      </FlexBox>
      <FlexBox minHeight="86px" overflow="hidden" m="30px 0 0 0" alignItems="center" borderRadius="5px">
        {icons.map((icon, index) => (
          <FlexBox key={icon}>
            <FlexBox
              height="86px"
              zIndex="3"
              p={isMobile ? '0' : '10px'}
              alignItems="center"
              justifyContent="center"
              backgroundColor={
                index <= steps.indexOf(trackingStatus.step) && actualColor
              }
            >
              <img
                src={icon}
                alt="tracking img"
              />
              {index === steps.indexOf(trackingStatus.step) && trackingStatus.canceled
              && (
                <FlexBox position="relative" c={color.alert}>
                  <span className={xCancel}>x</span>
                </FlexBox>
              )}
            </FlexBox>
            {index === steps.indexOf(trackingStatus.step) && !isMobile && (
            <img
              style={{ zIndex: '1', marginLeft: '-100px' }}
              src={trackingStatus.canceled ? TrackingGrayBg : TrackingGreenBg}
              alt="tracking img"
            />
            )}
          </FlexBox>
        ))}

      </FlexBox>
      <FlexBox p="20px 0 0 0" flexDirection="column">
        {trackingStatus.titleLabel && (
        <Typography fw="500">
          {translate(trackingStatus.titleLabel)}
        </Typography>
        )}
        {trackingStatus.label && (
        <Typography>
          {translate(trackingStatus.label)}
        </Typography>
        )}
      </FlexBox>
    </Card>
  );
};

export default OrderStatusCard;
