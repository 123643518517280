import React from 'react';
import StyledImage from './Image.styles';

const Image = ({ children, ...props }) => (
  <StyledImage
    className={props.className}
    alt={props.alt}
    fallback={props.alt}
    src={props.src}
    props={props}
  >
    {children}
  </StyledImage>
);

export default Image;
