import React from 'react';
import StyledSwitch from './Switch.styles';

const Switch = ({ children, ...props }) => (
  <StyledSwitch
    className={props.className}
    alt={props.alt}
    fallback={props.alt}
    src={props.alt}
    {...props}
  >
    {children}
  </StyledSwitch>
);

export default Switch;
