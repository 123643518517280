import React, { useContext } from 'react';
import { makeStyles } from '@naturacosmeticos/natds-web';
import { useHistory } from 'react-router-dom';
import Card from '../../../components/card/Card';
import Typography from '../../../components/typography/Typography';
import { I18nContext } from '../../../config/i18n';
import DataTable from '../../../components/datatable/DataTable';
import { formatCurrency } from '../../../utils/numbers-utils';
import { formatDateIso } from '../../../utils/date-utils';
import Button from '../../../components/button/Button';
import { pushInteraction } from '../../../data-layer/interaction';

const useStyles = makeStyles((theme) => ({
  styledCard: {
    backgroundColor: theme.color.background,
  },
}));

const CustomerHistoryOrderTable = ({ orderData }) => {
  const { styledCard } = useStyles();
  const { translate } = useContext(I18nContext);
  const { push } = useHistory();

  function handleClick({ orderNo }) {
    pushInteraction('navigation', 'customer historic', 'order details');
    push(`/sales/${orderNo}`);
    // push('/sales/00001652');
  }
  function getStatus(status) {
    try {
      const possibleStatus = {
        aprovado: { status: translate('sales.table.status.SETTLED'), color: '#569a32' },
        pendente: { status: translate('sales.table.status.BOLETO_PENDING'), color: '#fcc433' },
        cancelado: { status: translate('sales.table.status.REFUSED'), color: '#dc3a37' },
      };
      return possibleStatus[status];
    } catch {
      return { status };
    }
  }
  const columns = [
    {
      field: 'status',
      headerName: translate('sales.table.header_status'),
      flex: 1,
      sortable: false,
      minWidth: 190,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { status, color } = getStatus(row?.status?.toLowerCase() || 'pendente');
        return (
          <Typography c={color}>{status}</Typography>
        );
      },
    },
    {
      field: 'orderNo',
      headerName: translate('sales.order_resume.orders_table.order_numbers'),
      minWidth: 190,
      type: 'number',
      renderCell: ({ row }) => (row.orderNo || '00001652'),
      disableColumnMenu: true,
    },
    {
      field: 'productItemsQuantity',
      headerName: translate('sales.order_resume.orders_table.quantity'),
      disableColumnMenu: true,
      type: 'number',
      minWidth: 100,
      renderCell: ({ row }) => (row.productItemsQuantity || 3),
    },
    {
      field: 'creationDate',
      headerName: translate('sales.order_resume.orders_table.order_date'),
      sortable: false,
      minWidth: 190,
      type: 'number',
      disableColumnMenu: true,
      renderCell: ({ row }) => formatDateIso(row.creationDate),
    },
    {
      field: 'splitValorParticipanteCND',
      headerName: translate('sales.order_resume.orders_table.cn_profit'),
      sortable: false,
      type: 'number',
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: ({ row }) => formatCurrency(row.splitValorParticipanteCND),
    },
    {
      field: 'details',
      headerName: ' ',
      sortable: false,
      type: 'action',
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Button
          onClick={() => handleClick(row)}
          variant="contained"
          color="primary"
        >
          {translate('detail_button')}
        </Button>
      ),
    },
  ];

  return (
    <Card m="5px 0" className={styledCard} noShadow>
      <Typography variant="h5">{translate('sales.order_resume.title')}</Typography>
      <DataTable
        tableMargin="0"
        rowId="orderNo"
        dataset={orderData.OrderList || []}
        columns={columns}
      />
    </Card>
  );
};

export default CustomerHistoryOrderTable;
