import React from 'react';
import StyledDrawerMenu from './DrawerMenu.styles';

const DrawerMenu = ({ children, ...props }) => (
  <StyledDrawerMenu
    {...props}
  >
    {children}
  </StyledDrawerMenu>
);

export default DrawerMenu;
