import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import SearchCard from './Search';
import CustomersTable from './CustomerTable';
import FlexBox from '../../components/flex-box/FlexBox';
import Page from '../../components/page/Page';
import FilterBar from './FilterBar';
import ErrorScreen from '../../components/error-screen/ErrorScreen';

function Customers() {
  const { fetchCustomers } = useStoreActions((actions) => actions.customers);
  const { error, loading } = useStoreState((state) => state.customers);
  const { personId } = useStoreState((state) => state.main);
  const [isMobile] = useState(window.innerWidth < 1080);

  useEffect(() => {
    if (!personId) return;
    fetchCustomers({ personId });
  }, [fetchCustomers, personId]);

  function refetchCustomers() {
    fetchCustomers({ personId });
  }

  return (
    <>
      <Page>
        {error && <ErrorScreen action={refetchCustomers} />}
        {!error && (
        <>
          <FilterBar />
          <FlexBox flexDirection={isMobile ? 'column' : false} maxWidth={false}>
            <SearchCard loading={loading} />
          </FlexBox>
          <CustomersTable loading={loading} />
        </>
        )}

      </Page>
    </>
  );
}

export default Customers;
