import React from 'react';
import StyledDrawer from './Drawer.styles';

const Drawer = ({ children, ...props }) => (
  <StyledDrawer
    {...props}
  >
    {children}
  </StyledDrawer>
);

export default Drawer;
