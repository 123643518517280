import React, {
  useContext, useState, useEffect,
} from 'react';
import { useTheme } from '@material-ui/styles';
import { useStoreState } from 'easy-peasy';
import { Controller } from 'react-hook-form';

import TextField from '../../components/text-field/TextField';
import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import Button from '../../components/button/Button';
import Icon from '../../components/icon/Icon';
import Typography from '../../components/typography/Typography';
import LinkComponent from '../../components/link-component/LinkComponent';
import Dialog from '../../components/dialog/Dialog';
import DialogContent from '../../components/dialog-content/DialogContent';
import DialogActions from '../../components/dialog-actions/DialogActions';
import { validateLinkFacebook, validateLinkInstagram, validateLinkYoutube } from '../../utils/form-utils';

const linkUnderstandAfanpage = 'https://www.facebook.com/business/pages/set-up';
const linkNewFanpage = 'https://www.facebook.com/pages/creation/';

const SocialData = ({
  control, formState, setValue, clearErrors, getStatusInput,
}) => {
  const theme = useTheme();
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const [understandingAfanpageModal, setUnderstandingAfanpageModal] = useState({ open: false });
  const [modalNewFanPage, setOpenModalNewFanPage] = useState({ open: false });
  const [openInstagramLink, setOpenInstagramLink] = useState(false);
  const [openFacebookLinkState, setOpenFacebookLinkState] = useState(false);
  const [openChannelYoutubeLinkState, setOpenChannelYoutubeLinkState] = useState(false);

  const {
    instagramLink = '',
    facebookLink = '',
    channelYoutubeLink = '',
  } = useStoreState((state) => state.main);

  useEffect(() => {
    setOpenInstagramLink(!!instagramLink);
    setValue('imputInstagram', instagramLink);
  }, [instagramLink]);
  useEffect(() => {
    setOpenFacebookLinkState(!!facebookLink);
    setValue('imputFacebook', facebookLink);
  }, [facebookLink]);
  useEffect(() => {
    setOpenChannelYoutubeLinkState(!!channelYoutubeLink);
    setValue('imputYoutube', channelYoutubeLink);
  }, [channelYoutubeLink]);

  const color = theme.color.neutral400;

  function closeConfirmModal() {
    setUnderstandingAfanpageModal({ open: false });
    setOpenModalNewFanPage({ open: false });
  }

  function handleConfirmModalNewFanpage() {
    window.open(linkNewFanpage, '_blank');
  }

  function handleConfirmModal() {
    window.open(linkUnderstandAfanpage, '_blank');
  }

  return (
    <>
      <FlexBox p="0 30px" flexDirection="column">
        <FlexBox borderBottom={`2px solid ${color}`} p="30px 0" flexDirection="column">
          <Typography variant="h6">{translate('digital_space.social_tab.instagran_profile')}</Typography>
          <Typography m="10px 0">{translate('digital_space.social_tab.instagran_profile_description')}</Typography>
          {!openInstagramLink ? (
            <Button
              width={isMobile ? '100%' : 'max-content'}
              variant="outlined"
              style={{ margin: isMobile ? 'auto' : 'unset' }}
              color="primary"
              onClick={() => setOpenInstagramLink(true)}
            >
              <Icon
                color="primary"
                name="filled-brand-instagram"
                size="tiny"
              />
              <Typography p="0 0 0 10px" color="primary">
                {translate('digital_space.social_tab.link_instagran_profile')}
              </Typography>
            </Button>
          ) : (
            <FlexBox
              m="0 0 10px 0"
              alignItems={isMobile ? 'flex-start' : 'end'}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <FlexBox flexDirection="column" m={isMobile ? '0px' : '0px 20px 0px 0px'}>
                <Controller
                  render={({ field, fieldState }) => (
                    <>
                      <Typography p="10px 0" color="textSecondary" error={fieldState.error}>
                        {translate(('digital_space.social_tab.link_instagran_label'))}
                      </Typography>
                      <TextField
                        id="imputInstagram"
                        value={field.value}
                        onChange={(event) => field.onChange(event)}
                        state={getStatusInput(field, fieldState)}
                        style={{ minHeight: '40px' }}
                        placeholder={translate(('digital_space.social_tab.link_instagram_placeholder'))}
                        helpText={
                            fieldState.error && translate(fieldState.error.message)
                          }
                        rows={1}
                        size="semiX"
                        type="text"
                      />
                    </>
                  )}
                  rules={{
                    pattern: validateLinkInstagram,
                  }}
                  control={control}
                  name="imputInstagram"
                />
              </FlexBox>
              <Button
                width={isMobile ? '100%' : 'max-content'}
                variant="outlined"
                style={{
                  marginBottom: !isMobile && formState.errors.imputInstagram ? '23px' : 'unset',
                  marginTop: isMobile ? '5px' : '0px',
                }}
                color="primary"
                onClick={() => {
                  setOpenInstagramLink(false);
                  setValue('imputInstagram', '');
                  clearErrors('imputInstagram');
                }}
              >
                <Typography p="0px 20px" color="secondary">
                  {translate('digital_space.social_tab.unlink')}
                </Typography>
              </Button>
            </FlexBox>
          )}
        </FlexBox>
        <FlexBox borderBottom={`2px solid ${color}`} p="30px 0" flexDirection="column">
          <Typography variant="h6">{translate('digital_space.social_tab.youtube_channel')}</Typography>
          <Typography m="10px 0">{translate('digital_space.social_tab.youtube_channel_description')}</Typography>
          {!openChannelYoutubeLinkState ? (
            <Button
              width={isMobile ? '100%' : 'max-content'}
              variant="outlined"
              style={{ margin: isMobile ? 'auto' : 'unset' }}
              color="primary"
              onClick={() => setOpenChannelYoutubeLinkState(true)}
            >
              <Icon
                color="primary"
                name="filled-brand-youtube"
                size="tiny"
              />
              <Typography p="0 0 0 10px" color="primary">
                {translate('digital_space.social_tab.link_youtube_channel')}
              </Typography>
            </Button>
          ) : (
            <FlexBox
              m="0 0 10px 0"
              alignItems={isMobile ? 'flex-start' : 'end'}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <FlexBox flexDirection="column" m={isMobile ? '0px' : '0px 20px 0px 0px'}>
                <Controller
                  render={({ field, fieldState }) => (
                    <>
                      <Typography p="10px 0" color="textSecondary" error={fieldState.error}>
                        {translate(('digital_space.social_tab.link_youtube_label'))}
                      </Typography>
                      <TextField
                        id="imputYoutube"
                        onChange={(event) => field.onChange(event)}
                        value={field.value}
                        state={getStatusInput(field, fieldState)}
                        style={{ minHeight: '40px' }}
                        placeholder={translate(('digital_space.social_tab.link_youtube_placeholder'))}
                        helpText={
                            fieldState.error && translate(fieldState.error.message)
                          }
                        rows={1}
                        size="semiX"
                        type="text"
                      />
                    </>
                  )}
                  rules={{
                    pattern: validateLinkYoutube,
                  }}
                  control={control}
                  name="imputYoutube"
                />
              </FlexBox>
              <Button
                width={isMobile ? '100%' : 'max-content'}
                variant="outlined"
                style={{
                  marginBottom: !isMobile && formState.errors.imputYoutube ? '23px' : 'unset',
                  marginTop: isMobile ? '5px' : '0px',
                }}
                color="primary"
                onClick={() => {
                  setOpenChannelYoutubeLinkState(false);
                  setValue('imputYoutube', '');
                  clearErrors('imputYoutube');
                }}
              >
                <Typography p="0px 20px" color="secondary">
                  {translate('digital_space.social_tab.unlink')}
                </Typography>
              </Button>
            </FlexBox>
          )}
        </FlexBox>
        <FlexBox borderBottom={`2px solid ${color}`} p="30px 0" flexDirection="column">
          <Typography variant="h6">{translate('digital_space.social_tab.facebook_or_fanpage')}</Typography>
          <Typography m="10px 0">{translate('digital_space.social_tab.facebook_or_fanpage_description')}</Typography>
          {!openFacebookLinkState ? (
            <Button
              width={isMobile ? '100%' : 'max-content'}
              variant="outlined"
              style={{ margin: isMobile ? 'auto' : 'unset' }}
              color="primary"
              onClick={() => setOpenFacebookLinkState(true)}
            >
              <Icon
                color="primary"
                name="filled-brand-youtube"
                size="tiny"
              />
              <Typography p="0 0 0 10px" color="primary">
                {translate('digital_space.social_tab.link_facebook_or_fanpage')}
              </Typography>
            </Button>
          ) : (
            <FlexBox
              m="0 0 10px 0"
              alignItems={isMobile ? 'flex-start' : 'end'}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <FlexBox flexDirection="column" m={isMobile ? '0px' : '0px 20px 0px 0px'}>
                <Controller
                  render={({ field, fieldState }) => (
                    <>
                      <Typography p="10px 0" color="textSecondary" error={fieldState.error}>
                        {translate(('digital_space.social_tab.link_facebook_label'))}
                      </Typography>
                      <TextField
                        id="imputFacebook"
                        value={field.value}
                        onChange={(event) => field.onChange(event)}
                        state={getStatusInput(field, fieldState)}
                        style={{ minHeight: '40px' }}
                        placeholder={translate(('digital_space.social_tab.link_facebook_placeholder'))}
                        helpText={
                            fieldState.error && translate(fieldState.error.message)
                          }
                        rows={1}
                        size="semiX"
                        type="text"
                      />
                    </>
                  )}
                  rules={{
                    pattern: validateLinkFacebook,
                  }}
                  control={control}
                  name="imputFacebook"
                />
              </FlexBox>
              <Button
                width={isMobile ? '100%' : 'max-content'}
                variant="outlined"
                style={{
                  marginBottom: !isMobile && formState.errors.imputFacebook ? '23px' : 'unset',
                  marginTop: isMobile ? '5px' : '0px',
                }}
                color="primary"
                onClick={() => {
                  setOpenFacebookLinkState(false);
                  setValue('imputFacebook', '');
                  clearErrors('imputFacebook');
                }}
              >
                <Typography p="0px 20px" color="secondary">
                  {translate('digital_space.social_tab.unlink')}
                </Typography>
              </Button>
            </FlexBox>
          )}
        </FlexBox>
        <FlexBox p="30px 0" flexDirection="column">
          <Typography variant="h6">{translate('digital_space.social_tab.create_fanpage')}</Typography>
          <Typography m="10px 0">{translate('digital_space.social_tab.create_fanpage_description')}</Typography>
          <Button
            width={isMobile ? '100%' : 'max-content'}
            variant="outlined"
            style={{ margin: isMobile ? 'auto' : 'unset' }}
            color="primary"
            onClick={() => setOpenModalNewFanPage({
              open: true,
            })}
          >
            <Icon
              color="primary"
              name="filled-brand-facebook"
              size="tiny"
            />
            <Typography p="0 0 0 10px" color="primary">
              {translate('digital_space.social_tab.link_create_fanpage')}
            </Typography>
          </Button>
          <LinkComponent
            style={{ paddingTop: ' 10px' }}
            color="primary"
            onClick={() => setUnderstandingAfanpageModal({
              open: true,
            })}
            underline="always"
            href="#"
            variant="body2"
          >
            {translate('digital_space.social_tab.understand_fanpage')}
          </LinkComponent>
        </FlexBox>
      </FlexBox>
      <Dialog
        maxWidth="md"
        open={understandingAfanpageModal.open}
        onClose={closeConfirmModal}
        onEscapeKeyDown={closeConfirmModal}
      >
        <DialogContent>
          <FlexBox flexDirection="column">
            <Typography variant="h6">
              {translate('digital_space.social_tab.redirect_fanpage')}
            </Typography>
            <LinkComponent
              style={{ paddingTop: ' 10px', cursor: 'context-menu' }}
              color="primary"
              underline="always"
              href="#"
              variant="body1"
            >
              {linkUnderstandAfanpage}
            </LinkComponent>
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={closeConfirmModal}
            variant="text"
          >
            {translate('cancel_button')}
          </Button>
          <Button
            color="primary"
            onClick={handleConfirmModal}
            variant="text"
          >
            {translate('confirm_button')}
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        maxWidth="sm"
        open={modalNewFanPage.open}
        onClose={handleConfirmModalNewFanpage}
        onEscapeKeyDown={closeConfirmModal}
      >
        <DialogContent>
          <FlexBox flexDirection="column">
            <Typography variant="h6">
              {translate('digital_space.social_tab.redirect_facebook')}
            </Typography>
            <Typography color="textSecondary" p="10px 0px 0px 0px">
              {translate('digital_space.social_tab.redirect_facebook_description')}
            </Typography>
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={closeConfirmModal}
            variant="text"
          >
            {translate('cancel_button')}
          </Button>
          <Button
            color="primary"
            onClick={handleConfirmModalNewFanpage}
            variant="text"
          >
            {translate('confirm_button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default SocialData;
