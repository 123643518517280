import React, { useContext, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { I18nContext } from '../../config/i18n';
import DataTable from '../../components/datatable/DataTable';
import Typography from '../../components/typography/Typography';
import { formatCurrency } from '../../utils/numbers-utils';
import FlexBox from '../../components/flex-box/FlexBox';
import FilterBar from './FilterBar';
import { formatDateToExport } from '../../utils/date-utils';
import Button from '../../components/button/Button';
import { pushInteraction } from '../../data-layer/interaction';
import config from '../../config/config';

const SalesTable = ({ reloadFunction }) => {
  const { translate } = useContext(I18nContext);
  const dateFormat = translate('format.date');
  const [isMobile] = useState(window.innerWidth < 1080);
  const { push } = useHistory();
  const { sales = [], loading } = useStoreState((state) => state.sales);

  function handleClick({ orderId }) {
    pushInteraction('navigation', 'sales page', 'order details');
    push(`/sales/${orderId}`);
  }

  function getStatus(status, item) {
    try {
      // eslint-disable-next-line max-len
      const hasPaidStatus = Array.isArray(item.statusHistory) && item.statusHistory.some((historyStatus) => historyStatus.includes('91 Paid'));
      let isApproved = true;
      // eslint-disable-next-line no-unused-vars
      if (item.statusOrder === '8 - FAILED' || item.statusOrder === '6 - CANCELLED') { isApproved = false; }
      if (isApproved && hasPaidStatus) {
        return { status: translate('sales.table.status.SETTLED'), color: '#569a32' };
      } if (isApproved && !hasPaidStatus) {
        return { status: translate('sales.table.status.BOLETO_PENDING'), color: '#fcc433' };
      } if (isApproved === false) {
        return { status: translate('sales.table.status.REFUSED'), color: '#dc3a37' };
      }
    } catch {
      return { status };
    }
  }
  const columns = [
    {
      field: 'statusOrder',
      headerName: translate('sales.table.header_status'),
      sortable: false,
      minWidth: 120,
      disableColumnMenu: true,
      renderCell: ({ row }) => {
        const { status, color } = getStatus(row.statusOrder[0], row);
        return (
          <Typography c={color}>{status}</Typography>
        );
      },
      exportValue: ({ row }) => {
        const { status = '' } = getStatus(row.statusOrder[0], row);
        return status;
      },
    },
    {
      field: 'orderId',
      headerName: translate('sales.table.header_number'),
      minWidth: 120,
      renderCell: ({ row }) => row.orderId,
      exportValue: ({ row }) => row.orderId,
      disableColumnMenu: true,
    },
    {
      field: 'customerName',
      headerName: translate('sales.table.header_name'),
      flex: 1,
      disableColumnMenu: true,
      minWidth: 250,
      renderCell: ({ row }) => row.customerName,
      exportValue: ({ row }) => row.customerName,
    },
    {
      field: 'createDate',
      headerName: translate('sales.table.header_date'),
      type: 'number',
      sortable: false,
      minWidth: isMobile ? 100 : 180,
      disableColumnMenu: true,
      renderCell: ({ row }) => formatDateToExport(row.createDate, dateFormat),
      exportValue: ({ row }) => formatDateToExport(row.createDate, dateFormat),
    },
    {
      field: 'cnProfitValue',
      headerName: translate('sales.table.header_profit'),
      type: 'number',
      sortable: false,
      minWidth: isMobile ? 100 : 120,
      disableColumnMenu: true,
      // eslint-disable-next-line max-len
      renderCell: ({ row }) => (row.statusOrder[0] === '6' || row.statusOrder[0] === '8' ? formatCurrency(0) : formatCurrency(row.cnProfitValue)),
      // eslint-disable-next-line max-len
      exportValue: ({ row }) => (row.statusOrder[0] === '6' || row.statusOrder[0] === '8' ? formatCurrency(0) : formatCurrency(row.cnProfitValue)),
    },
    {
      field: '',
      type: 'actions',
      headerName: ' ',
      sortable: false,
      minWidth: isMobile ? 130 : 120,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Button
          variant="contained"
          onClick={() => handleClick(row)}
          color="primary"
        >
          {translate('detail_button')}
        </Button>
      ),
    },
  ];

  const handlePermissionEarningsCalendar = () => {
    if (config.general.country === 'ar' && config.general.company === 'natura') {
      const sizeListCompletedOrders = sales.filter((item) => {
        // eslint-disable-next-line max-len
        const hasPaidStatus = Array.isArray(item.statusHistory) && item.statusHistory.some((status) => status.includes('91 Paid'));
        let isApproved = true;
        if (item.statusOrder === '8 - FAILED' || item.statusOrder === '6 - CANCELLED') { isApproved = false; }
        return hasPaidStatus && isApproved;
      }).length;
      return (
        <Button
          disabled={!sizeListCompletedOrders}
          variant="contained"
          onClick={() => push('/earning-calendar')}
          minWidth="120px"
          flex={1}
        >
          {translate('sales.earnings_calendar')}
        </Button>
      );
    }
    return null;
  };
  return (
    <>
      <FlexBox flexDirection="column" m="0" p="0" maxWidth={false}>
        <DataTable
          showExtraButtons={handlePermissionEarningsCalendar()}
          initialState={{
            sorting: {
              sortModel: [{ field: 'orderId', sort: 'asc' }],
            },
          }}
          loading={loading}
          tableMargin="0"
          reloadFunction={reloadFunction}
          button={<FilterBar />}
          rowId="orderId"
          dataset={sales}
          columns={columns}
          fileName={translate('sales.file_name.download')}
        />
      </FlexBox>
    </>
  );
};

export default SalesTable;
