import React, { useContext } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useHistory } from 'react-router-dom';
import { I18nContext } from '../../config/i18n';
import Typography from '../typography/Typography';
import Card from '../card/Card';
import Button from '../button/Button';
import StyledBackground from './Background.styles';
import StyledLoginModalError from './LoginModalError.styles';
import config from '../../config/config';
import DialogTitle from '../dialog-title/DialogTitle';
import DialogContent from '../dialog-content/DialogContent';
import useHandleLogout from '../../hooks/useHandleLogout';

const LoginModalError = ({ ...props }) => {
  const { translate } = useContext(I18nContext);
  const { resetAllStore, clearStorage } = useHandleLogout();
  const { handleLogout, setMessageErrorResetPassword } = useStoreActions((state) => state.main);

  const { messageErrorResetPassword } = useStoreState((state) => state.main);

  const history = useHistory();

  const handleLogoutClick = () => {
    handleLogout();
    resetAllStore();
    clearStorage();
    if (config.auth.loginConfig !== 'natura_login') {
      history.push('/logout');
    } else if (messageErrorResetPassword) {
      setMessageErrorResetPassword(undefined);
      history.push('/first-access');
    }
  };

  return (
    <>
      <StyledBackground />
      <StyledLoginModalError
        {...props}
        messageErrorResetPassword={!!messageErrorResetPassword}
      >
        <Card alignItems="flex-end" border="0">
          {messageErrorResetPassword ? (
            <>
              <DialogTitle style={{ width: '100%' }}>
                <Typography variant="subtitle1">
                  {translate('login_password_invalid')}
                </Typography>
              </DialogTitle>
              <DialogContent>
                <Typography>
                  {translate('login_error_phrase_reset_password')}
                </Typography>
              </DialogContent>
            </>
          ) : (
            <Typography>
              {translate('login_error_phrase')}
            </Typography>
          )}
          <Button w="50px" onClick={handleLogoutClick}>Ok</Button>
        </Card>
      </StyledLoginModalError>
    </>
  );
};

export default LoginModalError;
