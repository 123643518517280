import React, { useContext } from 'react';
import Typography from '../../components/typography/Typography';
import Card from '../../components/card/Card';
import { I18nContext } from '../../config/i18n';

const Presentation = () => {
  const { translate } = useContext(I18nContext);
  return (
    <>
      <Card>
        <Typography variant="h6">{translate('visits.presentation.title')}</Typography>
        <Typography>
          {translate('visits.presentation.presentation_1')}
        </Typography>
        <Typography>
          {translate('visits.presentation.presentation_2')}
        </Typography>
        <Typography>
          {translate('visits.presentation.presentation_3')}
        </Typography>
      </Card>
    </>
  );
};

export default Presentation;
