import { makeStyles } from '@naturacosmeticos/natds-web';

export const dataTableStyle = makeStyles((theme) => ({
  table: {
    margin: (props) => props.tableMargin || '15px',
    color: `${theme.color.highEmphasis}!important`,
    '& .MuiTablePagination-actions': {
      color: `${theme.color.highEmphasis}!important`,
    },
    '& .css-levciy-MuiTablePagination-displayedRows': {
      color: `${theme.color.highEmphasis}!important`,
    },
    border: 'none!important',
    '& .MuiDataGrid-cell': {
      border: 'none!important',
    },
    '& .MuiDataGrid-columnsContainer': {
      borderBottom: `1px solid ${theme.color.mediumEmphasis}`,
    },
    '& .MuiDataGrid-columnHeaderTitleContainer': {
      display: 'flex',
      justifyContent: 'flex-start !important',
      padding: '0px!important',
    },
    '& .MuiDataGrid-cell--withRenderer': {
      display: 'flex',
      justifyContent: 'flex-end !important',
    },
    '& .MuiDataGrid-cell--withRenderer[data-field=status]': {
      display: 'flex',
      justifyContent: 'flex-start !important',
    },
    '& .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
    '& .MuiPaper-root': {
      boxShadow: '0',
      margin: '10px',
    },
    '& .MuiDataGrid-cell:focus': {
      outline: 'unset !important',
    },
    '& .MuiDataGrid-cell:focus-within': {
      outline: 'unset !important',
    },
  },
}));
