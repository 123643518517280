import { Button } from '@naturacosmeticos/natds-web';
import { styled } from '@material-ui/core/styles';

const StyledButton = styled(Button)(({ theme }) => ({
  padding: ({ padding, p }) => padding || p || '0 10px',
  width: ({ w, width }) => width || w,
  minWidth: ({ minWidth }) => minWidth,
  margin: ({ margin, m }) => margin || m,
  borderRadius: ({ borderRadius }) => borderRadius,
  lineHeight: '12px',
  [theme.breakpoints.down('xs')]: {
    /* Estilos para dispositivos com largura menor ou igual a 600px */
    fontSize: '11px',
    flex: ({ flex }) => (flex || ''),
    minWidth: ({ flex }) => flex && '100px',
    maxWidth: ({ flex }) => flex && '150px',
    padding: '5px',
  },
  '& span': {
    /* Estilos para o elemento span dentro do botão */
    lineHeight: '12px',
  },
}));

export default StyledButton;
