import React, { useContext, useState } from 'react';
import { I18nContext } from '../../../config/i18n';
import Card from '../../../components/card/Card';
import Typography from '../../../components/typography/Typography';
import FlexBox from '../../../components/flex-box/FlexBox';
import ProgressIndicator from '../../../components/progress-indicator/ProgressIndicator';

const CustomerDetails = ({ loading, data }) => {
  const [isMobile] = useState(window.innerWidth < 1080);
  const { translate } = useContext(I18nContext);
  return (
    <>
      <Card minHeight={loading && '250px'} alignItems={loading && 'center'} justifyContent={loading && 'center'}>
        {loading && <ProgressIndicator />}
        {!loading && (
        <>
          <Typography m="10px 0" variant="h5">
            {translate('sales.customer_details.title')}
          </Typography>
          <FlexBox flexDirection={isMobile && 'column'}>
            <FlexBox m="5px 0" flexDirection="column" justifyContent="space-between" alignItems="center">
              <FlexBox alignItems="center">
                <Typography color="textSecondary">
                  {translate('sales.customer_details.customer_name')}
                </Typography>
              </FlexBox>
              <FlexBox flex="1">
                <Typography whiteSpace="nowrap">
                  {data.customerName}
                </Typography>
              </FlexBox>
            </FlexBox>
            <FlexBox m="5px 0" flexDirection="column" justifyContent="space-between" alignItems="center">
              <FlexBox alignItems="center">
                <Typography color="textSecondary">
                  {translate('sales.customer_details.customer_email')}
                </Typography>
              </FlexBox>
              <FlexBox flex="1">
                <Typography whiteSpace="nowrap">
                  {data.customerEmail}
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox flexDirection={isMobile && 'column'}>
            <FlexBox m="5px 0" flexDirection="column" justifyContent="space-between" alignItems="center">
              <FlexBox alignItems="center">
                <Typography color="textSecondary">
                  {translate('sales.customer_details.customer_phone')}
                </Typography>
              </FlexBox>
              <FlexBox flex="1">
                <Typography whiteSpace="nowrap">
                  {data.customerPhone}
                </Typography>
              </FlexBox>
            </FlexBox>
            <FlexBox m="5px 0" flexDirection="column" justifyContent="space-between" alignItems="center">
              <FlexBox alignItems="center">
                <Typography color="textSecondary">
                  {translate('sales.customer_details.customer_uf')}
                </Typography>
              </FlexBox>
              <FlexBox flex="1">
                <Typography whiteSpace="nowrap">
                  {data.customerState}
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </>
        )}
      </Card>

    </>
  );
};

export default CustomerDetails;
