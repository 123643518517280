import React, { useContext, useState, useRef } from 'react';
import { Avatar } from '@naturacosmeticos/natds-web/dist/Components/Avatar';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { withRouter } from 'react-router-dom';
import { I18nContext } from '../../../config/i18n';
import Card from '../../card/Card';
import Button from '../../button/Button';
import FlexBox from '../../flex-box/FlexBox';
import Typography from '../../typography/Typography';
import configBlockedRoutes from '../../router/ConfigBlockedRoutes';
import { profilePopoverStyle } from './ProfilePopover.style';
import Icon from '../../icon/Icon';
import Switch from '../../switch/Switch';
import config from '../../../config/config';
import useHandleLogout from '../../../hooks/useHandleLogout';

const blockedRoutes = configBlockedRoutes();

const ProfilePopover = (props) => {
  const btnRef = useRef();
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const [state, setState] = useState({ active: false });
  const { changeTheme, handleLogout } = useStoreActions((actions) => actions.main);
  const {
    theme, personFullName, personEmail, imageCN,
  } = useStoreState((actions) => actions.main);
  const classes = profilePopoverStyle({ isMobile });
  const { resetAllStore, clearStorage } = useHandleLogout();

  const handleProfile = () => {
    // eslint-disable-next-line react/destructuring-assignment
    props.history.push('/profile');
    setState({ ...state, active: false });
  };

  const doLogout = () => {
    handleLogout();
    resetAllStore();
    clearStorage();
    setState({ ...state, active: false });
    // eslint-disable-next-line react/destructuring-assignment
    if (config.auth.loginConfig !== 'natura_login') props.history.push('/logout');
  };

  return (
    <div style={{ position: 'relative' }}>
      <Button
        style={{ padding: 0, borderRadius: '20px', minWidth: 'unset' }}
        btnref={btnRef}
        onClick={() => setState({ active: !state.active })}
      >
        <Avatar
          alt="Nat Natura holding a avatar"
          color="primary"
          size="big"
          src={imageCN}
        />
      </Button>
      <div className={state.active ? classes.popOver : classes.hide}>
        <Card maxWidth={isMobile && '-webkit-fill-available'} m="0" p="0">
          <FlexBox p="10px">
            <FlexBox m="0 10px 10px 0" width="max-content">
              <Avatar
                alt="Nat Natura holding a avatar"
                color="primary"
                size="huge"
                src={imageCN}
              />
            </FlexBox>
            <FlexBox flexDirection="column" justifyContent="center" gap="5px">
              <Typography whiteSpace="normal" color="primary">{personFullName}</Typography>
              <FlexBox>
                <Typography whiteSpace="nowrap" classes={classes.codeSpan}>
                  {translate('profile.email')}
                </Typography>
                <Typography m="0 5px">{personEmail}</Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox>
            <Icon
              style={{ marginLeft: 10 }}
              name="outlined-action-lighton"
              size="small"
              color="primary"
            />
            <Switch
              checked={theme === 'dark'}
              color="primary"
              onChange={changeTheme}
            />
            <Icon
              name="outlined-action-lightoff"
              size="small"
              color="primary"
            />
            {!blockedRoutes.some((item) => item === '/profile') && (
            <Button
              style={{ flex: '1' }}
              color="inherit"
              onClick={() => handleProfile(props)}
            >
              {translate('profile.button')}
            </Button>
            )}
            <Button
              style={{ flex: '1' }}
              color="inherit"
              onClick={doLogout}
            >
              {translate('logout.button')}
            </Button>
          </FlexBox>
        </Card>
      </div>
      {state.active && (
      // eslint-disable-next-line jsx-a11y/control-has-associated-label
      <button
        className={classes.hideButton}
        type="button"
        onClick={() => setState({ active: !state.active, lang: state.lang })}
      />
      )}
    </div>
  );
};

export default withRouter(ProfilePopover);
