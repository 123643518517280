import CryptoJS from 'crypto-js';
import config from '../config/config';

export function generateHash(personIdParam) {
  const hash = CryptoJS.HmacSHA256(personIdParam, config.general.secretCrypto);
  return CryptoJS.enc.Base64.stringify(hash);
}

export const getEncryptPersonId = () => {
  const personId = sessionStorage.getItem('personId');
  const encryptPersonId = personId ? generateHash(personId) : null;
  return encryptPersonId;
};
export const getPersonId = () => {
  const personId = sessionStorage.getItem('personId');
  return personId;
};
