import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { TextField } from '@naturacosmeticos/natds-web';

import Card from '../../components/card/Card';
import FlexBox from '../../components/flex-box/FlexBox';
import DataTable from '../../components/datatable/DataTable';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import AbondonedOrderDetail from './AbondonedOrderDetail';
import { formatDateToExport } from '../../utils/date-utils';
import Button from '../../components/button/Button';
import { pushInteraction } from '../../data-layer/interaction';

const digitalSpaceBaseUrl = process.env.REACT_APP_DIGITAL_SPACE_BASE_URL;

const AbandonedOrdersTable = ({ data, loading }) => {
  const { translate } = useContext(I18nContext);
  const dateFormat = translate('format.date');
  const [isMobile] = useState(window.innerWidth < 1080);
  const {
    setSearch, filterAbandonedOrders, addIndividualAbanonedOrder,
  } = useStoreActions((actions) => actions.abandonedOrders);
  const { handleSubmit, register } = useForm();
  const [orderDetailState, setOrderDetailState] = useState({ open: false, code: '' });
  const { personId, personDigitalSpaceName } = useStoreState((state) => state.main);

  function shareWhatsapp({ customerName, customerPhone }) {
    // eslint-disable-next-line max-len
    const encoded = encodeURIComponent(`${translate('abandoned_orders.table.share_phrase', [customerName, `${digitalSpaceBaseUrl}${personDigitalSpaceName}`])}`);
    const link = `https://wa.me/${customerPhone}?text=${encoded}`;
    pushInteraction('click', 'abandoned page', 'send on whatsapp');
    window.open(link);
  }

  function toggleCloseModal(code) {
    if (orderDetailState.open) {
      addIndividualAbanonedOrder(null);
    } else {
      pushInteraction('click', 'abandoned page', 'see bag');
    }
    setOrderDetailState({ code: code || false, open: !orderDetailState.open });
  }

  const columns = [
    {
      field: 'customerName',
      headerName: translate('abandoned_orders.table.client_name'),
      sortable: false,
      flex: 1,
      minWidth: 190,
      disableColumnMenu: true,
      renderCell: ({ row }) => <Typography whiteSpace="normal">{row.customerName}</Typography>,
      exportValue: ({ row }) => row.customerName,
    },
    {
      field: 'customerEmail',
      headerName: translate('abandoned_orders.table.head_email'),
      minWidth: 240,
      renderCell: ({ row }) => <Typography whiteSpace="normal">{row.customerEmail}</Typography>,
      exportValue: ({ row }) => row.customerEmail,
      disableColumnMenu: true,
    },
    {
      field: 'lastModified',
      headerName: translate('abandoned_orders.table.head_date'),
      type: 'date',
      minWidth: 180,
      disableColumnMenu: true,
      exportValue: ({ row }) => row.lastModified && formatDateToExport(row.lastModified, dateFormat),
      renderCell: ({ row }) => row.lastModified && formatDateToExport(row.lastModified, dateFormat),
    },
    {
      field: '  ',
      headerName: ' ',
      width: 140,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Button variant="contained" color="primary" onClick={() => toggleCloseModal(row.UUID)}>
          {translate('abandoned_orders.table.order_details')}
        </Button>
      ),
    },
    {
      field: ' ',
      headerName: ' ',
      width: 220,
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <Button variant="contained" color="primary" onClick={() => shareWhatsapp(row)}>
          {translate('abandoned_orders.table.share_button')}
        </Button>
      ),
    },
  ];

  function handleFilter({ searchValue }) {
    setSearch(searchValue);
    filterAbandonedOrders();
    pushInteraction('search', 'customer', undefined);
  }

  return (
    <>
      <Card>
        <FlexBox alignItems="center">
          <form onSubmit={handleSubmit(handleFilter)}>
            <FlexBox alignItems="center">
              <TextField
                {...register('searchValue')}
                style={{ marginRight: '10px', flex: '1', minWidth: isMobile ? '150px' : '281px' }}
                placeholder={translate('abandoned_orders.table.search_label')}
                rows={1}
                size="semiX"
              />
              <Button
                variant="contained"
                style={{ marginLeft: '10px' }}
                color="primary"
                onClick={handleSubmit(handleFilter)}
              >
                {translate('search')}
              </Button>
            </FlexBox>
          </form>
        </FlexBox>
        <DataTable
          loading={loading}
          rowId="UUID"
          dataset={data}
          columns={columns}
          fileName={translate('export.table.abandoned_orders')}
        />
      </Card>
      {orderDetailState.open
        && (
        <AbondonedOrderDetail
          personId={personId}
          toggleCloseModal={toggleCloseModal}
          code={orderDetailState.code}
        />
        )}
    </>
  );
};

export default AbandonedOrdersTable;
