import React, { memo, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import Button from '../../../../components/button/Button';
import DialogActions from '../../../../components/dialog-actions/DialogActions';
import DialogContent from '../../../../components/dialog-content/DialogContent';
import DialogTitle from '../../../../components/dialog-title/DialogTitle';
import Dialog from '../../../../components/dialog/Dialog';
import Typography from '../../../../components/typography/Typography';
import { I18nContext } from '../../../../config/i18n';

const ConfirmationDialog = ({ handleClose, isOpen, email }) => {
  const { pathname } = useLocation();
  const { translate } = useContext(I18nContext);
  return (
    <Dialog maxWidth="sm" onEscapeKeyDown={handleClose} open={isOpen}>
      <DialogTitle>
        <Typography variant="h5">
          {translate('forgotPassword.modal.title')}
        </Typography>
      </DialogTitle>
      <DialogContent>
        {pathname === '/first-access' ? (
          <Typography variant="body2">
            {translate('forgotPassword.modal.my_first_access_text')}
          </Typography>
        ) : (
          <Typography variant="body2">
            {translate('forgotPassword.modal.text')}
          </Typography>
        )}
        <Typography align="center" variant="subtitle1" p="20px 0px">
          {email}
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button color="primary" variant="contained" onClick={handleClose}>
          {translate('forgotPassword.modal.button')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default memo(ConfirmationDialog);
