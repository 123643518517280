import React, { useContext, useEffect, useState } from 'react';
import { makeStyles } from '@naturacosmeticos/natds-web';
import { useParams } from 'react-router-dom';

import Card from '../../../components/card/Card';
import { I18nContext } from '../../../config/i18n';
import Typography from '../../../components/typography/Typography';
import FlexBox from '../../../components/flex-box/FlexBox';
import { formatCurrency } from '../../../utils/numbers-utils';
import InfoPopover from '../../../components/info-popover/InfoPopover';
import { formatDateIso } from '../../../utils/date-utils';
import ProgressIndicator from '../../../components/progress-indicator/ProgressIndicator';
import config from '../../../config/config';

const useStyles = makeStyles(({ color }) => ({
  success: {
    color: color.success,
  },
}));

const OrderDetails = ({
  loading, data, isMobile, trackingStatus,
}) => {
  const { success } = useStyles();
  const { translate } = useContext(I18nContext);
  const { orderNumber } = useParams();
  const [earningValue, setEarningValue] = useState(0);

  useEffect(() => {
    if (data && data.cnProfitValue && data.brokerPaymentValue) {
      const taxSales = config.general.taxSales ? data.brokerPaymentValue : 0;
      if (trackingStatus && trackingStatus.canceled === true) {
        setEarningValue(0);
      } else {
        setEarningValue(data.cnProfitValue - taxSales);
      }
    }
  }, [trackingStatus, data]);

  return (
    <Card minHeight={loading && '250px'} alignItems={loading && 'center'} justifyContent={loading && 'center'}>
      {loading && <ProgressIndicator />}
      {!loading && (
      <>
        <Typography m="10px 0" variant="h5">
          {translate('sales.order_details.title')}
          {' '}
          {orderNumber}
        </Typography>
        <Typography m="10px 0" color="textSecondary">
          {translate('sales.order_details.description')}
        </Typography>
        <FlexBox flexDirection={isMobile && 'column'}>
          <FlexBox flexDirection="column">
            <FlexBox m="10px 0" flexDirection="column">
              <Typography color="textSecondary">{translate('sales.order_details.date_label')}</Typography>
              <Typography>{formatDateIso(data.createDate)}</Typography>
            </FlexBox>
            {data.couponUsed && (
            <FlexBox m="10px 0" flexDirection="column">
              <Typography color="textSecondary">{translate('sales.order_details.coupon_label')}</Typography>
              <Typography>{data.couponCode}</Typography>
            </FlexBox>
            )}
          </FlexBox>
          <FlexBox>
            <FlexBox flexDirection="column">
              <FlexBox m="5px 0" justifyContent="space-between" alignItems="center">
                <FlexBox>
                  <Typography color="textSecondary">
                    {translate('sales.order_details.consultancy_label')}
                  </Typography>
                  <InfoPopover placement="bottom" text={translate('sales.order_details.consultancy_text')} />
                </FlexBox>
                <FlexBox flex="1">
                  <Typography whiteSpace="nowrap">
                    {formatCurrency(data.cnProfitValue)}
                  </Typography>
                </FlexBox>
              </FlexBox>
              {data.cnGratification > 0 && (
              <FlexBox m="5px 0" justifyContent="space-between" alignItems="center">
                <FlexBox alignItems="center">
                  <Typography color="textSecondary">
                    {translate('sales.order_details.gratification_label')}
                  </Typography>
                  <InfoPopover text={translate('sales.order_details.gratification_text')} />
                </FlexBox>
                <FlexBox flex="1">
                  <Typography whiteSpace="nowrap">
                    {formatCurrency(data.cnGratification)}
                  </Typography>
                </FlexBox>
              </FlexBox>
              )}
              { config.general.taxSales && (
              <FlexBox m="5px 0" justifyContent="space-between" alignItems="center">
                <FlexBox alignItems="center">
                  <Typography color="textSecondary">
                    {translate('sales.order_details.tax_label')}
                  </Typography>
                </FlexBox>
                <FlexBox flex="1">
                  <Typography whiteSpace="nowrap">
                    -
                    {' '}
                    {formatCurrency(data.brokerPaymentValue)}
                  </Typography>
                </FlexBox>
              </FlexBox>
              )}
              <FlexBox m="15px 0" flexDirection="column">
                <Typography color="textSecondary">{translate('sales.order_details.profit_label')}</Typography>
                <Typography className={success} variant="h4">
                  {formatCurrency(earningValue)}
                </Typography>
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </FlexBox>
      </>
      )}
    </Card>
  );
};

export default OrderDetails;
