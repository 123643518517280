import { makeStyles } from '@naturacosmeticos/natds-web';

export const paymentStatusStyle = makeStyles((theme) => ({
  circle: {
    width: '30px',
    height: '30px',
    borderRadius: '50px',
    marginRight: '5px',
    transition: '0.3s',
    color: theme.color.onLink,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  smallCircle: {
    width: '20px',
    height: '20px',
  },
  green: {
    backgroundColor: '#569a32',
  },
  red: {
    backgroundColor: '#dc3a37',
  },
  yellow: {
    backgroundColor: '#fcc433',
  },
  openGreen: {
    height: (props) => `${(props.orderApproved / props.total) * 120}px`,
    width: '20px',
    marginRight: '0',
  },
  openYellow: {
    height: (props) => `${(props.orderDone / props.total) * 120}px`,
    width: '20px',
    marginRight: '0',
  },
  openRed: {
    height: (props) => `${(props.orderCanceled / props.total) * 120}px`,
    width: '20px',
    marginRight: '0',
  },
}));
