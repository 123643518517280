import React, { useContext } from 'react';
import { useStoreState } from 'easy-peasy';
import FlexBox from '../../../components/flex-box/FlexBox';
import Card from '../../../components/card/Card';
import Typography from '../../../components/typography/Typography';
import { I18nContext } from '../../../config/i18n';
import config from '../../../config/config';

function Presentation() {
  const { translate } = useContext(I18nContext);
  const { levelCN } = useStoreState((state) => state.main);

  const withoutLevel = levelCN ? config.general.maximunDiscountCoupons : config.general.maximunWithoutLevelCoupons;

  // eslint-disable-next-line max-len, eqeqeq
  const maxDiscountCoupon = config.general.levelsDiscountCoupons?.filter((lv) => lv.level == levelCN)[0]?.discount || withoutLevel;

  const verifyTranslateExisting = (tranlateText) => translate(tranlateText) !== tranlateText;

  return (
    <>
      <Card>
        <FlexBox flexDirection="column">
          <Typography variant="h6">{translate('coupons.presentation.title')}</Typography>
        </FlexBox>
        <FlexBox alignItems="center">
          <Typography fz="30px" p="0 15px" color="primary">•</Typography>

          {verifyTranslateExisting('coupons.presentation.instructions.one.part.one')
            ? (
              <Typography>
                {translate('coupons.presentation.instructions.one.part.one')}
                {` ${maxDiscountCoupon}% `}
                {translate('coupons.presentation.instructions.one.part.two')}
              </Typography>
            )
            : (
              <Typography>
                {translate('coupons.presentation.instructions.one')}
              </Typography>
            )}
        </FlexBox>
        <FlexBox alignItems="center">
          <Typography fz="30px" p="0 15px" color="primary">•</Typography>
          <Typography>
            {translate('coupons.presentation.instructions.two')}
          </Typography>
        </FlexBox>
        <FlexBox alignItems="center">
          <Typography fz="30px" p="0 15px" color="primary">•</Typography>
          <Typography>
            {translate('coupons.presentation.instructions.three')}
          </Typography>
        </FlexBox>
        {verifyTranslateExisting('coupons.presentation.instructions.four')
        && (
        <FlexBox alignItems="center">
          <Typography fz="30px" p="0 15px" color="primary">•</Typography>
          <Typography>
            {translate('coupons.presentation.instructions.four')}
          </Typography>
        </FlexBox>
        )}
      </Card>
    </>
  );
}

export default Presentation;
