import React, { useContext, useState } from 'react';
import { makeStyles } from '@naturacosmeticos/natds-web';
import { useStoreState } from 'easy-peasy';
import Card from '../../components/card/Card';
import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import TotalGain from './TotalGain';
import PaymentStatus from './PaymentStatus';
import Button from '../../components/button/Button';
import Icon from '../../components/icon/Icon';

const useStyles = makeStyles(() => ({
  openButton: {
    minWidth: '20px',
    height: 'unset',
    padding: '0',
    '& .NatDSButton-label-5': {
      margin: '0',
    },
  },
}));
const Resume = () => {
  const { translate } = useContext(I18nContext);
  const { openButton } = useStyles();
  const [isMobile] = useState(window.innerWidth < 1080);
  const [open, setOpen] = useState(false);
  function handleClick() {
    setOpen(!open);
  }
  const { originalData } = useStoreState((state) => state.sales);
  const { orders = [] } = originalData;
  let orderApproved = 0;
  let orderDone = 0;
  let orderCanceled = 0;

  orders.forEach((item) => {
    // eslint-disable-next-line max-len
    const hasPaidStatus = Array.isArray(item.statusHistory) && item.statusHistory.some((status) => status.includes('91 Paid'));
    let isApproved = true;
    // eslint-disable-next-line no-unused-vars
    if (item.statusOrder === '8 - FAILED' || item.statusOrder === '6 - CANCELLED') { isApproved = false; }
    if (isApproved && hasPaidStatus) orderApproved += 1;
    if (isApproved && !hasPaidStatus) orderDone += 1;
    if (isApproved === false) orderCanceled += 1;
  });

  return (
    <>
      <Card>
        <FlexBox justifyContent="space-between" alignItems="center">
          <Typography color="textSecondary">{translate('sales.resume.title')}</Typography>
          <Button className={openButton} onClick={handleClick}>
            <Icon
              size="small"
              color="primary"
              name={open ? 'outlined-navigation-arrowtop' : 'outlined-navigation-arrowbottom'}
            />
          </Button>
        </FlexBox>
        <FlexBox
          flexDirection={isMobile && 'column'}
          mediaQ
        >
          <TotalGain
            orderApproved={orderApproved}
            orderDone={orderDone}
            orderCanceled={orderCanceled}
            isMobile={isMobile}
            open={open}
          />
          <PaymentStatus
            orderApproved={orderApproved}
            orderDone={orderDone}
            orderCanceled={orderCanceled}
            isMobile={isMobile}
            open={open}
          />
        </FlexBox>
      </Card>
    </>
  );
};

export default Resume;
