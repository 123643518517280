import React from 'react';
import FlexBox from '../flex-box/FlexBox';
import Page from '../page/Page';
import ProgressIndicator from '../progress-indicator/ProgressIndicator';

const LoadingScreen = () => (
  <>
    <Page>
      <FlexBox
        alignItems="center"
        justifyContent="center"
        minHeight={`${window.innerHeight - 136}px`}
      >
        <ProgressIndicator />
      </FlexBox>
    </Page>

  </>
);

export default LoadingScreen;
