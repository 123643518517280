import React, { useContext, useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { makeStyles } from '@naturacosmeticos/natds-web';
import { useStoreActions, useStoreState } from 'easy-peasy';
import FlexBox from '../../components/flex-box/FlexBox';
import { formatDateIsoToFetch, getAWeekBeforeFrom } from '../../utils/date-utils';
import Button from '../../components/button/Button';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import { pushInteraction } from '../../data-layer/interaction';
import { modelDateInterval } from '../../data-layer/modeling';

const useStyles = makeStyles(() => ({
  calendar: {
    margin: '15px 8px',
    maxWidth: window.innerWidth > 1080 && '190px',
    '& input': {
      minHeight: '40px',
    },
  },
}));

const CalendarFilter = () => {
  const { fetchVisits } = useStoreActions((state) => state.visits);
  const { personId } = useStoreState((state) => state.main);
  const [startDate, setStartDate] = useState(getAWeekBeforeFrom(new Date()));
  const [endDate, setEndDate] = useState(new Date());
  const { translate } = useContext(I18nContext);
  const classes = useStyles();
  const [isMobile] = useState(window.innerWidth < 1080);

  function handleDateChange(date) {
    setStartDate(date);
  }

  function handleEndDateChange(date) {
    setEndDate(date);
  }

  function applyPeriod() {
    const startDateToFetch = formatDateIsoToFetch(startDate);
    const startEndToFetch = formatDateIsoToFetch(endDate);
    fetchVisits({ startDate: startDateToFetch, endDate: startEndToFetch, personId });
    pushInteraction('filter', 'visit', modelDateInterval(startDate, endDate));
  }

  return (
    <>
      <FlexBox flexDirection={isMobile ? 'column' : 'row'} justifyContent="flex-end" alignItems="flex-end">
        <FlexBox justifyContent="flex-end" alignItems="center">
          <FlexBox flexDirection="column" justifyContent="flex-end" alignItems="center" maxWidth="fit-content">
            <FlexBox justifyContent="flex-start" alignItems="center">
              <Typography margin="0px 10px">{translate('visits.presentation.filter_title')}</Typography>
            </FlexBox>
            <FlexBox flexDirection="row" justifyContent="flex-end" alignItems="center">
              <DatePicker
                className={classes.calendar}
                label={translate('visits.presentation.filter_1')}
                format="dd/MM/yyyy"
                value={startDate}
                disableFuture
                onChange={handleDateChange}
                animateYearScrolling
              />
              <DatePicker
                className={classes.calendar}
                label={translate('visits.presentation.filter_2')}
                format="dd/MM/yyyy"
                disableFuture
                value={endDate}
                onChange={handleEndDateChange}
                animateYearScrolling
              />
            </FlexBox>
          </FlexBox>
        </FlexBox>
        <Button
          margin={isMobile ? '0px 6px;' : '0px 10px 15px 0px'}
          style={{ width: isMobile ? '-webkit-fill-available' : 'auto' }}
          variant="contained"
          onClick={applyPeriod}
          className={classes.bottomButton}
          color="primary"
        >
          {translate('customers.filter_bar.apply-label')}
        </Button>
      </FlexBox>
    </>
  );
};

export default CalendarFilter;
