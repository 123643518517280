import React from 'react';
import StyledDialogContent from './DialogContent.styles';

const DialogContent = ({ children, ...props }) => (
  <StyledDialogContent
    {...props}
    className={props.className}
    alt={props.alt}
    fallback={props.alt}
    src={props.alt}
    props={props}
  >
    {children}
  </StyledDialogContent>
);

export default DialogContent;
