import React, { useContext, useEffect, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { makeStyles, TextField } from '@naturacosmeticos/natds-web';
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import { useStoreActions, useStoreState } from 'easy-peasy';

import config from '../../config/config';
import { I18nContext } from '../../config/i18n';
import Button from '../../components/button/Button';
import FlexBox from '../../components/flex-box/FlexBox';
import LinkComponent from '../../components/link-component/LinkComponent';

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiFormControl-root': {
      flex: '1',
      width: '100%',
    },
  },
}));

const apiKey = config.general.googleMapsKey;
const { searchZipCodeUrl, country } = config.general;

const Address = ({ cancelEdit }) => {
  const classes = useStyles();
  const { translate } = useContext(I18nContext);
  const [addressState, setAddressState] = useState();
  const { personAddress } = useStoreState((state) => state.main);
  const { saveChangesAddress } = useStoreActions((state) => state.main);

  const {
    control,
    formState,
    handleSubmit,
    setValue,
    // clearErrors, setError,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      imputNeighborhood: '',
      imputStreet: '',
      imputNumber: '',
      imputState: '',
      imputPostalCode: '',
      imputFloorAndApartment: '',
      imputCity: '',
    },
  });

  useEffect(() => {
    if (personAddress && personAddress.length > 0) {
      const addressRedux = personAddress[0];
      setValue('imputNeighborhood', addressRedux.neighborhood);
      setValue('imputStreet', addressRedux.location);
      setValue('imputNumber', addressRedux.number);
      setValue('imputState', addressRedux.state);
      setValue('imputPostalCode', addressRedux.zipCode);
      setValue('imputFloorAndApartment', addressRedux.complement);
      setValue('imputCity', addressRedux.city);
    }
  }, [personAddress]);

  async function onSubmit(data) {
    if (Object.keys(formState.errors).length === 0) {
      console.log('salvando...', data);
      saveChangesAddress({ ...data, countryCode: country });
    } else {
      // setOpenModalNeedToSave(true);
    }
  }

  // eslint-disable-next-line camelcase
  const getAddressObject = (addressComponents) => {
    const ShouldBeComponent = {
      streetNumber: ['street_number'],
      postalCode: ['postal_code'],
      street: ['street_address', 'route'],
      province: [
        'administrative_area_level_1',
        'administrative_area_level_2',
        'administrative_area_level_3',
        'administrative_area_level_4',
        'administrative_area_level_5',
      ],
      city: ['administrative_area_level_2', 'political'],
      country: ['country'],
      neighborhood: ['political', 'sublocality', 'sublocality_level_1'],
    };

    const address = {
      streetNumber: '',
      postalCode: '',
      street: '',
      province: '',
      city: '',
      country: '',
      neighborhood: '',
    };

    addressComponents.forEach((component) => {
      // eslint-disable-next-line no-restricted-syntax
      for (const shouldBe in ShouldBeComponent) {
        if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
          if (shouldBe === 'country') {
            address[shouldBe] = component.short_name;
          } else {
            address[shouldBe] = component.long_name;
          }
        }
      }
    });

    return address;
  };

  useEffect(() => {
    if (addressState && addressState.value) {
      geocodeByPlaceId(addressState.value.place_id).then(
        (response) => {
          const addressObject = getAddressObject(response[0].address_components);
          setValue('imputStreet', addressObject.street);
          setValue('imputState', addressObject.province);
          setValue('imputNeighborhood', addressObject.neighborhood);
          setValue('imputCity', addressObject.city);
          setValue('imputStreet', addressObject.address);
        },
      );
    }
  }, [addressState]);

  return (
    <>
      <FlexBox>
        <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
          <FlexBox
            p="0px 0"
            flexDirection="column"
            className={classes.textField}
          >
            {apiKey && (
              <div style={{ margin: '0px 0px 20px 0px' }}>
                <GooglePlacesAutocomplete
                  apiKey={apiKey}
                  autocompletionRequest={{
                    componentRestrictions: {
                      country: [country],
                    },
                    // types: ['(cities)'],
                  }}
                  selectProps={{
                    placeholder: translate('profile.address.shearch_address'),
                    isClearable: true,
                    value: addressState,
                    onChange: (val) => setAddressState(val),
                  }}
                />
              </div>
            )}
            <FlexBox height="80px">
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="demo-text-field-2"
                    value={field.value}
                    state={fieldState.error ? 'error' : undefined}
                    helpText={fieldState.error && translate(fieldState.error.message)}
                    multiline
                    rows={1}
                    label={translate('profile.address.street')}
                    size="semiX"
                    type="text"
                  />
                )}
                rules={{
                // required: required.message,
                }}
                control={control}
                name="imputStreet"
              />
            </FlexBox>
            <FlexBox height="80px">
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="demo-text-field-2"
                    value={field.value}
                    state={fieldState.error ? 'error' : undefined}
                    helpText={fieldState.error && translate(fieldState.error.message)}
                    multiline
                    rows={1}
                    label={translate('profile.address.state')}
                    size="semiX"
                    type="text"
                  />
                )}
                rules={{
                // required: required.message,
                }}
                control={control}
                name="imputState"
              />
            </FlexBox>
            <FlexBox height="80px" gap="15px">
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="demo-text-field-2"
                    value={field.value}
                    state={fieldState.error ? 'error' : undefined}
                    helpText={fieldState.error && translate(fieldState.error.message)}
                    multiline
                    rows={1}
                    label={translate('profile.address.number')}
                    size="semiX"
                    type="text"
                  />
                )}
                rules={{
                // required: required.message,
                }}
                control={control}
                name="imputNumber"
              />
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="demo-text-field-2"
                    value={field.value}
                    state={fieldState.error ? 'error' : undefined}
                    helpText={fieldState.error && translate(fieldState.error.message)}
                    multiline
                    rows={1}
                    label={translate('profile.address.neighborhood')}
                    size="semiX"
                    type="text"
                  />
                )}
                rules={{
                // required: required.message,
                }}
                control={control}
                name="imputNeighborhood"
              />
            </FlexBox>
            <FlexBox height="80px" gap="15px">
              <FlexBox>
                <Controller
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      id="demo-text-field-2"
                      value={field.value}
                      state={fieldState.error ? 'error' : undefined}
                      helpText={fieldState.error && translate(fieldState.error.message)}
                      multiline
                      rows={1}
                      label={translate('profile.address.postal_code')}
                      size="semiX"
                      type="text"
                    />
                  )}
                  rules={{
                    // required: required.message,
                  }}
                  control={control}
                  name="imputPostalCode"
                />
              </FlexBox>
              <FlexBox justifyContent="center">
                <LinkComponent
                  style={{ paddingTop: '30px' }}
                  color="primary"
                  onClick={() => window.open(searchZipCodeUrl, '_blank')}
                  underline="always"
                  href="#"
                  variant="body2"
                >
                  {translate('profile.address.helper_postal_code')}
                </LinkComponent>
              </FlexBox>
            </FlexBox>
            <FlexBox height="80px" gap="15px">
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="demo-text-field-2"
                    value={field.value}
                    state={fieldState.error ? 'error' : undefined}
                    helpText={fieldState.error && translate(fieldState.error.message)}
                    multiline
                    rows={1}
                    label={translate('profile.address.city')}
                    size="semiX"
                    type="text"
                  />
                )}
                rules={{
                // required: required.message,
                }}
                control={control}
                name="imputCity"
              />
              <Controller
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    id="demo-text-field-2"
                    value={field.value}
                    state={fieldState.error ? 'error' : undefined}
                    helpText={fieldState.error && translate(fieldState.error.message)}
                    multiline
                    rows={1}
                    label={translate('profile.address.floor_and_apartment')}
                    size="semiX"
                    type="text"
                  />
                )}
                rules={{
                // required: required.message,
                }}
                control={control}
                name="imputFloorAndApartment"
              />
            </FlexBox>
          </FlexBox>
          <FlexBox padding="0px 0px" justifyContent="end" gap={20}>
            <Button
              color="primary"
              onClick={() => cancelEdit()}
              variant="text"
            >
              {translate('cancel_button')}
            </Button>
            <Button
              color="primary"
              variant="contained"
              type="submit"
              disabled={Object.keys(formState.errors).length}
            >
              {translate('digital_space.save_button')}
            </Button>
          </FlexBox>
        </form>
      </FlexBox>
    </>
  );
};

export default Address;
