import React, { useContext, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import InputFile from '../../components/input-file/InputFile';
import { handleChangeFile } from '../../utils/image-utils';
import Typography from '../../components/typography/Typography';

const Salutation = () => {
  const { translate } = useContext(I18nContext);
  const { imageWelcome } = useStoreState((state) => state.main);
  const [image, setImage] = useState();
  const { setImageWelcome, setShowAlert, setNewImageWelcome } = useStoreActions((actions) => actions.main);

  async function handleChange(e) {
    const callbackError = (typeError) => {
      setShowAlert({
        message: translate(typeError ? 'digital_space.invalid_file_type' : 'digital_space.invalid_file_size'),
        type: 'error',
        id: new Date().getTime(),
      });
    };

    const callbackSuccessful = (base64) => {
      setImageWelcome(base64);
      setNewImageWelcome(base64);
    };

    handleChangeFile(e, callbackSuccessful, callbackError);
  }

  const getBase64FromUrl = () => {
    if (imageWelcome) {
      if (imageWelcome.includes('http://')
          || imageWelcome.includes('https://')
          || imageWelcome.includes('images.rede.natura.net')
          || imageWelcome.includes('s3.amazonaws')) {
        setImage(`${imageWelcome}?v=${Math.random() * 16}`);
      } else {
        setImage(`data:image/jpeg;base64,/9j/${imageWelcome}`);
      }
    } else {
      setImage();
    }
  };

  useEffect(() => {
    getBase64FromUrl();
  }, [imageWelcome]);

  return (
    <>
      <FlexBox p="0 30px" flexDirection="column">
        <FlexBox p="20px 0" flexDirection="column">
          <Typography m="0 0 10px 0" variant="h6">{translate('digital_space.salutation_title')}</Typography>
          <Typography m="10px 0">{translate('digital_space.salutation_text')}</Typography>
          <FlexBox flexDirection="column" alignItems="center">
            <FlexBox flexDirection="column" w="200px" borderRadius="5px" overflow="hidden" m="10px 0px">
              {image && (
                <img height="170px" width="200px" src={image} alt="cn" />
              )}
              <InputFile
                text={translate('digital_space.change_photo_button')}
                onChange={handleChange}
                idElement="SalutationData"
              />
            </FlexBox>
            <Typography color="textSecondary">{translate('digital_space.photo_spec1')}</Typography>
            <Typography color="textSecondary">{translate('digital_space.photo_spec2')}</Typography>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default Salutation;
