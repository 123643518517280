import { pushDataLayerError } from './reportError';

export const pushInteraction = (action, category, label) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'interaction',
      action,
      category,
      label,
    });
  } catch (e) {
    pushDataLayerError('interaction', e);
  }
};
