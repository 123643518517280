import { styled } from '@material-ui/core/styles';
import { Container } from '@naturacosmeticos/natds-web';

const StyledLoginModalError = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  position: 'fixed',
  top: 'calc( 50% - 82px )',
  maxWidth: '100%',
  left: 'calc( 50% - 200px )',
  width: ({ messageErrorResetPassword }) => (messageErrorResetPassword ? '600px' : '400px'),
  zIndex: '1301',
});

export default StyledLoginModalError;
