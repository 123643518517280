import React, { useContext, useState, useEffect } from 'react';
import { subDays } from 'date-fns';
import Card from '../../../components/card/Card';
import { I18nContext } from '../../../config/i18n';
import OrderStatusCard from './OrderStatusCard';
import FlexBox from '../../../components/flex-box/FlexBox';
import OrderData from './OrderData';
import OrderResume from './OrderResume';
import Typography from '../../../components/typography/Typography';
import Button from '../../../components/button/Button';
import Icon from '../../../components/icon/Icon';
import ProgressIndicator from '../../../components/progress-indicator/ProgressIndicator';
import CustomerHistoryOrderTable from './CustomerHistoryOrderTable';
import fetcher from '../../../api/fetcher';
import { generateHash, getPersonId } from '../../../utils/encrypt-utils';

const CustomerOrderInformation = ({
  loading, data, paymentTypesTranslate, orderStatusTranslate, trackingStatus,
}) => {
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const [open, setOpen] = useState(false);
  const [orderData, setOrderData] = useState([]);
  const personId = getPersonId();

  useEffect(() => {
    const customerNumber = data?.customerId;
    const date = subDays(new Date(), 60);
    if (customerNumber) {
      fetcher().post('order_search', {
        headers: {
          personId,
          cnId: generateHash(personId),
        },
        json: {
          customer_no: customerNumber,
          selectedCn: personId,
          creationDate: date,
        },
      }).json().then((orderInfo) => {
        setOrderData(orderInfo);
      })
        .catch((er) => {
          console.log(er);
        });
    }
  }, [data]);

  function handleClick() {
    setOpen(!open);
  }
  return (
    <>
      <Card minHeight={loading && '250px'} alignItems={loading && 'center'} justifyContent={loading && 'center'}>
        {loading && <ProgressIndicator />}
        {!loading && (
        <FlexBox alignItems="center" m="5px 0 10px 0" justifyContent="space-between">
          <Typography variant="h5">{translate('sales.customer_orders.title')}</Typography>
          <Button onClick={handleClick}>
            <Icon
              size="small"
              color="primary"
              name={open ? 'outlined-navigation-arrowtop' : 'outlined-navigation-arrowbottom'}
            />
          </Button>
        </FlexBox>
        )}
        {!loading && open && (
        <>
          <FlexBox flexDirection={isMobile && 'column'}>
            <OrderStatusCard
              data={data}
              trackingStatus={trackingStatus}
            />
            <OrderData
              trackingStatus={trackingStatus}
              orderStatusTranslate={orderStatusTranslate}
              paymentTypesTranslate={paymentTypesTranslate}
              data={data}
            />
          </FlexBox>
          <OrderResume data={data} />
          {orderData && (
          <CustomerHistoryOrderTable
            orderStatusTranslate={orderStatusTranslate}
            data={data}
            orderData={orderData}
          />
          )}
        </>
        )}
      </Card>
    </>
  );
};

export default CustomerOrderInformation;
