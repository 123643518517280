import React, { useContext, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import FlexBox from '../../components/flex-box/FlexBox';
import Card from '../../components/card/Card';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import DualText from '../../components/dual-text/DualText';

function PersonContact() {
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);

  const { personEmail, phoneHome } = useStoreState((state) => state.main);

  return (
    <Card>
      <FlexBox>
        <Typography m="0 0 10px 0" variant="h5" color="primary">{translate('profile.contact_title')}</Typography>
      </FlexBox>
      <FlexBox flexDirection={isMobile && 'column'}>
        <FlexBox flexDirection="column">
          <DualText
            text1={translate('profile.phone')}
            text2={phoneHome}
          />
          <DualText
            text1={translate('profile.email')}
            text2={personEmail}
          />
        </FlexBox>
      </FlexBox>
    </Card>
  );
}

export default PersonContact;
