import customerModel from './customers-model';
import salesModel from './sales-model';
// eslint-disable-next-line import/no-named-as-default
import mainModel from './main-model';
import couponsModel from './coupons-model';
import abandonedOrdersModel from './abandoned-orders-model';
import visitsModel from './visits-model';

const storeModel = {
  customers: customerModel,
  sales: salesModel,
  main: mainModel,
  coupons: couponsModel,
  abandonedOrders: abandonedOrdersModel,
  visits: visitsModel,
};

export default storeModel;
