import React, { useContext } from 'react';
import Typography from '../../components/typography/Typography';
import Card from '../../components/card/Card';
import FlexBox from '../../components/flex-box/FlexBox';
import { I18nContext } from '../../config/i18n';
import Icon from '../../components/icon/Icon';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';

const TotalAbandonedOrders = ({ data, loading }) => {
  const { translate } = useContext(I18nContext);
  const { baskets } = data;
  return (
    <>
      <Card>
        <FlexBox justifyContent="center" alignItems="center">
          <Icon name="outlined-product-abandonedbag" />
          <Typography variant="h5">{translate('abandoned_orders.kpi.title')}</Typography>
          {loading && (
          <FlexBox w="32px" flex="unset" m="0 0 0 10px">
            <ProgressIndicator size={32} />
          </FlexBox>
          )}
          {!loading && <Typography m="5px 10px" variant="h5">{baskets && baskets.length}</Typography>}
        </FlexBox>
      </Card>
    </>
  );
};

export default TotalAbandonedOrders;
