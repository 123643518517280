import React, { useContext, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import Dialog from '../../components/dialog/Dialog';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';
import DialogTitle from '../../components/dialog-title/DialogTitle';
import DialogContent from '../../components/dialog-content/DialogContent';
import DialogActions from '../../components/dialog-actions/DialogActions';
import Button from '../../components/button/Button';
import config from '../../config/config';

const URLBase = process.env.REACT_APP_URL_BASE_IMAGE;

const AbondonedOrderDetail = ({ code, toggleCloseModal }) => {
  const { translate } = useContext(I18nContext);
  const { fetchAbandonedBag } = useStoreActions((actions) => actions.abandonedOrders);
  const { individualAbandonedOrder } = useStoreState((state) => state.abandonedOrders);
  const { customerNo, personId } = useStoreState((state) => state.main);

  useEffect(() => {
    const indentifier = config.general.company === 'natura' ? customerNo : personId;
    fetchAbandonedBag({ orderId: code, indentifier });
  }, []);

  return (
    <>
      <Dialog
        maxWidth="sm"
        open
        onEscapeKeyDown={toggleCloseModal}
      >
        {!individualAbandonedOrder && (
        <FlexBox p="100px">
          <ProgressIndicator />
        </FlexBox>
        )}
        {individualAbandonedOrder && (
        <>
          <DialogTitle>
            {translate('abandoned_orders.abandoned_bag_modal.title')}
          </DialogTitle>
          <DialogContent overflow="hidden">
            {individualAbandonedOrder.items ? (
              <>
                <FlexBox m="5px" alignItems="center">
                  <FlexBox minWidth="100px" flex="1 2 auto">
                    <Typography>
                      {translate('abandoned_orders.abandoned_bag_modal.product')}
                    </Typography>
                  </FlexBox>
                  <FlexBox p="10px" />
                  <FlexBox minWidth="70px" flex="1 2 auto">
                    <Typography>
                      {translate('abandoned_orders.abandoned_bag_modal.quantity')}
                    </Typography>
                  </FlexBox>
                </FlexBox>
                {individualAbandonedOrder.items && individualAbandonedOrder.items.map((order) => (
                  <FlexBox key={order.productName} m="5px" alignItems="center">
                    <FlexBox minWidth="100px" flex="1 2 auto">
                      <img
                        width="70px"
                        src={URLBase + order.imageURL}
                        alt={order.productName}
                      />
                    </FlexBox>
                    <FlexBox p="10px">
                      <Typography>
                        {order.productName}
                      </Typography>
                    </FlexBox>
                    <FlexBox minWidth="70px" flex="1 2 auto">
                      <Typography>
                        {order.quantity}
                      </Typography>
                    </FlexBox>
                  </FlexBox>
                ))}
              </>
            ) : (
              <>
                <FlexBox minWidth="100px" flex="1 2 auto">
                  <Typography>
                    {translate('abandoned_orders.there_arent_details')}
                  </Typography>
                </FlexBox>
              </>
            )}

          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={toggleCloseModal}
              variant="text"
            >
              {translate('abandoned_orders.abandoned_bag_modal.close')}
            </Button>
          </DialogActions>
        </>
        )}
      </Dialog>
    </>
  );
};

export default AbondonedOrderDetail;
