import React, { useContext } from 'react';
import Button from '../button/Button';
import { I18nContext } from '../../config/i18n';
import Page from '../page/Page';
import FlexBox from '../flex-box/FlexBox';
import Typography from '../typography/Typography';
import sadFace from '../../assets/sad-face.svg';
import Image from '../image/Image';

const ErrorScreen = ({ action, payload }) => {
  const { translate } = useContext(I18nContext);
  function handleClick() {
    action(payload);
  }
  return (
    <Page>
      <FlexBox
        alignItems="center"
        justifyContent="center"
        minHeight={`${window.innerHeight - 136}px`}
        flexDirection="column"
      >
        <Image alt="Sad face image" src={sadFace} />
        <Typography textAlign="center" mw="250px" margin="10px 0">
          {translate('error_screen')}
        </Typography>
        {action && (
        <Button onClick={handleClick}>
          <Typography>
            {translate('error_screen.reload_button')}
          </Typography>
        </Button>
        )}
      </FlexBox>
    </Page>
  );
};

export default ErrorScreen;
