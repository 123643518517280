import React from 'react';
import StyledPopover from './Popover.styles';

const Popover = ({ children, ...props }) => (
  <StyledPopover
    className={props.className}
    open={props.open}
    style={props.style}
    id={props.id}
    anchorEl={props.anchorEl}
    maxWidth={props.maxWidth}
    placement={props.placement}
    popperOptions={props.popperOptions}
    props={props}
  >
    {children}
  </StyledPopover>
);

export default Popover;
