import { makeStyles } from '@naturacosmeticos/natds-web';

export const shareCouponModalStyle = makeStyles(() => ({
  positionImg: {
    position: 'absolute',
    top: '0',
    left: '0',
    right: '0',
    bottom: '0',
  },
  dialog: {
    '& .MuiDialog-paper': {
      margin: window.innerWidth < 1080 && '10px',
    },
    '& .MuiIcon-root': {
      color: (props) => props.color.success,
    },
  },
}));
