import { makeStyles } from '@naturacosmeticos/natds-web';

export const dropdownLanguagesStyle = makeStyles(() => ({
  hideButton: {
    position: 'fixed',
    backgroundColor: 'transparent',
    border: 'none',
    top: '0',
    left: '0',
    right: '0',
    height: '100%',
    width: '100%',
    zIndex: '1301',
  },
}));
