import React, { useState } from 'react';
import { useStoreState } from 'easy-peasy';
import CarouselBanner from '../../components/carousel/CarouselBanner';
import { generateUUID } from '../../utils/aux-utils';
import FlexBox from '../../components/flex-box/FlexBox';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';
import Card from '../../components/card/Card';

const Banner = () => {
  const { dataBanner, loadingDataBanner } = useStoreState((state) => state.main);
  const [isMobile] = useState(window.innerWidth < 1080);

  return (
    <>
      {(loadingDataBanner || (dataBanner && dataBanner.length > 0)) && (
        <Card maxWidth="100%" p={isMobile && '16px 0px'}>
          <FlexBox
            justifyContent="space-between"
            alignItems={isMobile ? 'flex-start' : 'center'}
            minWidth="100%"
            flexDirection={isMobile ? 'column' : 'row'}
          >
            {loadingDataBanner ? (
              <FlexBox alignItems="center" justifyContent="center">
                <ProgressIndicator size={24} />
              </FlexBox>
            ) : (
              <CarouselBanner key={generateUUID()} items={dataBanner} />
            )}
          </FlexBox>
        </Card>
      )}
    </>
  );
};

export default Banner;
