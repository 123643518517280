import React from 'react';
import { useTheme } from '@material-ui/styles';
import StyledCard from './Card.styles';

const Card = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <StyledCard theme={theme} className={props.className} props={props}>
      {children}
    </StyledCard>
  );
};

export default Card;
