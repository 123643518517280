import React, { useReducer } from 'react';

import config from '../config';
import EN from './en.json';
import PT from './pt.json';
import ES from './es.json';
import ES_AR from './es-ar.json';
import ES_CH from './es-ch.json';
import ES_NAT_MX from './es-nat-mx.json';

const translations = {
  en: EN,
  pt: PT,
  es: ES,
  es_ar: ES_AR,
  es_nat_mx: ES_NAT_MX,
  es_ch: ES_CH,
};

const getTranslate = (langCode) => (key, vars) => {
  if (vars && Array.isArray(vars)) {
    let text = translations[langCode][key] || key;
    vars.forEach((e, index) => {
      text = text.replace(`{${index}}`, e.toString());
    });
    return text;
  }
  return translations[langCode][key] || key;
};

const initialLang = config.general.language;

const initialState = {
  langCode: initialLang,
  translate: getTranslate(initialLang),
};

export const I18nContext = React.createContext(initialState);

export const I18nContextProvider = ({ children }) => {
  const reducer = (state, action) => {
    switch (action.type) {
      case 'setLanguage':
        return {
          langCode: action.payload,
          translate: getTranslate(action.payload),
        };
      default:
        return { ...initialState };
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  return (
    <I18nContext.Provider value={{ ...state, dispatch }}>
      {children}
    </I18nContext.Provider>
  );
};
