import {
  parseISO, format, getMonth, isWithinInterval, getYear, getDate, formatISO, add, isSameDay,
} from 'date-fns';
import { useContext } from 'react';
import { I18nContext } from '../config/i18n';

export function formatDateIso(dateISO) {
  const { translate } = useContext(I18nContext);
  try {
    const isodate = parseISO(dateISO);
    return format(isodate, translate('format.date'));
  } catch {
    return dateISO;
  }
}

// dateTime = new Date or getTime() ex: 1664992780254
export function formatDateTime(dateTime) {
  const { translate } = useContext(I18nContext);
  try {
    return format(dateTime, translate('format.date'));
  } catch {
    return dateTime;
  }
}

export function formatDateIsoWithPropsTranslate(dateISO, translate) {
  try {
    return format(parseISO(dateISO), translate('format.date'));
  } catch {
    return dateISO;
  }
}

export function formatDateIsoToFetch(dateISO) {
  try {
    return format(dateISO, 'yyyy-MM-dd');
  } catch {
    return dateISO;
  }
}

export function getAWeekBeforeFrom(dateISO) {
  try {
    return add(dateISO, { weeks: -1 });
  } catch {
    return dateISO;
  }
}

export function formatDateToExport(dateISO, dateFormat) {
  try {
    return format(parseISO(dateISO), dateFormat);
  } catch {
    return dateISO;
  }
}

export function formatDateTimeIso(unixDate, formatDate) {
  try {
    const isodate = formatISO(unixDate);
    return format(parseISO(isodate), formatDate);
  } catch {
    return unixDate;
  }
}

export function sameMonth(dateISO) {
  return getMonth(parseISO(dateISO)) === getMonth(new Date());
}

export function sameDay(dateOne, dateTwo) {
  return isSameDay(dateOne, dateTwo);
}

export function betweenDates(dateISO, startDate, endDate) {
  const start = new Date(getYear(new Date()), getMonth(startDate), getDate(startDate));
  const end = new Date(getYear(new Date()), getMonth(endDate), getDate(endDate));
  const date = new Date(getYear(new Date()), getMonth(dateISO), getDate(dateISO));
  return isWithinInterval(date, { start, end });
}
