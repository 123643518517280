import React from 'react';
import StyledTypography from './Typography.styles';

const Typography = ({ children, ...props }) => (
  <StyledTypography
    className={props.className}
    align={props.align}
    color={props.error ? 'error' : props.color}
    variant={props.variant}
    props={props}
  >
    {children}
  </StyledTypography>
);

export default Typography;
