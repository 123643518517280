const blockedRoutes = {
  'avon-mx': [
    '/coupons',
    '/profile',
    '/digital-space',
    '/visits',
  ],
  'natura-ch': [],
};

export default () => {
  const routesFind = blockedRoutes[process.env.REACT_APP_ROUTE_CONFIG || 'natura-ch'];
  return routesFind || [];
};
