import React, { useEffect, useState, useContext } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import FlexBox from '../../../components/flex-box/FlexBox';
import Page from '../../../components/page/Page';
import GoBackBtn from '../../../components/go-back-btn/GoBackBtn';
import Presentation from './Presentation';
import NewCouponCard from './NewCouponCard';
import ErrorScreen from '../../../components/error-screen/ErrorScreen';
import ErrorModal from '../../../components/error-modal/ErrorModal';
import BlocklistModal from '../../../components/blocklist-modal/BlocklistModal';
import DuplicateCoupon from '../../../components/duplicate-coupon/DuplicateCoupon';
import GenericError from '../../../components/generic-error-modal/GenericError';
import { I18nContext } from '../../../config/i18n';

function NewCoupon() {
  const { translate } = useContext(I18nContext);
  const [openModal, setOpenModal] = useState('');
  const [openBlocklistModal, setOpenBlocklistModal] = useState('');
  const [openDuplicateCoupon, setOpenDuplicateCoupon] = useState('');
  const [openGenericError, setOpenGenericError] = useState('');
  const [messageError, setMessageError] = useState();
  // eslint-disable-next-line object-curly-newline, max-len
  const { error, modalError, blocklistModal, duplicateCouponModal, genericError } = useStoreState((state) => state.coupons);
  // eslint-disable-next-line max-len
  const {
    setModalError, setBlocklistModal, setDuplicateCouponModal, setGenericErrorModal,
  } = useStoreActions((state) => state.coupons);

  function closeModalError() {
    setModalError();
  }

  function closeBlocklistModal() {
    setBlocklistModal();
    setOpenBlocklistModal(false);
  }

  function closeDuplicateCupon() {
    setDuplicateCouponModal();
    setOpenDuplicateCoupon(false);
  }

  function closeGenericError() {
    setGenericErrorModal();
    setOpenGenericError(false);
  }

  const getMessageError = () => {
    if (modalError.translate) {
      return translate(modalError.message);
    } if (modalError.message) {
      return modalError.message;
    }
    return translate('error-modal-text');
  };

  useEffect(() => {
    if (modalError) {
      setMessageError(getMessageError());
      setOpenModal(true);
    } else if (blocklistModal) {
      setOpenBlocklistModal(true);
    } else if (duplicateCouponModal) {
      setOpenDuplicateCoupon(true);
    } else if (genericError) {
      setOpenGenericError(true);
    }
  }, [modalError, blocklistModal, duplicateCouponModal, genericError]);

  return (
    <>
      {error && <ErrorScreen />}
      {!error
      && (
        <Page>
          <ErrorModal
            openModal={openModal}
            messageError={messageError}
            toClose={closeModalError}
          />
          <BlocklistModal
            openModal={openBlocklistModal}
            toClose={closeBlocklistModal}
          />
          <DuplicateCoupon
            openModal={openDuplicateCoupon}
            toClose={closeDuplicateCupon}
          />
          <GenericError
            openModal={openGenericError}
            toClose={closeGenericError}
          />
          <FlexBox>
            <GoBackBtn />
          </FlexBox>
          <FlexBox flexDirection="column">
            <Presentation />
            <NewCouponCard />
          </FlexBox>
        </Page>
      )}
    </>
  );
}

export default NewCoupon;
