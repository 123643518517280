import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import { StoreProvider, useStoreState } from 'easy-peasy';
import { CssBaseline, Provider, themes } from '@naturacosmeticos/natds-web';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns'; // Just for MuiPickers
import { startCase } from 'lodash';
import config from './config/config';
import reportWebVitals from './reportWebVitals';

import App from './App';
import store from './store';
import { I18nContextProvider } from './config/i18n';
// import { makeServer } from './mock';
import GoogleTagManager from './data-layer/GoogleTagManager';

import '@naturacosmeticos/natds-icons/dist/natds-icons.css';

const rootElement = document.getElementById('root');

const Index = () => {
  const { theme } = useStoreState((state) => state.main);

  // if (process.env.NODE_ENV === 'development') {
  //   makeServer().then(() => console.log('MOCK server up!!'));
  // }

  useEffect(() => {
    const setCompany = async () => {
      const favicon = document.getElementById('favicon');
      favicon.href = `src/assets/favicon_${config.general.company}.ico`;

      document.title = startCase(`Painel ${config.general.company}`);
    };
    setCompany();
  }, []);

  return (
    <Provider theme={themes[config.general.company][theme]}>
      <CssBaseline />
      <I18nContextProvider>
        <GoogleTagManager />
        <App />
      </I18nContextProvider>
    </Provider>
  );
};

ReactDOM.render(
  <MuiPickersUtilsProvider utils={DateFnsUtils}>
    <StoreProvider store={store}>
      <Index />
    </StoreProvider>
  </MuiPickersUtilsProvider>,
  rootElement,
);

reportWebVitals();
