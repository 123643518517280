import React, {
  useContext, useRef, useMemo, memo,
} from 'react';
import { Line } from 'react-chartjs-2';
import { useTheme } from '@material-ui/styles';
import { parseISO, eachDayOfInterval, differenceInCalendarDays } from 'date-fns';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import { formatDateIsoWithPropsTranslate } from '../../utils/date-utils';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';

const LineChart = ({ isMobile, dataBase = {}, loading }) => {
  const { color } = useTheme();
  const { visitsPerWeek = [] } = dataBase;
  const lineRef = useRef();
  const { translate } = useContext(I18nContext);

  const agroupByDate = (list) => {
    if (list && list.length > 0) {
      const newSourceMediumState = [];
      list.forEach((e) => {
        const findElement = newSourceMediumState.find((element) => element.date === e.date);
        if (!findElement) {
          newSourceMediumState.push(e);
        } else {
          const indexOfElement = newSourceMediumState.indexOf(findElement);
          newSourceMediumState[indexOfElement] = {
            ...e,
            users: Number(e.users) + Number(findElement.users),
          };
        }
      });

      return newSourceMediumState;
    }
    return [];
  };

  const completeDatas = (list) => {
    let dataList = [];
    for (let i = 0; i < list.length; i += 1) {
      const actualElement = list[i];
      if (i < list.length) {
        const nextElement = list[i + 1];
        dataList = [...dataList, actualElement];

        if (actualElement && actualElement.date && nextElement && nextElement.date) {
          const startDate = parseISO(actualElement.date);
          const endDate = parseISO(nextElement.date);

          if (differenceInCalendarDays(endDate, startDate) > 1) {
            const listWithDays = eachDayOfInterval({
              start: startDate,
              end: endDate,
            });

            if (listWithDays.length > 2) {
              listWithDays.shift();
              listWithDays.pop();
            }

            dataList = [...dataList, ...listWithDays.map((e) => ({
              medium: '',
              date: `${e.getFullYear()}${e.getMonth() + 1}${e.getDate()}`,
              sessions: '0',
              users: '0',
            }))];
          }
        }
      }
    }
    return agroupByDate(dataList);
  };

  function monteDataChartWithMemo() {
    return useMemo(() => completeDatas(visitsPerWeek), [visitsPerWeek]);
  }

  const visitsPerWeekState = monteDataChartWithMemo();
  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          color: color.neutral800,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: color.neutral800,
        },
      },
      y: {
        ticks: {
          color: color.neutral800,
          precision: 0,
        },
      },
    },
  };
  const visitsData = visitsPerWeekState ? visitsPerWeekState.map((visit) => visit.users) : [];
  const labelsData = visitsPerWeekState
    ? visitsPerWeekState.map((visit) => formatDateIsoWithPropsTranslate(visit.date, translate)) : [];
  const data = {
    labels: labelsData,
    datasets: [
      {
        label: translate('visits.charts.line_label'),
        data: visitsData,
        fill: false,
        backgroundColor: 'rgb(255, 99, 132)',
        borderColor: 'rgba(255, 99, 132, 0.2)',
      },
    ],
  };
  return (
    <FlexBox
      maxWidth={!isMobile && '66%'}
      minWidth="300px"
      minHeight={!isMobile && '350px'}
      m={isMobile && '10px 0'}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex="2"
    >
      {!loading && visitsData.length > 0 && (
      <>
        <Typography c={color.highlight} textAlign="center">
          {translate('visits.charts.line_title')}
        </Typography>
        <Line ref={lineRef} data={data} options={options} />
      </>
      )}
      {!loading && visitsData.length === 0 && (
        <Typography c={color.highlight} textAlign="center">
          {translate('visits.charts.chart_no_data')}
        </Typography>
      )}
      {loading && <ProgressIndicator />}
    </FlexBox>
  );
};

export default memo(LineChart);
