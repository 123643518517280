import React, {
  useContext, useRef, useMemo, memo,
} from 'react';
import { Pie } from 'react-chartjs-2';
import { useTheme } from '@material-ui/styles';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';

const PieChart = ({ isMobile, dataBase = {}, loading }) => {
  const { translate } = useContext(I18nContext);
  const { sourceMedium = [] } = dataBase;
  const { color } = useTheme();
  const pieRef = useRef();

  const getFlowTracker = (label) => {
    if (label === 'google / organic' || label === 'bing / organic') {
      return translate('visits.charts.line.google_search');
    } if (label === '') {
      return translate('visits.charts.line.google_ads');
    } if (label === '(direct) / (none)') {
      return translate('visits.charts.line.direct_access');
    } if (label === 'social_selling / compartilhamento') {
      return translate('visits.charts.line.facebook');
    }
    return translate('visits.charts.line.other_campaign');
  };

  const agroupValues = () => {
    let listReturn = [];
    sourceMedium.forEach((e) => {
      const getTranslateLabel = getFlowTracker(e.medium);
      const elementFind = listReturn.find((element) => element.medium === getTranslateLabel);
      if (!elementFind) {
        listReturn = [
          ...listReturn, {
            quantitySession: e.quantitySession,
            quantityUser: e.quantityUser,
            quantityNewUser: e.quantityNewUser,
            medium: getTranslateLabel,
          }];
      } else {
        const IndexOfElement = listReturn.indexOf(elementFind);
        listReturn[IndexOfElement] = {
          ...elementFind,
          quantityUser: Number(elementFind.quantityUser) + Number(e.quantityUser),
        };
      }
    });
    return listReturn;
  };

  function monteDataChartWithMemo() {
    return useMemo(() => agroupValues(sourceMedium), [sourceMedium]);
  }

  const sourceMediumState = monteDataChartWithMemo();

  const data = {
    labels: sourceMediumState ? sourceMediumState.map((item) => item.medium) : [],
    datasets: [
      {
        label: '# of Votes',
        data: sourceMediumState ? sourceMediumState.map((item) => (item.quantityUser)) : [],
        backgroundColor: [
          'rgba(255, 99, 132, 0.3)',
          'rgba(75, 192, 192, 0.3)',
          'rgba(255, 206, 86, 0.3)',
          'rgba(54, 162, 235, 0.3)',
          'rgba(120,192,75,0.3)',
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(75, 192, 192, 1)',
          'rgba(255, 206, 86, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(120,192,75,1)',
        ],
        borderWidth: 1,
      },
    ],
  };
  const options = {
    plugins: {
      legend: {
        display: true,
        labels: {
          color: color.neutral900,
        },
      },
    },
  };

  return (
    <FlexBox
      maxWidth={!isMobile && '33%'}
      minWidth="300px"
      minHeight={!isMobile && '350px'}
      m={isMobile && '10px 0'}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      flex="1"
    >
      {!loading && sourceMediumState.length > 0 && (
      <>
        <Typography c={color.highlight} textAlign="center">
          {translate('visits.charts.pie_title')}
        </Typography>
        <Pie options={options} ref={pieRef} data={data} />
      </>
      )}
      {!loading && sourceMediumState.length === 0 && (
        <Typography c={color.highlight} textAlign="center">
          {translate('visits.charts.chart_no_data')}
        </Typography>
      )}
      {loading && <ProgressIndicator />}
    </FlexBox>
  );
};

export default memo(PieChart);
