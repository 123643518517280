import React, { useContext } from 'react';
import { useTheme } from '@material-ui/styles';
import { I18nContext } from '../../config/i18n';
import Typography from '../../components/typography/Typography';
import FlexBox from '../../components/flex-box/FlexBox';
import CouponOne from '../../assets/coup1.png';
import CouponTwo from '../../assets/coup2.png';
import { shareCouponModalStyle } from './ShareCouponModal.style';
import { formatDateTime } from '../../utils/date-utils';
import Dialog from '../../components/dialog/Dialog';
import DialogTitle from '../../components/dialog-title/DialogTitle';
import DialogContent from '../../components/dialog-content/DialogContent';
import Logo from '../../components/logo/Logo';
import Button from '../../components/button/Button';
import Icon from '../../components/icon/Icon';
import DialogActions from '../../components/dialog-actions/DialogActions';

const ShareCouponModal = ({ data, toggleshareModal }) => {
  const { color } = useTheme();
  const { positionImg, dialog } = shareCouponModalStyle({ color });
  const { translate } = useContext(I18nContext);
  function shareWhatsapp() {
    const encoded = encodeURIComponent(`${translate('coupons.share_code_whats')} ${data.coupon.code}`);
    const link = `https://wa.me/?text=${encoded}`;
    window.open(link);
  }

  return (
    <>
      <Dialog
        className={dialog}
        maxWidth="sm"
        open={data.open}
        onEscapeKeyDown={toggleshareModal}
      >
        <DialogTitle>
          <Typography align="center" variant="h5" c={color.success}>
            {translate('coupons.share_coupon_modal_title')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <FlexBox flexDirection="column">
            <Typography c={color.mediumEmphasis} align="center">
              {translate('coupon.share_modal')}
            </Typography>
            <Typography m="20px 0" align="center">
              {`${translate('coupon.share_modal_code')} ${data.coupon.code}`}
            </Typography>
            <FlexBox justifyContent="center">
              <FlexBox w="max-content" position="relative">
                <img
                  src={CouponOne}
                  alt="Coupon img"
                />
                <FlexBox alignItems="center" justifyContent="center" className={positionImg}>
                  <Logo
                    color="surface"
                    size="huge"
                    model="b"
                  />
                </FlexBox>
              </FlexBox>
              <FlexBox position="relative" w="max-content">
                <img
                  src={CouponTwo}
                  alt="Coupon img"
                />
                <FlexBox flexDirection="column" alignItems="center" justifyContent="center" className={positionImg}>
                  <FlexBox justifyContent="center" overflow="hidden">
                    <Typography variant="h4">{data.coupon.description}</Typography>
                  </FlexBox>
                  <Typography m="10px 0 0 0 " variant="body2">
                    {translate('coupons.share_coupon_end_date')}
                  </Typography>
                  <Typography variant="body2">
                    {data.coupon.endDate && formatDateTime(data.coupon.endDate)}
                  </Typography>
                </FlexBox>
              </FlexBox>
            </FlexBox>
          </FlexBox>
          <FlexBox m="20px 0" justifyContent="center">
            <Button onClick={shareWhatsapp}>
              <Icon size="small" name="filled-brand-whatsapp" />
              <Typography m="0 10px">Enviar via Whatsapp</Typography>
            </Button>
          </FlexBox>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={toggleshareModal}
            variant="text"
          >
            {translate('cancel_button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ShareCouponModal;
