import { Typography } from '@naturacosmeticos/natds-web';
import { styled } from '@material-ui/core/styles';

const StyledTypography = styled(Typography)({
  padding: ({ props }) => props.padding || props.p || '0',
  alignItems: ({ props }) => props.alignItems || 'unset',
  lineHeight: ({ props }) => props.lineHeight,
  fontSize: ({ props }) => props.fz || '',
  whiteSpace: ({ props }) => props.whiteSpace,
  wordBreak: ({ props }) => props.wordBreak,
  margin: ({ props }) => props.margin || props.m,
  textAlign: ({ props }) => props.textAlign,
  color: ({ props }) => props.color || props.c,
  display: ({ props }) => props.display,
  maxWidth: ({ props }) => props.mw || props.maxWidth,
  fontWeight: ({ props }) => props.fw || props.fontWeight,
  '@media (max-width: 960px)': {
    fontSize: ({ props }) => props.mediaQ && '24px',
  },
  '@media (min-width: 960px)': {
    minWidth: ({ props }) => props.minWidth,
  },
});

export default StyledTypography;
