import React, { useContext, useState, useEffect } from 'react';
import { makeStyles } from '@naturacosmeticos/natds-web';
import { DatePicker } from '@material-ui/pickers';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import { formatDateTimeIso } from '../../utils/date-utils';
import Button from '../../components/button/Button';
import Icon from '../../components/icon/Icon';
import Drawer from '../../components/drawer/Drawer';
import Intro from '../../components/intro/Intro';
import Select from '../../components/select/Select';
import { pushInteraction } from '../../data-layer/interaction';
import { modelDateInterval } from '../../data-layer/modeling';

const useStyles = makeStyles((theme) => ({
  periodButton: {
    '& .MuiFormHelperText-root': {
      color: theme.color.alert,
    },
    flexWrap: 'wrap',
  },
  clearBtn: {
    padding: '0',
    minWidth: '35px',
    height: '29px',
    marginLeft: '5px',
  },
  bottomButtonHolder: {
    position: 'absolute',
    bottom: '0',
    left: '0',
    right: '0',
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiButton-root': {
      borderRadius: '0',
    },
  },
  bottomButton: {
    flex: '1',
    width: '100%',
  },
  selectButton: {
    margin: '15px 0 5px 0',
  },
  datePickerStyle: {
    '& .MuiFormControl-root': {
      flex: 1,
    },
  },
}));
const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());
const optionsSelectYear = [
  {
    description: '2011',
    value: 2011,
  },
  {
    description: '2012',
    value: 2012,
  },
  {
    description: '2013',
    value: 2013,
  },
  {
    description: '2014',
    value: 2014,
  },
  {
    description: '2015',
    value: 2015,
  },
  {
    description: '2016',
    value: 2016,
  },
  {
    description: '2017',
    value: 2017,
  },
  {
    description: '2018',
    value: 2018,
  },
  {
    description: '2019',
    value: 2019,
  },
  {
    description: '2020',
    value: 2020,
  },
  {
    description: '2021',
    value: 2021,
  },
];
const optionsSelectCycle = [
  {
    description: '1',
    value: 1,
  },
  {
    description: '2',
    value: 2,
  },
  {
    description: '3',
    value: 3,
  },
  {
    description: '4',
    value: 4,
  },
  {
    description: '5',
    value: 5,
  },
  {
    description: '6',
    value: 6,
  },
  {
    description: '7',
    value: 7,
  },
  {
    description: '8',
    value: 8,
  },
  {
    description: '9',
    value: 9,
  },
  {
    description: '10',
    value: 10,
  },
  {
    description: '11',
    value: 11,
  },
  {
    description: '12',
    value: 12,
  },
  {
    description: '13',
    value: 13,
  },
  {
    description: '14',
    value: 14,
  },
  {
    description: '15',
    value: 15,
  },
  {
    description: '16',
    value: 16,
  },
  {
    description: '17',
    value: 17,
  },
  {
    description: '18',
    value: 18,
  },
  {
    description: '19',
    value: 19,
  },
  {
    description: '20',
    value: 20,
  },
];

const defaultDate = new Date();
defaultDate.setDate(defaultDate.getDate() - 30);
defaultDate.setHours(0, 0, 0, 0);

function FilterBar() {
  // eslint-disable-next-line max-len
  const storedInitialDate = localStorage.getItem('initialDate') ? new Date(localStorage.getItem('initialDate')) : defaultDate;
  const storedFinalDate = localStorage.getItem('finalDate') ? new Date(localStorage.getItem('finalDate')) : new Date();
  const [isMobile] = useState(window.innerWidth < 1080);
  const { translate } = useContext(I18nContext);
  const formatDate = translate('format.date');
  const [initialDate, setInitialDate] = useState(storedInitialDate);
  const [finalDate, setFinalDate] = useState(storedFinalDate);
  // const [maxDate, setMaxDate] = useState();
  const [, setYear] = useState(new Date().getFullYear());
  const [, setFilterCycle] = useState(0);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [cicleActive] = useState(false);
  const classes = useStyles();
  const { filter, loading } = useStoreState((state) => state.sales);
  const { personId } = useStoreState((state) => state.main);
  const { setFilter, fetchSales } = useStoreActions((actions) => actions.sales);

  useEffect(() => {
    if (storedInitialDate && storedFinalDate) {
      setInitialDate(new Date(storedInitialDate));
      setFinalDate(new Date(storedFinalDate));
    } else {
      setInitialDate(defaultDate);
      setFinalDate(new Date());
    }
  }, [filter]);

  useEffect(() => {
    if (initialDate <= finalDate) {
      const date = new Date(initialDate.getTime() + 2592000000);
      date.setHours(0, 0, 0, 0);
      setFinalDate(date);

      localStorage.setItem('initialDate', initialDate.toString());
      localStorage.setItem('finalDate', date.toString());
    }
  }, [initialDate]);

  function applyFilter() {
    if (initialDate <= finalDate) {
      const initialNewDate = formatDateTimeIso(initialDate.getTime(), formatDate);
      const finalNewDate = formatDateTimeIso(finalDate.getTime(), formatDate);
      setFilter({ ...filter, period: { initialDate, finalDate } });
      fetchSales({ initialDate: initialNewDate, finalDate: finalNewDate, personId });
      pushInteraction('filter', 'order', modelDateInterval(initialDate, finalDate));
    }
  }

  function clear() {
    localStorage.removeItem('initialDate');
    localStorage.removeItem('finalDate');
    setFilter({ ...filter, period: null, cycle: null });
    const initialNewDate = formatDateTimeIso(today.getTime() - 1296000000, formatDate);
    const finalNewDate = formatDateTimeIso(today.getTime(), formatDate);
    setInitialDate(defaultDate);
    setFinalDate(new Date());
    fetchSales({
      initialDate: initialNewDate,
      finalDate: finalNewDate,
      personId,
    });
    pushInteraction('filter', 'order', 'clear');
  }
  function toggleDrawer() {
    setOpenDrawer(!openDrawer);
  }
  function selectYearCycle({ target }) {
    setYear(target.value);
  }
  function selectCycle({ target }) {
    setFilterCycle(target.value);
  }

  return (
    <>
      <FlexBox width="auto" minWidth="80px" m={isMobile ? '0 0px 0 0' : '0 0px'}>
        <Button
          variant="contained"
          onClick={toggleDrawer}
          minWidth="120px"
          flex={1}
        >
          {translate('sales.filter.open_button')}
          <Icon size="small" name="outlined-action-filter" />
        </Button>
      </FlexBox>
      <Drawer onClose={toggleDrawer} anchor="right" open={openDrawer}>
        <FlexBox width="220px" p="10px 0" flexDirection="column">
          <Intro title={translate(('sales.filter.label'))} />
        </FlexBox>
        {/* <FlexBox p="10px" alignItems="center" justifyContent="space-between"> */}
        {/*  {translate('sales.filter.cycle_label')} */}
        {/*  <Switch checked={cicleActive} onClick={handleCycle} /> */}
        {/* </FlexBox> */}
        <FlexBox width="220px" flexDirection="column" p="10px 10px">
          {!cicleActive && (
          <>
            <Typography>{translate('sales.filter.period_label')}</Typography>
            <FlexBox className={classes.datePickerStyle} p="10px 0">
              <DatePicker
                format="dd/MM/yyyy"
                className={initialDate.getTime() < finalDate.getTime() - 2592000000 && classes.periodButton}
                label={translate('customers.filter_bar.from')}
                value={initialDate}
                onChange={(date) => setInitialDate(date)}
                helperText={initialDate.getTime() < finalDate.getTime() - 2592000000
                && translate('customers.filter_bar.from_error')}
              />
            </FlexBox>
            <FlexBox className={classes.datePickerStyle} p="10px 0">
              <DatePicker
                format="dd/MM/yyyy"
                className={initialDate > finalDate && classes.periodButton}
                label={translate('customers.filter_bar.to')}
                value={finalDate}
                onChange={(date) => setFinalDate(date)}
                helperText={initialDate > finalDate && translate('customers.filter_bar.to_error')}
                minDate={initialDate}
              />
            </FlexBox>
          </>
          )}
          {cicleActive && (
            <>
              <Typography m="0 0 10px 0">{translate('sales.filter.cycle_label')}</Typography>
              <Select
                helpText={translate('sales.filter.cycle_label')}
                id="year-select"
                label={translate('sales.filter.year_select')}
                onChange={selectYearCycle}
                options={optionsSelectYear}
                placeholder=" "
              />
              <Select
                id="cycle-select"
                className={classes.selectButton}
                label={translate('sales.filter.cycle_select')}
                onChange={selectCycle}
                options={optionsSelectCycle}
                placeholder=" "
              />
            </>
          )}
          <Button
            variant="contained"
            color="primary"
            onClick={applyFilter}
            className={classes.bottomButton}
            disabled={loading}
          >
            {translate('customers.filter_bar.apply-label')}
          </Button>
        </FlexBox>
        <div className={classes.bottomButtonHolder}>
          <Button
            variant="contained"
            color="primary"
            onClick={clear}
            className={classes.bottomButton}
          >
            {translate('customers.filter_bar.clear-label')}
          </Button>
        </div>
      </Drawer>
    </>
  );
}

export default FilterBar;
