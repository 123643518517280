import React, {
  useContext, useState, useRef, useEffect,
} from 'react';
import Button from '../../button/Button';
import { I18nContext } from '../../../config/i18n';
import { dropdownLanguagesStyle } from './DropdownLanguages.style';
import BrazilianFlag from '../../../assets/brazil_flag.svg';
import MexicoFlag from '../../../assets/mexico_flag.svg';
import UsaFlag from '../../../assets/usa_flag.svg';
import ChileFlag from '../../../assets/chile_flag.svg';
import ArgentinaFlag from '../../../assets/argentina_flag.svg';
import Popover from '../../popover/Popover';
import { pushInteraction } from '../../../data-layer/interaction';

const options = [
  {
    language: 'es_nat_mx',
    svg: MexicoFlag,
    label: 'ES',
    img: '',
  },
  {
    language: 'pt',
    svg: BrazilianFlag,
    label: 'PT',
    img: '',
  },
  {
    language: 'en',
    label: 'EN',
    svg: UsaFlag,
    img: '',
  },
  {
    language: 'es_ar',
    label: 'AR',
    svg: ArgentinaFlag,
    img: '',
  },
  {
    language: 'es_ch',
    label: 'CH',
    svg: ChileFlag,
    img: '',
  },
];

const DropdownLanguages = () => {
  const btnRef = useRef();
  const [isMobile] = useState(window.innerWidth < 1080);
  const classes = dropdownLanguagesStyle();
  const { dispatch, langCode } = useContext(I18nContext);

  const [state, setState] = useState({
    active: false,
    lang: options.find((item) => langCode === item.language)
        || options[0],
  });

  function changeLang(lang) {
    dispatch({ type: 'setLanguage', payload: lang.language });
    setState({ active: !state.active, lang });
    pushInteraction('change language', undefined, lang.label);
  }

  useEffect(() => {
    console.log(langCode);
  }, []);

  function toggleDropdown() {
    setState({ active: !state.active, lang: state.lang });
  }

  return (
    <div style={{ position: 'relative' }}>
      <Button
        style={{
          padding: !isMobile ? '0 10px' : '0px',
          marginRight: !isMobile ? '5px' : '0px',
          minWidth: '40px',
        }}
        btnref={btnRef}
        onClick={toggleDropdown}
      >
        <img
          src={state.lang.svg}
          style={{ width: '24px', height: '17px', marginRight: '10px' }}
          alt="React Logo"
        />
        {!isMobile && state.lang.label}
      </Button>
      {btnRef.current && options && (
        <Popover
          open={state.active}
          style={{ zIndex: '1300' }}
          id="popover"
          anchorEl={btnRef.current}
          maxWidth={0}
          placement="bottom"
          popperOptions={{}}
        >
          <span style={{ display: 'flex', flexDirection: 'column' }}>
            {
              options.map((item) => (
                <Button key={item.label} onClick={() => changeLang(item)}>
                  <img
                    src={item.svg}
                    style={{ width: '24px', height: '17px', marginRight: '10px' }}
                    alt="React Logo"
                  />
                  {item.label}
                </Button>
              ))
            }
          </span>
        </Popover>
      )}
      {state.active && (
        // eslint-disable-next-line jsx-a11y/control-has-associated-label
        <button
          type="button"
          className={classes.hideButton}
          onClick={() => setState({ active: !state.active, lang: state.lang })}
        />
      )}
    </div>
  );
};

export default DropdownLanguages;
