import React, { useContext } from 'react';
import { useStoreState } from 'easy-peasy';
import Card from '../../components/card/Card';
import { I18nContext } from '../../config/i18n';
import DataTable from '../../components/datatable/DataTable';
import { formatDateToExport, sameMonth } from '../../utils/date-utils';
import Icon from '../../components/icon/Icon';
import config from '../../config/config';

function CustomerTable({ loading }) {
  const { translate } = useContext(I18nContext);
  const dateFormat = translate('format.date');
  const { customers, originalData } = useStoreState((state) => state.customers);

  const getDataAddressAndPhoneUser = (row) => {
    let valueReturn = '';
    if (row.state && config.general.gecustomerStateDisplay) {
      valueReturn += `${row.state} `;
    }
    if (row.phoneMobile) {
      valueReturn += `${row.phoneMobile} `;
    }
    if (row.phoneMobile && row.phoneHome) {
      valueReturn += ' / ';
    }
    if (row.phoneHome) {
      valueReturn += row.phoneHome;
    }
    return valueReturn;
  };

  const columns = [
    {
      field: '',
      width: 50,
      headerName: '',
      sortable: false,
      disableColumnMenu: true,
      renderCell: ({ row }) => sameMonth(row.birthDate)
        && <Icon name="outlined-social-birthday" color="primary" size="small" />,
    },
    {
      field: 'fullName',
      headerName: translate('customers.table.header.name'),
      flex: 1,
      minWidth: 200,
      disableColumnMenu: true,
      exportValue: ({ row }) => row.fullName,
      renderCell: ({ row }) => <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>{ row.fullName }</span>,
    },
    {
      field: 'email',
      headerName: translate('customers.table.header.email'),
      flex: 1,
      width: 'min-content',
      minWidth: 300,
      disableColumnMenu: true,
      exportValue: ({ row }) => row.email,
      renderCell: ({ row }) => (
        <span style={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
          { row.email }
        </span>
      ),
    },
    {
      field: 'state',
      headerName: config.general.customerStateDisplay ? translate('customers.table.header.phone')
        : translate('customers.table.header.phone.without.state'),
      flex: 1,
      disableColumnMenu: true,
      minWidth: 250,
      exportValue: ({ row }) => getDataAddressAndPhoneUser(row),
      renderCell: ({ row }) => getDataAddressAndPhoneUser(row),
    },
    {
      field: 'createdDate',
      headerName: translate('customers.table.header.register'),
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,
      exportValue: ({ row }) => formatDateToExport(row.createdDate.split('T')[0], dateFormat),
      renderCell: ({ row }) => formatDateToExport(row.createdDate.split('T')[0], dateFormat),
      type: 'date',
    },
    {
      field: 'birthDate',
      headerName: translate('customers.table.header.birthday'),
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,
      exportValue: ({ row }) => formatDateToExport(row.birthDate.split('T')[0], dateFormat),
      renderCell: ({ row }) => formatDateToExport(row.birthDate.split('T')[0], dateFormat),
    },
  ];
  return (
    <Card p="0" maxWidth={false}>
      <DataTable
        loading={loading}
        rowId="id"
        title={translate('customers.table.title', [originalData.length])}
        dataset={customers}
        columns={columns}
        fileName={translate('export.table.customer')}
        // linkBtn={{ url: '/coupons/new', titleTag: 'customers.table.create_coupon' }}
      />
    </Card>
  );
}

export default CustomerTable;
