import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';

import FlexBox from '../../components/flex-box/FlexBox';
import Page from '../../components/page/Page';
import ErrorScreen from '../../components/error-screen/ErrorScreen';
import ProfileHeader from './ProfileHeader';
import PersonalInfo from './PersonalInfo';
import PersonalAddress from './PersonAddress';
import PersonalContact from './PersonContact';
import DigitalSpace from './DigitalSpace';
import Documents from './Documents';
import Evaluation from './Evaluation';

function Profile() {
  const [isMobile] = useState(window.innerWidth < 1080);
  const {
    error, customerNo,
  } = useStoreState((state) => state.main);
  const { getCnData } = useStoreActions((actions) => actions.main);

  useEffect(() => {
    if (!customerNo) return;
    getCnData({ customerNo });
  }, [customerNo]);

  return (
    <Page>
      {error && <ErrorScreen />}
      {!error && (
      <FlexBox flexDirection="column">
        <ProfileHeader />
        <PersonalInfo />
        <FlexBox flexDirection={isMobile && 'column'}>
          <PersonalAddress />
          <PersonalContact />
        </FlexBox>
        <FlexBox flexDirection={isMobile && 'column'}>
          <Evaluation />
          <Documents />
        </FlexBox>
        <DigitalSpace />
      </FlexBox>
      )}
    </Page>
  );
}

export default Profile;
