import React, { useContext } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { I18nContext } from '../../config/i18n';
import Card from '../../components/card/Card';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import { kpiStyle } from './Kpi.style';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';
import { pushInteraction } from '../../data-layer/interaction';
import { modelCouponFilter } from '../../data-layer/modeling';

function Kpi({
  textKey, values, color, activeKey, activeColor, loading,
}) {
  const { translate } = useContext(I18nContext);
  const { fetchCouponsByStatus } = useStoreActions((actions) => actions.coupons);
  const { activeCoupon } = useStoreState((state) => state.coupons);
  const { customerNo } = useStoreState((state) => state.main);

  function handleClick() {
    fetchCouponsByStatus({ customerNo, status: activeKey });
    pushInteraction('filter', 'coupon status', modelCouponFilter(textKey));
  }

  const { button, card } = kpiStyle({ activeKey, activeColor, activeCoupon });

  const kpiDetail = {
    position: 'absolute',
    top: 0,
    right: 0,
    padding: '3px',
    backgroundColor: color,
    width: '100%',
  };

  return (
    <>
      <button onClick={handleClick} type="button" className={button}>
        <Card height="100%" m="0" p="0" overflow="hidden" position="relative">
          <FlexBox p="10px" height="100%" className={card}>
            <div style={kpiDetail} />
            <FlexBox alignItems="center" justifyContent="center" flexDirection="column">
              <Typography variant="h6" p="0 0 10px 0">{translate(textKey)}</Typography>
              <FlexBox alignItems="center" justifyContent="center">
                {!loading && values && values.map((value, i) => (
                  <Typography key={i} variant="h5" p="0 5px">{value}</Typography>
                ))}
                {loading && <ProgressIndicator size={24} />}
              </FlexBox>
            </FlexBox>
          </FlexBox>
        </Card>
      </button>
    </>
  );
}

export default Kpi;
