import React from 'react';
import StyledTabContainer from './TabContainer.styles';

const TabContainer = ({ children, ...props }) => (
  <StyledTabContainer
    className={props.className}
    alt={props.alt}
    fallback={props.alt}
    src={props.alt}
    {...props}
  >
    {children}
  </StyledTabContainer>
);

export default TabContainer;
