import React, { useRef, useState } from 'react';
import { infoPopoverStyle } from './InfoPopover.style';
import Icon from '../icon/Icon';
import Popover from '../popover/Popover';

const InfoPopover = ({ text, iconName, placement }) => {
  const popverRef = useRef();
  const [popoverControl, setPopoverControl] = useState();
  const { infoButton, closePopover, popover } = infoPopoverStyle();
  function handleClick() {
    setPopoverControl(!popoverControl);
  }
  return (
    <>
      <button
        ref={popverRef}
        type="button"
        className={infoButton}
        onClick={handleClick}
      >
        <Icon size="micro" name={iconName || 'outlined-alert-info'} />
      </button>
      <Popover
        anchorEl={popverRef.current}
        id="popover4"
        className={popover}
        placement={placement || 'top'}
        open={popoverControl}
      >
        {text}
      </Popover>
      {popoverControl && (
        <button onClick={handleClick} type="button" className={closePopover} />
      )}
    </>
  );
};

export default InfoPopover;
