import React from 'react';
import { useLocation } from 'react-router-dom';
import Page from '../../components/page/Page';

function NotFound() {
  const location = useLocation();

  return (
    <Page>
      <h3>
        <code>{location.pathname}</code>
        <br />
        404 - NOT FOUND !!!!
      </h3>
    </Page>
  );
}

export default NotFound;
