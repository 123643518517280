import React, { useContext, useState } from 'react';
import { makeStyles, TextField } from '@naturacosmeticos/natds-web';
import { useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from 'easy-peasy';

import FlexBox from '../../components/flex-box/FlexBox';
import { I18nContext } from '../../config/i18n';
import ActiveFilters from './ActiveFilters';
import Button from '../../components/button/Button';
import { pushInteraction } from '../../data-layer/interaction';
import TooltipComponent from '../../components/tooltip';

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiFormControl-root': {
      flex: '1',
      width: '100%',
    },
    marginTop: window.innerWidth < 1080 && '10px',
  },
}));
const Search = () => {
  const { translate } = useContext(I18nContext);
  const { filter } = useStoreState((state) => state.customers);
  const { textField } = useStyles();
  const [isMobile] = useState(window.innerWidth < 1080);
  const { register, handleSubmit } = useForm();
  const { filterSales, setFilter } = useStoreActions((actions) => actions.sales);

  function handleFilter({ searchValue }) {
    setFilter({ ...filter, search: searchValue });
    filterSales();
    pushInteraction('search', 'customer/order', undefined);
  }

  return (
    <>
      <FlexBox flexDirection="column">
        <FlexBox flexDirection={isMobile && 'column'} alignItems={isMobile ? 'flex-end' : 'center'}>
          <form style={{ width: '100%' }} onSubmit={handleSubmit(handleFilter)}>
            <FlexBox className={textField} alignItems="center" maxWidth="420px">
              <TooltipComponent title={translate('sales.table.search-tooltip')}>
                <TextField
                  {...register('searchValue')}
                  style={{
                    marginRight: '15px',
                    flex: '1',
                    maxWidth: '300px',
                  }}
                  placeholder={translate('sales.table.search')}
                  rows={1}
                  size="semiX"
                />
              </TooltipComponent>
              <Button
                variant="contained"
                style={{ margin: isMobile ? '0 0 0 15px' : '0 0 0 10px' }}
                color="primary"
                onClick={handleSubmit(handleFilter)}
              >
                {translate('search')}
              </Button>
            </FlexBox>
          </form>
        </FlexBox>
        <ActiveFilters />
      </FlexBox>
    </>
  );
};

export default Search;
