import { BrowserRouter as Router } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Navigation from './components/navigation/Navigation';
import Header from './components/appbar/Header';
import AppRoutes from './components/router/AppRoutes';
import useHandleAuth from './hooks/useHandleAuth';
import AlertComponent from './components/alert';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import ReactivationModal from './components/reactivation-modal/ReactivationModal';
import config from './config/config';

function App() {
  const [menuActive, setMenuActive] = useState(window.innerWidth > 1080);
  const [isMobile] = useState(window.innerWidth < 1080);
  const isLogged = useHandleAuth();
  const hasMarginLeft = isLogged && menuActive && !isMobile;
  const { getCnData, handleChangeStatusShop, setOpenReactivationModal } = useStoreActions((actions) => actions.main);
  const {
    customerNo, consultantUserId, personIdCn, openReactivationModal, reactivationModelMessage, personEmail,
  } = useStoreState((state) => state.main);

  useEffect(() => {
    if (!customerNo) return;
    getCnData({ customerNo });
  }, [customerNo]);
  useEffect(() => {
    async function reactiveToken() {
      try {
        if (config.general.reactivationMode === 'mercadoPago') {
          const currentURL = window.location.href.includes('login');
          const token = localStorage.getItem('token-reactivation');
          if (!currentURL && token && personIdCn) {
            const newStatus = 1;
            const consultantInfo = {
              personId: personIdCn,
              storeStatus: newStatus,
              consultantRefreshToken: token.split('code=').at(-1),
              personEmail,
            };
            await handleChangeStatusShop(consultantInfo);
            setOpenReactivationModal({ status: true, message: 'success' });
            localStorage.removeItem('token-reactivation');
            window.history.pushState('', '', '/');
          }
        }
      } catch (e) {
        setOpenReactivationModal({ status: true, message: 'error' });
        localStorage.removeItem('token-reactivation');
      }
    }
    reactiveToken();
  }, [consultantUserId, personIdCn, window.location.href]);

  return (
    <Router>
      <>
        <>
          <ReactivationModal
            openModal={openReactivationModal}
            toClose={() => setOpenReactivationModal(false)}
            message={reactivationModelMessage}
          />
        </>
        <AlertComponent />
        <Header menuActive={menuActive} setMenuActive={setMenuActive} hasUser={isLogged} />
        <Navigation isMobile={isMobile} setMenuActive={setMenuActive} menuActive={menuActive && isLogged} />
        <div style={{ marginTop: '56px' }}>
          <div style={{
            transition: 'width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms  ',
            marginLeft: hasMarginLeft ? '240px' : '0',
          }}
          >
            <AppRoutes />
          </div>
        </div>
      </>
    </Router>
  );
}

export default App;
