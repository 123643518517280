import { makeStyles } from '@naturacosmeticos/natds-web';

export const NewCouponCardStyle = makeStyles((theme) => ({
  form: {
    '& .MuiFormControl-root': {
      margin: '0px',
    },
    '& .MuiInput-formControl': {
      marginTop: '7px',
    },
    '& .MuiFormLabel-root': {
      fontWeight: '500',
    },
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  calendar: {
    margin: '15px 8px',
    maxWidth: window.innerWidth > 1080 && '190px',
    '& input': {
      minHeight: '40px',
    },
  },
  calendarAlert: {
    margin: '15px 8px',
    maxWidth: window.innerWidth > 1080 && '190px',
    '& .MuiFormHelperText-root': {
      color: theme.color.alert,
    },
  },
  textField: {
    margin: '15px 8px',
  },
  textFieldAlert: {
    margin: '15px 8px',
    '& .MuiFormHelperText-root': {
      color: theme.color.alert,
    },
  },
  percentDetail: {
    margin: '0 0 0 20px',
    display: 'flex',
    alignItems: 'center',
    width: '30px',
  },
  slider: {
    margin: '0 0 0 5px',
  },
}));
