import FileType from 'file-type/browser';
import imageCompression from 'browser-image-compression';

import config from '../config/config';

const acceptFileType = ['image/jpeg', 'image/jpg', 'jpeg', 'jpg'];
const maxFileSize = config.general.maxFileSize || 1000000;
const maxWidthOrHeightToCompressImage = config.general.maxWidthOrHeightToCompressImage || 1920;

const compressImage = async (imageFile) => {
  const options = {
    maxSizeMB: 1,
    maxWidthOrHeight: maxWidthOrHeightToCompressImage,
    useWebWorker: true,
  };
  return imageCompression(imageFile, options)
    .then((compressedFile) => compressedFile)
    .catch((error) => {
      console.log(error.message);
    });
};

const convertBase64 = (file) => new Promise((resolve, reject) => {
  const fileReader = new FileReader();
  fileReader.readAsDataURL(file);
  fileReader.onload = () => {
    resolve(fileReader.result.split('data:image/jpeg;base64,/9j/')[1]);
  };

  fileReader.onerror = (error) => {
    reject(error);
  };
});

async function handleChangeFile(event, callbackSuccessful, callbackError) {
  const executeErrorCallback = (typeError = true) => {
    if (callbackError && typeof callbackError === 'function') {
      callbackError(typeError);
    }
  };

  if (!event.target.files[0]) return;
  const file = event.target.files[0];

  if (file && acceptFileType.some((element) => element === file.type)) {
    try {
      if (file.size > maxFileSize) {
        executeErrorCallback(false);
        return;
      }
      const mimeTypeFyle = await FileType.fromBlob(file);

      if (mimeTypeFyle && acceptFileType.some((element) => element === mimeTypeFyle.ext)) {
        if (callbackSuccessful && typeof callbackSuccessful === 'function') {
          const compressedFile = await compressImage(file);
          console.log(`originalFile size ${file.size / 1024 / 1024} MB`);
          console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`);

          const base64 = await convertBase64(file.size < compressedFile.size ? file : compressedFile);
          callbackSuccessful(base64);
          return;
        }
      } else {
        executeErrorCallback();
        return;
      }
    } catch (error) {
      console.log('error', error);
      throw new Error();
    }
  } else {
    executeErrorCallback();
  }
}

export { convertBase64, handleChangeFile };
