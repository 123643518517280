import React from 'react';
import { useTheme } from '@material-ui/styles';

import FlexBox from '../../flex-box/FlexBox';
import Icon from '../../icon/Icon';
import LinkComponent from '../../link-component/LinkComponent';
import Typography from '../../typography/Typography';

const ContentAlert = ({ dataAlert }) => {
  const theme = useTheme();

  function openLink(e) {
    if (e.link) {
      window.open(e.link, '_blank');
    }
  }

  const showItem = (e) => {
    if (e.link) {
      return (
        <LinkComponent
          style={{ cursor: 'pointer' }}
          color="default"
          underline="none"
          href="#"
          variant="body1"
          onClick={() => openLink(e)}
        >
          {e.descriptionText}
        </LinkComponent>
      );
    } return (
      <Typography>{e.descriptionText}</Typography>
    );
  };

  return (
    <>
      <FlexBox
        m="10px"
        flexDirection="column"
        justifyContent="space-between"
        maxWidth="-webkit-fill-available"
        alignItems="center"
      >
        {dataAlert.map((e) => (
          <FlexBox
            flexDirection="row"
            borderLeft={`3px solid ${theme.color.lowEmphasis}`}
            borderRight={`1px solid ${theme.color.lowEmphasis}`}
            borderBottom={`1px solid ${theme.color.lowEmphasis}`}
            borderTop={`1px solid ${theme.color.lowEmphasis}`}
            margin="10px"
            alignItems="center"
            borderRadius="5px"
          >
            <FlexBox
              padding="0px 16px 0px 16px"
              margin="10px"
              alignItems="center"
              justifyContent="center"
              width="50px"
              height="50px"
              borderRadius="50%"
              border={`1px solid ${theme.color.lowEmphasis}`}
            >
              <Icon
                size="small"
                name={e.titleText === 'announcements'
                  ? 'outlined-communication-speaker' : 'outlined-alert-notification'}
              />
            </FlexBox>
            {showItem(e)}
          </FlexBox>
        ))}
      </FlexBox>

    </>
  );
};

export default ContentAlert;
