import { pushDataLayerError } from './reportError';

export const getPageName = (pathname) => {
  try {
    return pathname.substring(1);
  } catch (e) {
    pushDataLayerError('getPageName', e);
  }
};

export const modelCouponFilter = (couponTextKey) => {
  switch (couponTextKey) {
    case 'coupons.kpis.active_coupons':
      return 'active coupons';
    case 'coupons.kpis.natura_coupons':
      return 'natura coupons';
    case 'coupons.kpis.inactive_coupons':
      return 'inactive coupons';
    case 'coupons.kpis.used_coupons':
      return 'used coupons';
    default:
      return undefined;
  }
};

export const modelDateInterval = (initialDate, finalDate) => {
  try {
    return `${initialDate.toDateString()} - ${finalDate.toDateString()}`;
  } catch (e) {
    pushDataLayerError('modelDateInterval', e);
  }
};
