import { action, thunk } from 'easy-peasy';
import fetcher from '../../api/fetcher';

const initialState = {
  abandonedOrders: [],
  originalData: {},
  loading: true,
  openModal: false,
  error: false,
  individualAbandonedOrder: null,
  search: null,
};

const abandonedOrdersModel = {
  ...initialState,
  resetStoreAbandonedOrders: action((state) => {
    Object.assign(state, initialState);
  }),
  addAbandonedOrders: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.abandonedOrders = payload;
    // eslint-disable-next-line no-param-reassign
    state.loading = false;
  }),
  setSearch: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.search = payload;
  }),
  addOriginalData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.originalData = payload;
  }),
  addIndividualAbanonedOrder: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.individualAbandonedOrder = payload;
  }),
  setLoading: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.error = payload;
  }),
  // eslint-disable-next-line no-unused-vars
  fetchAbandonedOrders: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError(false);
    const { indentifier } = payload;
    fetcher().get(`abandoned-bag?personId=${indentifier}`).json()
      .then((data) => {
        actions.addOriginalData(data);
        actions.setLoading(false);
        actions.addAbandonedOrders(data.baskets);
      })
      .catch((err) => {
        actions.setError(true);
        console.error('error: -> ', err.message);
      });
  }),
  fetchAbandonedBag: thunk(async (actions, payload) => {
    const { indentifier, orderId } = payload;
    fetcher().get(`abandoned-bag/${orderId}?personId=${indentifier}`).json()
      .then((data) => {
        const elementFind = data && data.baskets ? data.baskets.find((e) => e.UUID === orderId) : null;
        actions.addIndividualAbanonedOrder(elementFind || null);
      })
      .catch((err) => {
        actions.setError(true);
        console.error('error: -> ', err.message);
      });
  }),
  filterAbandonedOrders: action((state) => {
    const { search, originalData } = state;
    // eslint-disable-next-line no-param-reassign
    state.abandonedOrders = originalData.baskets;
    if (search) {
      // eslint-disable-next-line no-param-reassign
      state.abandonedOrders = originalData.baskets.filter((item) => (
        item.customerName.toUpperCase().indexOf(search.toUpperCase()) > -1
        || item.customerEmail.toUpperCase().indexOf(search.toUpperCase()) > -1
      ));
    }
  }),
};
export default abandonedOrdersModel;
