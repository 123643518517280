import React from 'react';
import StyledDialog from './Dialog.styles';

const Dialog = ({ children, ...props }) => (
  <StyledDialog
    {...props}
  >
    {children}
  </StyledDialog>
);

export default Dialog;
