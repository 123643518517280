import React from 'react';
import StyledProgressIndicator from './ProgressIndicator.styles';

const ProgressIndicator = ({ children, ...props }) => (
  <StyledProgressIndicator
    className={props.className}
    size={props.size}
    props={props}
  >
    {children}
  </StyledProgressIndicator>
);

export default ProgressIndicator;
