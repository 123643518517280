import { makeStyles } from '@naturacosmeticos/natds-web';

export const kpiStyle = makeStyles({
  button: {
    backgroundColor: 'transparent',
    border: 'none',
    flex: 1,
    display: 'flex',
    padding: '0',
    cursor: 'pointer',
    borderRadius: '4px',
    margin: '10px',
  },
  card: {
    backgroundColor: (props) => props.activeKey === props.activeCoupon && props.activeColor,
  },
});
