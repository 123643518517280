import { makeStyles } from '@naturacosmeticos/natds-web';

export const LoginStyle = makeStyles(() => ({
  container: {
    maxWidth: '550px',
    margin: '0 auto',
    paddingTop: '40px',
  },
  loginButton: {
    width: '100%',
  },
  inputPassword: {
    '& > div': {
      '& > input::-ms-reveal, input::-ms-clear': {
        display: 'none',
      },
    },
  },
}));
