import { makeStyles } from '@naturacosmeticos/natds-web';

export const ForgotPasswordStyle = makeStyles((theme) => ({
  container: {
    maxWidth: '500px',
    margin: '0 auto',
    paddingTop: '40px',
  },
  button: {
    backgroundColor: theme.color.primary,
    width: '100%',
    marginTop: '20px',
  },
}));
