import React, { useContext, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { useTheme } from '@material-ui/styles';
import { Avatar } from '@naturacosmeticos/natds-web/dist/Components/Avatar';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import NaturaFlower from '../../assets/natura_flower.png';

function ProfileHeader() {
  const { translate } = useContext(I18nContext);
  const theme = useTheme();
  const { personFullName, personEmail, imageCN } = useStoreState((state) => state.main);
  const [isMobile] = useState(window.innerWidth < 1080);

  return (
    <FlexBox
      borderRadius="3px"
      backgroundImage={NaturaFlower}
      flexDirection="column"
      m="10px"
      mw="calc( 100% - 20px )"
      height="300px"
      p={isMobile ? '0px' : '20px'}
      justifyContent="end"
    >
      <FlexBox
        mw={isMobile ? '480px' : 'max-content'}
        flexDirection={isMobile && 'column'}
        height={isMobile && '255px'}
        justifyContent={isMobile && 'space-around'}
      >
        <Avatar
          style={{ alignSelf: isMobile ? 'center' : '' }}
          alt="Nat Natura holding a avatar"
          color="primary"
          size="huge"
          src={imageCN}
        />
        <FlexBox
          m={isMobile ? '' : '0 0 0 20px'}
          flexDirection="column"
        >
          <Typography c={theme.color.onLink} variant="h4" textAlign={isMobile && 'center'}>
            {personFullName}
          </Typography>
          <FlexBox justifyContent={isMobile && 'center'}>
            <Typography c={theme.color.onLink} whiteSpace="nowrap">
              {translate('profile.email')}
            </Typography>
            <Typography c={theme.color.onLink} wordBreak="break-all" m="0 0 0 5px">
              {personEmail}
            </Typography>
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
}

export default ProfileHeader;
