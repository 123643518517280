import React, { useContext, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { makeStyles } from '@naturacosmeticos/natds-web';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import { formatDateTimeIso } from '../../utils/date-utils';
import Icon from '../../components/icon/Icon';

const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

const useStyles = makeStyles((theme) => ({
  currentFilter: {
    backgroundColor: theme.color.lowEmphasis,
    borderRadius: '100px',
    padding: '5px 7px',
    width: 'auto',
    alignItems: 'center',
  },
  closeBtn: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '0',
    cursor: 'pointer',
    color: theme.color.highEmphasis,
    display: 'flex',
    alignItems: 'center',

  },
}));

const ActiveFilters = () => {
  const { filter, originalData, sales } = useStoreState((state) => state.sales);
  const { personId } = useStoreState((state) => state.main);
  const { translate } = useContext(I18nContext);
  const dateFormat = translate('format.date');
  const [isMobile] = useState(window.innerWidth < 1080);
  const { fetchSales, setFilter } = useStoreActions((actions) => actions.sales);
  const { currentFilter, closeBtn } = useStyles();
  function clear() {
    localStorage.removeItem('initialDate');
    localStorage.removeItem('finalDate');
    setFilter({ ...filter, period: null, cycle: null });
    fetchSales({
      initialDate: formatDateTimeIso(today.getTime() - (86400000 * 30), dateFormat),
      finalDate: formatDateTimeIso(today, dateFormat),
      personId,
    });
  }
  useEffect(() => {
    setFilter({ ...filter, period: null, cycle: null });
  }, []);
  return (
    <>
      {(filter.cycle || filter.period) && (
      <FlexBox flexWrap="wrap" m="10px 0" alignItems="center">
        <Typography whiteSpace="nowrap" m="0 15px" color="textSecondary">
          {translate('sales.active_filters.label')}
          {' '}
          {sales && sales.length}
          {' '}
          {translate('sales.active_filters.label_abbreviation')}
          {' '}
          {originalData && originalData.orders && originalData.orders.length}
        </Typography>
          {filter.period && (
          <FlexBox m={isMobile && '5px 0'} alignItems="center" className={currentFilter}>
            <button className={closeBtn} type="button" onClick={clear}>
              <Icon size="tiny" name="outlined-navigation-close" />
            </button>
            {formatDateTimeIso(filter.period.initialDate.getTime(), dateFormat)}
            {' - '}
            {formatDateTimeIso(filter.period.finalDate.getTime(), dateFormat)}
          </FlexBox>
          )}
      </FlexBox>
      )}
    </>
  );
};

export default ActiveFilters;
