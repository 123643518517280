import React from 'react';
import StyledButton from './Button.styles';

const Button = ({ children, ...props }) => (
  <StyledButton
    ref={props.btnref}
    {...props}
  >
    {children}
  </StyledButton>
);

export default Button;
