import React, { useContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import {
  DataGrid, ptBR, esES, enUS,
} from '@mui/x-data-grid';
import { useHistory } from 'react-router-dom';
import Button from '../button/Button';
import FlexBox from '../flex-box/FlexBox';
import { exportData } from '../../utils/export-utils';
import { I18nContext } from '../../config/i18n';
import { dataTableStyle } from './DataTable.style';
import Typography from '../typography/Typography';
import sadIcon from '../../assets/Illustration - Lined - Sad.svg';
import Intro from '../intro/Intro';
import { pushInteraction } from '../../data-layer/interaction';
import { getPageName } from '../../data-layer/modeling';

function DataTable(props) {
  const {
    button,
    dataset,
    columns,
    loading,
    initialState,
    pageSize,
    showSelection,
    reloadFunction,
    fileName,
    title,
    titleDetail,
    linkBtn,
    rowId,
    tableMargin,
    showExtraButtons,
  } = props;
  const history = useHistory();
  const { translate, langCode } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const [localeText, setLocaleText] = useState();

  useEffect(() => {
    if (langCode === 'es' || langCode === 'es_ar' || langCode === 'es_ch') {
      setLocaleText(esES.components.MuiDataGrid.defaultProps.localeText);
    } if (langCode === 'pt') {
      setLocaleText(ptBR.components.MuiDataGrid.defaultProps.localeText);
    } if (langCode === 'en') {
      setLocaleText(enUS.components.MuiDataGrid.defaultProps.localeText);
    }
  }, [langCode]);

  const location = useLocation();

  const { table } = dataTableStyle({ tableMargin });

  function handleExport() {
    pushInteraction('click', `${getPageName(location.pathname)} page`, 'export excel');
    exportData(dataset, fileName, columns);
  }

  function handleClick() {
    pushInteraction('navigation', `${getPageName(location.pathname)} page`, 'create coupon');
    history.push(linkBtn.url);
  }
  return (
    <>
      <FlexBox alignItems="center" flexDirection={isMobile && 'column'} justifyContent="space-between" p="16px 0 5px 0">
        {title && (
        <FlexBox>
          <Intro
            title={title}
            detail={titleDetail}
          />
        </FlexBox>
        )}
        <FlexBox p="5px" justifyContent="flex-end" alignItems="center" fontSize="12px" gap="10px" flexWrap="wrap">
          {linkBtn && (
            <Button
              variant="contained"
              onClick={handleClick}
              minWidth="120px"
              flex={1}
            >
              {translate(linkBtn.titleTag)}
            </Button>

          )}
          {button}
          {fileName && (
            <Button
              variant="contained"
              onClick={handleExport}
              minWidth="120px"
              flex={1}
            >
              {translate('default_export_excel_button')}
            </Button>
          )}
          {showExtraButtons}
        </FlexBox>
      </FlexBox>
      <DataGrid
        className={table}
        initialState={initialState}
        getRowId={(row) => row[rowId]}
        rows={dataset}
        columns={columns}
        pageSize={pageSize || 5}
        loading={loading}
        checkboxSelection={showSelection}
        disableSelectionOnClick={!showSelection}
        autoHeight
        density="comfortable"
        localeText={localeText}
        components={{
          NoRowsOverlay: () => (
            <FlexBox position="absolute" alignItems="center" justifyContent="center" height="130px">
              <Typography>
                {translate('no-data-table')}
              </Typography>
            </FlexBox>
          ),
          ErrorOverlay: () => (
            <FlexBox p="0 0 50px 0" flexDirection="column" alignItems="center" justifyContent="center">
              <img src={sadIcon} alt="Sad face emote" />
              <Typography m="10px">
                {translate('error-data-table')}
              </Typography>
              {reloadFunction && (
              <FlexBox justifyContent="center" m="5px">
                <Button
                  color="primary"
                  variant="contained"
                  onClick={reloadFunction}
                >
                  {translate('Tentar novamente')}
                </Button>
              </FlexBox>
              )}
            </FlexBox>
          ),
        }}
      />
    </>
  );
}

export default DataTable;
