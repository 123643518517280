/* eslint-disable max-len */
import React, { useContext, useEffect, useState } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import { IconButton, makeStyles } from '@naturacosmeticos/natds-web';
import { useHistory } from 'react-router-dom';

import FlexBox from '../../components/flex-box/FlexBox';
import Page from '../../components/page/Page';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import Card from '../../components/card/Card';
import TableComponent from '../../components/table/Table';
import TooltipComponent from '../../components/tooltip';
import Icon from '../../components/icon/Icon';
import { formatDateToExport, formatDateTimeIso } from '../../utils/date-utils';
import { formatCurrency } from '../../utils/numbers-utils';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';

const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

const EarningsCalendarDetailsComponent = ({ rowSelected }) => {
  const { translate } = useContext(I18nContext);
  const dateFormat = translate('format.date');

  const getTypeCard = (value) => {
    if (value === 'credit_card') {
      return translate('earnings_calendar.table.detalhes-type-of-card_credit');
    } if (value === 'debit_card') {
      return translate('earnings_calendar.table.detalhes-type-of-card_debit');
    } if (value === 'reversal') {
      return translate('earnings_calendar.table.detalhes-type-of-reversal');
    }
    return '';
  };

  // eslint-disable-next-line max-len
  const getValueEarnings = (row) => {
    if (row && row.charge && row.charge.settlement) {
      return row.charge.settlement;
    }
    return 0;
  };

  const columns = [
    {
      field: 'datePayment',
      headerName: translate('earnings_calendar.table.detalhes-date'),
      minWidth: 80,
      sortable: false,
      exportValue: ({ row }) => formatDateToExport(row.charge.settlement?.scheduleDate, dateFormat),
      renderCell: ({ row }) => formatDateToExport(row.charge.settlement?.scheduleDate, dateFormat),
    },
    {
      field: 'charge.order.identifier',
      headerName: translate('earnings_calendar.table.detalhes-order-number'),
      minWidth: 80,
      sortable: false,
      exportValue: ({ row }) => row.charge.order.identifier,
      renderCell: ({ row }) => row.charge.order.identifier,
    },
    {
      field: 'orderValue',
      headerName: translate('earnings_calendar.table.detalhes-order-value'),
      sortable: false,
      exportValue: ({ row }) => formatCurrency(row.charge?.amount.value, 100),
      renderCell: ({ row }) => formatCurrency(row.charge?.amount.value, 100),
    },
    {
      field: 'card',
      headerName: translate('earnings_calendar.table.detalhes-profit-without-shipping'),
      minWidth: 100,
      flex: 1,
      sortable: false,
      exportValue: ({ row }) => formatCurrency((getValueEarnings(row)?.grossAmount?.value || 0), 100),
      renderCell: ({ row }) => formatCurrency((getValueEarnings(row)?.grossAmount?.value || 0), 100),
    },
    {
      field: 'cardRetention',
      headerName: translate('earnings_calendar.table.detalhes-retention'),
      minWidth: 100,
      flex: 1,
      sortable: false,
      exportValue: ({ row }) => formatCurrency((getValueEarnings(row)?.mdrAmount?.value + getValueEarnings(row)?.originalTransaction?.taxAmount?.value || 0), 100),
      renderCell: ({ row }) => formatCurrency((getValueEarnings(row)?.mdrAmount?.value + getValueEarnings(row)?.originalTransaction?.taxAmount?.value || 0), 100),
    },
    {
      field: 'cardGain',
      headerName: translate('earnings_calendar.table.detalhes-net-income'),
      minWidth: 50,
      flex: 1,
      sortable: false,
      exportValue: ({ row }) => formatCurrency((getValueEarnings(row)?.amount?.value || 0), 100),
      renderCell: ({ row }) => formatCurrency((getValueEarnings(row)?.amount?.value || 0), 100),
    },
    {
      field: 'cardType',
      headerName: translate('earnings_calendar.table.detalhes-card'),
      minWidth: 50,
      sortable: false,
      exportValue: ({ row }) => row.charge.brand,
      renderCell: ({ row }) => row.charge.brand,
    },
    {
      field: 'cardType',
      headerName: translate('earnings_calendar.table.detalhes-type-of-card'),
      minWidth: 50,
      sortable: false,
      exportValue: ({ row }) => getTypeCard(row.method),
      renderCell: ({ row }) => getTypeCard(row.method),
    },
  ];

  return (
    <TableComponent
      tableDetails
      loading={false}
      dataset={rowSelected.details}
      columns={columns}
      dataTableConfig={{
        showPagination: false,
        showMoreDetails: false,
      }}
    />
  );
};

const useStyles = makeStyles((theme) => ({
  currentFilter: {
    backgroundColor: theme.color.lowEmphasis,
    borderRadius: '100px',
    padding: '5px 7px',
    width: 'max-content',
    alignItems: 'center',
  },
}));

const EarningsCalendarComponent = () => {
  const {
    loadingEarningsCalendar = false, earningsCalendarData, sales = [], filter,
  } = useStoreState((state) => state.sales);
  const { translate } = useContext(I18nContext);
  const dateFormat = translate('format.date');
  const { fetchEarningsCalendar } = useStoreActions((actions) => actions.sales);
  const [dataTable, setDataTable] = useState([]);
  const [dataSetDetails, setDataSetDetails] = useState([]);
  const [dataHeader, setDataHeader] = useState();
  const [isMobile] = useState(window.innerWidth < 1080);
  const history = useHistory();
  const { currentFilter } = useStyles();

  useEffect(() => {
    if (sales && sales.length > 0) {
      const sizeOrders = [];
      sales.forEach((item) => {
        // eslint-disable-next-line max-len
        const hasPaidStatus = Array.isArray(item.statusHistory) && item.statusHistory.some((status) => status.includes('91 Paid'));
        let isApproved = true;
        // eslint-disable-next-line no-unused-vars
        if (item.statusOrder === '8 - FAILED' || item.statusOrder === '6 - CANCELLED') { isApproved = false; }
        if (isApproved && hasPaidStatus) sizeOrders.push(item);
        else if (isApproved && !hasPaidStatus) sizeOrders.push(item);
      });
      const ordersIds = sizeOrders.map((e) => e.orderId);
      const listOrdersString = ordersIds.map((orders) => `${orders}REDEARG`).join(',');
      fetchEarningsCalendar({ orders: listOrdersString });
    } else {
      history.push('/sales');
    }
  }, []);

  const mountDate = (response = []) => {
    if (response) {
      const dataGroup = [];
      const dataRows = [];
      const ordersIds = [];
      response.forEach((element) => {
        if (element && element.charge && element.charge.order && element.charge.order.identifier) {
          const createElement = {
            amount: element.amount,
            charge: element.charge,
            feeAmount: element.feeAmount,
            id: element.id,
            method: element.method,
            status: element.status,
          };
          if (createElement.charge && createElement.charge.settlement) {
            // eslint-disable-next-line max-len
            const getValueEarnings = createElement.charge.settlement.filter((e) => e.receiver.customerId.toString() !== '30677757295' && e.scheduleDate && e.amount && e.amount.value);
            getValueEarnings.forEach((valueEarning) => {
              const indexOfElement = dataGroup.indexOf(dataGroup.find((elementFind) => {
                if (valueEarning) {
                  if (elementFind.datePayment === valueEarning?.scheduleDate) {
                    return true;
                  }
                }
                return false;
              }));
              const method = valueEarning.amount.value < 0 ? 'reversal' : createElement.method;
              const newElement = {
                ...createElement,
                method,
                charge: {
                  ...createElement.charge,
                  settlement: valueEarning,
                },
              };
              dataRows.push(newElement);
              // eslint-disable-next-line max-len
              if (indexOfElement !== -1 && valueEarning && valueEarning.scheduleDate && valueEarning.amount && valueEarning.amount.value) {
                const listDetails = [newElement, ...dataGroup[indexOfElement].details];
                dataGroup[indexOfElement].netValue += valueEarning.amount.value;
                dataGroup[indexOfElement].amountValue += ordersIds.includes(newElement.charge.order.identifier) ? 0 : 1;
                dataGroup[indexOfElement].details = listDetails;
                dataGroup[indexOfElement].valueOfProducts += ordersIds.includes(newElement.charge.order.identifier) ? 0 : newElement.charge.amount.value;
                ordersIds.push(newElement.charge.order.identifier);
              } else {
                dataGroup.push({
                  netValue: valueEarning?.amount.value,
                  amountValue: 1,
                  details: [newElement],
                  valueOfProducts: newElement.charge?.amount.value,
                  datePayment: valueEarning?.scheduleDate,
                });
                ordersIds.push(newElement.charge.order.identifier);
              }
            });
          }
        }
      });

      const amount = dataGroup.length > 0 ? dataGroup.map((e) => e.amountValue).reduce((soma, i) => (i ? soma + i : soma)) : 0;
      const netValue = dataGroup.length > 0 ? dataGroup.map((e) => e.netValue).reduce((soma, i) => (i ? soma + i : soma)) : 0;
      // eslint-disable-next-line max-len
      const valueOfProducts = dataGroup.length > 0 ? dataGroup.map((e) => e.valueOfProducts).reduce((soma, i) => (soma + i)) : 0;

      setDataTable(dataGroup);
      setDataSetDetails(dataRows);
      setDataHeader({
        amount: amount || 0,
        netValue: netValue || 0,
        valueOfProducts: valueOfProducts || 0,
      });
    }
  };

  useEffect(() => {
    if (earningsCalendarData) {
      mountDate(earningsCalendarData);
    }
  }, [earningsCalendarData]);

  const getTypeCard = (value) => {
    if (value === 'credit_card') {
      return translate('earnings_calendar.table.detalhes-type-of-card_credit');
    } if (value === 'debit_card') {
      return translate('earnings_calendar.table.detalhes-type-of-card_debit');
    } if (value === 'reversal') {
      return translate('earnings_calendar.table.detalhes-type-of-reversal');
    }
    return '';
  };

  // eslint-disable-next-line max-len
  const getValueEarnings = (row) => {
    if (row && row.charge && row.charge.settlement) {
      return row.charge.settlement;
    }
    return 0;
  };

  const columns = [
    {
      field: 'datePayment',
      headerName: translate('earnings_calendar.table.date'),
      minWidth: 100,
      flex: 1,
      sortable: true,
      exportValue: ({ row }) => formatDateToExport(row.datePayment, dateFormat),
      renderCell: ({ row }) => formatDateToExport(row.datePayment, dateFormat),
    },
    {
      field: 'amountValue',
      headerName: translate('earnings_calendar.table.amount_of_sales'),
      minWidth: 50,
      sortable: true,
      exportValue: ({ row }) => row.amountValue,
      renderCell: ({ row }) => row.amountValue,
    },
    {
      field: 'netValue',
      headerName: translate('earnings_calendar.table.earnings'),
      minWidth: 80,
      disableColumnMenu: true,
      sortable: true,
      exportValue: ({ row }) => formatCurrency(row.netValue, 100),
      renderCell: ({ row }) => formatCurrency(row.netValue, 100),
    },
  ];

  const columnsDetail = [
    {
      field: 'datePayment',
      headerName: translate('earnings_calendar.table.detalhes-date'),
      minWidth: 80,
      sortable: false,
      exportValue: ({ row }) => formatDateToExport(row.charge.settlement?.scheduleDate, dateFormat),
      renderCell: ({ row }) => formatDateToExport(row.charge.settlement?.scheduleDate, dateFormat),
    },
    {
      field: 'charge.order.identifier',
      headerName: translate('earnings_calendar.table.detalhes-order-number'),
      minWidth: 80,
      sortable: false,
      exportValue: ({ row }) => row.charge.order.identifier,
      renderCell: ({ row }) => row.charge.order.identifier,
    },
    {
      field: 'orderValue',
      headerName: translate('earnings_calendar.table.detalhes-order-value'),
      sortable: false,
      exportValue: ({ row }) => formatCurrency(row.charge?.amount.value, 100),
      renderCell: ({ row }) => formatCurrency(row.charge?.amount.value, 100),
    },
    {
      field: 'card',
      headerName: translate('earnings_calendar.table.detalhes-profit-without-shipping'),
      minWidth: 100,
      flex: 1,
      sortable: false,
      exportValue: ({ row }) => formatCurrency(getValueEarnings(row)?.amount.value, 100),
      renderCell: ({ row }) => formatCurrency(getValueEarnings(row)?.amount.value, 100),
    },
    {
      field: 'cardType',
      headerName: translate('earnings_calendar.table.detalhes-card'),
      minWidth: 50,
      sortable: false,
      exportValue: ({ row }) => row.charge.brand,
      renderCell: ({ row }) => row.charge.brand,
    },
    {
      field: 'cardType',
      headerName: translate('earnings_calendar.table.detalhes-type-of-card'),
      minWidth: 50,
      sortable: false,
      exportValue: ({ row }) => getTypeCard(row.method),
      renderCell: ({ row }) => getTypeCard(row.method),
    },
  ];

  const renderComponentHeader = (title, value, color, key) => (
    <Card height="100%" m="0" p="0" overflow="hidden" position="relative" backgroundColor={color} key={key}>
      <FlexBox p="10px" height="100%">
        <FlexBox alignItems="center" justifyContent="center" flexDirection="column">
          <FlexBox alignItems="center" justifyContent="center">
            {dataHeader && (
              <FlexBox flexDirection="column" alignItems="center" justifyContent="center">
                <Typography variant="h6" p="0 5px">{title}</Typography>
                <Typography variant="h6" p="0 5px">{value}</Typography>
              </FlexBox>
            )}
            {!dataHeader && <ProgressIndicator size={24} />}
          </FlexBox>
        </FlexBox>
      </FlexBox>
    </Card>
  );

  const getDataFilter = () => {
    let initialDate = formatDateTimeIso(today.getTime() - 604800000, dateFormat);
    let finalDate = formatDateTimeIso(today.getTime(), dateFormat);

    if (filter && filter.period && filter.period.initialDate && filter.period.finalDate) {
      initialDate = formatDateTimeIso(filter.period.initialDate, dateFormat);
      finalDate = formatDateTimeIso(filter.period.finalDate, dateFormat);
    }

    return (
      <FlexBox m={isMobile ? '5px 0' : '0px 0px 0px 10px'} alignItems="center" className={currentFilter}>
        {`${initialDate} - ${finalDate}`}
      </FlexBox>
    );
  };

  return (
    <>
      <Page>
        <FlexBox flexDirection="column">
          <Card>
            <FlexBox p="10px 0px">
              <Typography p="0 10px" variant="h6">{translate('sales.earnings_calendar')}</Typography>
            </FlexBox>
            {getDataFilter()}
            <FlexBox p="20px 10px" flexDirection={isMobile ? 'column' : false} gap="20px">
              {renderComponentHeader(
                translate('earnings_calendar.table-card-sales'),
                dataHeader ? dataHeader.amount : '',
                'rgb(118, 118, 118, 0.5)',
                '1',
              )}
              {renderComponentHeader(
                translate('earnings_calendar.table-card-value-of-products'),
                dataHeader ? formatCurrency(dataHeader.valueOfProducts, 100) : '',
                'rgb(220, 58, 55, 0.5)',
                '2',
              )}
              {renderComponentHeader(
                translate('earnings_calendar.table-card-netvalue'),
                dataHeader ? formatCurrency(dataHeader.netValue, 100) : '',
                'rgb(86, 154, 50, 0.5)',
                '3',
              )}
            </FlexBox>
            <TableComponent
              loading={loadingEarningsCalendar}
              dataset={dataTable}
              columns={columns}
              dataTableConfig={{
                showPagination: true,
                showMoreDetails: true,
                columnsDetail,
                datasetDetail: dataSetDetails,
                earningText: true,
                componentButtonMoreDetails: ({ click, activedRow }) => (
                  activedRow ? (
                    <TooltipComponent title={translate('earnings_calendar.table.less_details')}>
                      <IconButton
                        color="default"
                        size="medium"
                        onClick={() => click()}
                      >
                        <Icon
                          name="outlined-navigation-arrowtop"
                          size="small"
                        />
                      </IconButton>
                    </TooltipComponent>
                  ) : (
                    <TooltipComponent title={translate('earnings_calendar.table.more_details')}>
                      <IconButton
                        color="default"
                        size="medium"
                        onClick={() => click()}
                      >
                        <Icon
                          name="outlined-navigation-arrowbottom"
                          size="small"
                        />
                      </IconButton>
                    </TooltipComponent>
                  )
                ),
                componentMoreDetails: (rowSelected) => <EarningsCalendarDetailsComponent rowSelected={rowSelected} />,
              }}
              fileName={translate('export.table.earnings-calendar')}
            />

          </Card>
        </FlexBox>
      </Page>
    </>
  );
};

export default EarningsCalendarComponent;
