import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import { TextField } from '@naturacosmeticos/natds-web';
import ShareCouponModal from './ShareCouponModal';
import { I18nContext } from '../../config/i18n';
import Card from '../../components/card/Card';
import FlexBox from '../../components/flex-box/FlexBox';
import DataTable from '../../components/datatable/DataTable';
import { couponsTableStyle } from './CouponsTable.style';
import { formatDateToExport } from '../../utils/date-utils';
import Dialog from '../../components/dialog/Dialog';
import Button from '../../components/button/Button';
import DialogTitle from '../../components/dialog-title/DialogTitle';
import DialogContent from '../../components/dialog-content/DialogContent';
import DialogActions from '../../components/dialog-actions/DialogActions';
import { pushInteraction } from '../../data-layer/interaction';

function CouponsTable() {
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const {
    coupons, activeCoupon, loading, reloadData,
  } = useStoreState((state) => state.coupons);
  const { register, handleSubmit } = useForm();
  const [couponToClose, setCouponToClose] = useState({ open: false, code: '' });
  const [shareCouponScreen, setShareCouponScreen] = useState({ open: false, coupon: {} });
  const { personId, customerNo } = useStoreState((state) => state.main);
  const {
    setSearch, filterCoupons, finishCoupon, fetchCouponsSummary, fetchCouponsByStatus, setError,
  } = useStoreActions((actions) => actions.coupons);
  const classes = couponsTableStyle();
  const history = useHistory();

  function toggleCloseCouponModal(code) {
    setCouponToClose({ code: code || false, open: !couponToClose.open });
  }

  function toggleshareModal(coupon) {
    if (coupon?.code) pushInteraction('click', 'share coupon', coupon.code);
    setShareCouponScreen({ coupon: coupon || false, open: !shareCouponScreen.open });
  }

  function confirmCloseCoupon() {
    pushInteraction('click', 'finish coupon', couponToClose?.code);
    finishCoupon({
      personId,
      customerId: customerNo,
      couponCode: couponToClose.code,
    });

    toggleCloseCouponModal();
  }

  useEffect(() => {
    if (reloadData) {
      fetchCouponsSummary({ customerNo });
      fetchCouponsByStatus({ customerNo, status: 'active' });
    }
  }, [reloadData]);

  const columns = [
    {
      field: 'code',
      headerName: translate('coupons.table.head_code'),
      minWidth: 130,
      flex: 1,
      disableColumnMenu: true,
      exportValue: ({ row }) => row.code,
      renderCell: ({ row }) => row.code,
    },
    {
      field: 'percentDiscount',
      headerName: translate('coupons.table.head_discount'),
      type: 'number',
      minWidth: 130,
      exportValue: ({ row }) => `${row.percentDiscount}%`,
      renderCell: ({ row }) => `${row.percentDiscount}%`,
      disableColumnMenu: true,
    },
    {
      field: 'startDateValidity',
      headerName: translate('coupons.table.head_initial_date'),
      hide: activeCoupon === 'couponUsedList',
      minWidth: 120,
      disableColumnMenu: true,
      exportValue: ({ row }) => {
        const splitDate = row.startDateValidity.split('/');
        const newDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
        return (
          row.startDateValidity && formatDateToExport(newDate, translate('format.date'))
        );
      },
      renderCell: ({ row }) => {
        const splitDate = row.startDateValidity.split('/');
        const newDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
        return (
          row.startDateValidity && formatDateToExport(newDate, translate('format.date'))
        );
      },
    },
    {
      field: 'endDateValidity',
      headerName: translate('coupons.table.head_final_date'),
      hide: activeCoupon === 'couponUsedList',
      minWidth: 130,
      disableColumnMenu: true,
      exportValue: ({ row }) => {
        const splitDate = row.endDateValidity.split('/');
        const newDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
        return (
          row.endDateValidity && formatDateToExport(newDate, translate('format.date'))
        );
      },
      renderCell: ({ row }) => {
        const splitDate = row.endDateValidity.split('/');
        const newDate = `${splitDate[1]}/${splitDate[0]}/${splitDate[2]}`;
        return (
          row.endDateValidity && formatDateToExport(newDate, translate('format.date'))
        );
      },
    },
    {
      field: 'qtyMaxRedemption',
      headerName: translate('coupons.table.head_remain'),
      sortable: false,
      hide: activeCoupon === 'couponNaturaList',
      type: activeCoupon === 'couponEnableList' ? 'string' : 'number',
      disableColumnMenu: true,
      exportValue: ({ row }) => (
        activeCoupon === 'couponEnableList'
          ? `${row.qtyUsedredemption} ${translate('coupons.abbreviation')} ${row.qtyMaxRedemption}`
          : row.qtyUsedredemption || row.qtyMaxRedemption
      ),
      renderCell: ({ row }) => (
        activeCoupon === 'couponEnableList'
          ? `${row.qtyUsedredemption} ${translate('coupons.abbreviation')} ${row.qtyMaxRedemption}`
          : row.qtyUsedredemption || row.qtyMaxRedemption
      ),
    },
    {
      field: ' ',
      headerName: ' ',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      hide: activeCoupon !== 'active',
      renderCell: ({ row }) => (
        <Button color="primary" variant="contained" onClick={() => toggleshareModal(row)}>
          {translate('coupons.table.share_button')}
        </Button>
      ),
    },
    {
      field: '  ',
      headerName: ' ',
      width: 150,
      sortable: false,
      disableColumnMenu: true,
      hide: activeCoupon !== 'active',
      renderCell: ({ row }) => (
        <Button color="primary" variant="contained" onClick={() => toggleCloseCouponModal(row.code)}>
          {translate('coupons.table.finalization_button')}
        </Button>
      ),
    },
  ];

  function handleFilter({ searchValue }) {
    setSearch(searchValue);
    filterCoupons();
    pushInteraction('search', 'coupon', searchValue);
  }

  function handleClick() {
    setError(false);
    pushInteraction('navigation', 'coupon page', 'create coupon');
    history.push('/coupons/new');
  }

  return (
    <>
      <Card>
        <FlexBox flexDirection={isMobile && 'column'} alignItems={isMobile ? 'flex-end' : 'center'}>
          <form style={{ width: isMobile && '100%' }} onSubmit={handleSubmit(handleFilter)}>
            <FlexBox className={classes.textField} alignItems="center">
              <TextField
                {...register('searchValue')}
                style={{
                  marginRight: '10px', flex: '1', minWidth: isMobile ? '150px' : '282px', minHeight: '40px',
                }}
                placeholder={translate('coupons.table.search_input')}
              />
              <Button
                color="primary"
                variant="contained"
                style={{ marginLeft: '15px' }}
                onClick={handleSubmit(handleFilter)}
              >
                {translate('search')}
              </Button>
            </FlexBox>
          </form>

        </FlexBox>
        <DataTable
          rowId="code"
          loading={loading}
          dataset={coupons}
          button={(
            <Button
              variant="contained"
              onClick={handleClick}
              style={{ marginRight: '10px' }}
            >
              {translate('coupons.table.create_coupon')}
            </Button>
          )}
          columns={columns}
          fileName={translate('export.table.coupons')}
        />
        <Dialog
          maxWidth="sm"
          open={couponToClose.open}
          onEscapeKeyDown={toggleCloseCouponModal}
        >
          <DialogTitle>
            {translate('coupons.close_coupon_modal_title')}
          </DialogTitle>
          <DialogContent>
            {`${translate('coupons.close_coupon_modal')} ${couponToClose.code}?`}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={toggleCloseCouponModal}
              variant="text"
            >
              {translate('cancel_button')}
            </Button>
            <Button
              color="primary"
              onClick={confirmCloseCoupon}
              variant="text"
            >
              {translate('confirm_button')}
            </Button>
          </DialogActions>
        </Dialog>
        <ShareCouponModal
          toggleshareModal={toggleshareModal}
          data={{
            open: shareCouponScreen.open,
            coupon: {
              ...shareCouponScreen.coupon,
              description: `${shareCouponScreen.coupon.percentDiscount}%`,
              endDate: new Date(shareCouponScreen.coupon.endDateValidity).getTime(),
            },
          }}
        />
      </Card>
    </>
  );
}

export default CouponsTable;
