import React, { useContext } from 'react';
import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import { paymentStatusStyle } from './PaymentStatus.style';

const PaymentStatus = ({
  open, isMobile, orderApproved, orderDone, orderCanceled,
}) => {
  const { translate } = useContext(I18nContext);

  const total = orderApproved + orderDone + orderCanceled;

  const {
    red, yellow, green, circle, openGreen, openYellow, openRed, smallCircle,
  } = paymentStatusStyle({
    orderApproved,
    orderDone,
    orderCanceled,
    total,
  });

  return (
    <FlexBox p={!isMobile && '0 0 0 16px'} flex="1">
      <FlexBox
        justifyContent="space-between"
        alignItems={!open && 'center'}
        flexDirection={open && 'column'}
        p="10px 0"
      >
        <Typography>{translate('sales.resume.payment_status.title')}</Typography>
        {open && (
        <FlexBox flexDirection="column" justifyContent="center">
          <FlexBox m="3px 0" alignItems="center">
            <div className={`${circle} ${smallCircle} ${green}`} />
            <Typography>{translate('sales.resume.payment_status.approved')}</Typography>
          </FlexBox>
          <FlexBox m="3px 0" alignItems="center">
            <div className={`${circle} ${smallCircle} ${yellow}`} />
            <Typography>{translate('sales.resume.payment_status.accomplished')}</Typography>
          </FlexBox>
          <FlexBox m="3px 0" alignItems="center">
            <div className={`${circle} ${smallCircle} ${red}`} />
            <Typography>{translate('sales.resume.payment_status.canceled')}</Typography>
          </FlexBox>
        </FlexBox>
        )}
      </FlexBox>
      <FlexBox alignItems={!open && 'center'}>
        <FlexBox maxWidth="50px" justifyContent="flex-end" flexDirection="column" alignItems="center">
          {open && <Typography>{orderApproved}</Typography>}
          <div className={`${circle} ${green} ${open && openGreen}`}>{!open && orderApproved}</div>
        </FlexBox>
        <FlexBox maxWidth="50px" justifyContent="flex-end" flexDirection="column" alignItems="center">
          {open && <Typography>{orderDone}</Typography>}
          <div className={`${circle} ${yellow} ${open && openYellow}`}>{!open && orderDone}</div>
        </FlexBox>
        <FlexBox maxWidth="50px" justifyContent="flex-end" flexDirection="column" alignItems="center">
          {open && <Typography>{orderCanceled}</Typography>}
          <div className={`${circle} ${red} ${open && openRed}`}>{!open && orderCanceled}</div>
        </FlexBox>
      </FlexBox>
    </FlexBox>
  );
};

export default PaymentStatus;
