import config from '../config/config';

export function formatNumber(value, opt) {
  const options = {
    maximumFractionDigits: config.general.currencyFractionDigits,
    minimumFractionDigits: config.general.currencyFractionDigits,
  };
  const number = new Intl.NumberFormat(config.general.languageIso,
    { ...options, ...opt }).format(value);
  return number;
}

export function formatCurrency(value, divisor = 1) {
  const numberFractionDigits = config.general.currencyFractionDigits;
  const options = {
    style: 'currency',
    currency: config.general.currencyIso,
    minimumFractionDigits: numberFractionDigits,
    maximumFractionDigits: numberFractionDigits,
  };
  const formattedValue = formatNumber(value / divisor, options);
  return formattedValue;
}

export const removeMaskPhone = (value = '') => {
  let stringReplace = value;
  stringReplace = stringReplace.replaceAll(' ', '');
  stringReplace = stringReplace.replaceAll('(', '');
  stringReplace = stringReplace.replaceAll(')', '');
  stringReplace = stringReplace.replaceAll('-', '');
  stringReplace = stringReplace.replaceAll('_', '');
  return stringReplace;
};

export const handlePhoneContries = {
  ar: {
    mask: [/\d/, /\d/, ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    normalize: (value) => {
      if (!value) {
        return '';
      }
      return value.replace(/(\d{2})?(\d{3})?(\d{8})/, '$1 $2 $3');
    },
    placeholder: 'XX XXX XXXXXXXX',
    maxLengthWhatsappNumber: 13,
  },
  mx: {
    mask: [/\d/, /\d/, ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    normalize: (value) => {
      if (!value) {
        return '';
      }
      return value.replace(/(\d{2})?(\d{2})?(\d{8})/, '$1 $2 $3');
    },
    placeholder: 'XX XX XXXXXXXX',
    maxLengthWhatsappNumber: 13,
  },
  ch: {
    mask: [/\d/, /\d/, ' ', /[1-9]/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
    normalize: (value) => {
      if (!value) {
        return '';
      }
      return value.replace(/(\d{2})?(\d{3})?(\d{8})/, '$1 $2 $3');
    },
    placeholder: 'XX XXX XXXXXX',
    maxLengthWhatsappNumber: 11,
  },
  br: {
    // eslint-disable-next-line max-len
    mask: [/\d/, /\d/, ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    normalize: (value) => {
      if (!value) {
        return '';
      }
      return value.replace(/(\d{2})?(\d{3})?(\d{4})?(\d{4})/, '$1 ($2) $3-$4');
    },
    placeholder: 'XX (XXX) XXXXX-XXXX',
    maxLengthWhatsappNumber: 15,
  },
};
