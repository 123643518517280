import React, { useContext, useState } from 'react';
import { Slider } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { DatePicker } from '@material-ui/pickers';
import { useForm, Controller } from 'react-hook-form';
import { TextField } from '@naturacosmeticos/natds-web';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@material-ui/styles';

import FlexBox from '../../../components/flex-box/FlexBox';
import Card from '../../../components/card/Card';
import Typography from '../../../components/typography/Typography';
import { I18nContext } from '../../../config/i18n';
import ShareCouponModal from '../ShareCouponModal';
import { NewCouponCardStyle } from './NewCouponCard.style';
import Switch from '../../../components/switch/Switch';
import Button from '../../../components/button/Button';
import Dialog from '../../../components/dialog/Dialog';
import DialogContent from '../../../components/dialog-content/DialogContent';
import DialogActions from '../../../components/dialog-actions/DialogActions';
import { pushRegisterCoupon } from '../../../data-layer/coupon';
import config from '../../../config/config';
import { sameDay } from '../../../utils/date-utils';
import ProgressIndicator from '../../../components/progress-indicator/ProgressIndicator';

function NewCoupon() {
  const theme = useTheme();
  const classes = NewCouponCardStyle();
  const history = useHistory();
  const {
    control, handleSubmit, setValue, formState: { errors },
  } = useForm();
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const [activeSwitch, setActiveSwitch] = useState(false);
  const [initialDate, setInitialDate] = useState(new Date());
  const {
    createCoupon,
    toggleModal,
    fetchCouponsSummary,
    fetchCouponsByStatus,
  } = useStoreActions((actions) => actions.coupons);
  const { openModal, modalData = {}, loadingCreateCoupon } = useStoreState((state) => state.coupons);
  const { personId, customerNo, levelCN } = useStoreState((state) => state.main);
  const [confirmModal, setConfirmModal] = useState({});

  const withoutLevel = levelCN ? config.general.maximunDiscountCoupons : config.general.maximunWithoutLevelCoupons;

  // eslint-disable-next-line max-len, eqeqeq
  const maxDiscountCoupon = config.general.levelsDiscountCoupons?.filter((lv) => lv.level == levelCN)[0]?.discount || withoutLevel;

  function onSubmit({
  // eslint-disable-next-line no-shadow
    couponName, cuponPercent, finalDate, initialDate, quantity,

  }) {
    if (
      !couponName
        || !cuponPercent
        || !finalDate
        || !initialDate
        || !quantity
        || !(finalDate > initialDate)
        || !((finalDate - initialDate) < 2592000000)) return;
    setConfirmModal({
      open: true,
      couponData: {
        // eslint-disable-next-line max-len
        couponName, cuponPercent: activeSwitch ? Number(maxDiscountCoupon) : cuponPercent, finalDate, initialDate, quantity,
      },
    });
  }

  const modalControl = {
    open: openModal,
    coupon: {
      endDate: modalData.endDateValidity && modalData.endDateValidity.getTime(),
      description: `${modalData.discountPercent}%`,
      code: modalData.couponId,
    },
  };

  function handleConfirmModal() {
    const {
      // eslint-disable-next-line no-shadow
      initialDate,
      couponName,
      cuponPercent,
      finalDate,
      quantity,
    } = confirmModal.couponData;

    createCoupon({
      personId,
      customerId: customerNo,
      couponId: couponName,
      discountPercent: activeSwitch ? Number(maxDiscountCoupon) : Number(cuponPercent),
      startDateValidity: initialDate,
      endDateValidity: finalDate,
      qtyMaxRedemption: Number(quantity),
    });

    pushRegisterCoupon(confirmModal.couponData);
    setConfirmModal({});
    fetchCouponsSummary({ customerNo });
    fetchCouponsByStatus({ customerNo, status: 'active' });
  }

  function closeConfirmModal() {
    setConfirmModal({});
  }

  function handleCancel() {
    history.goBack();
  }

  function getFinalDateInitial() {
    const newDate = new Date();
    newDate.setDate(newDate.getDate() + 1);
    return newDate;
  }

  return (
    <>
      <Card>
        {loadingCreateCoupon ? (
          <FlexBox alignItems="center" justifyContent="center">
            <ProgressIndicator size={24} />
          </FlexBox>
        ) : (
          <FlexBox flexDirection="column">
            <Typography variant="h6">{translate('coupons.new_coupon.title')}</Typography>
            <Typography p="10px 0 20px 0">{translate('coupons.new_coupon.explain_text')}</Typography>

            <form onSubmit={handleSubmit(onSubmit)} className={classes.form}>
              <Controller
                style={{ with: '100%' }}
                render={({ field }) => (
                  <TextField
                    maxLength="10"
                    label={translate('coupons.new_coupon.input_coupon_name')}
                    onChange={(event) => {
                      // eslint-disable-next-line no-param-reassign
                      event.target.value = event.target.value.replace(/[^a-z0-9]/gi, '').toUpperCase();
                      field.onChange(event);
                    }}
                    style={{ marginRight: '10px' }}
                    placeholder={translate('coupons.new_coupon.input_coupon_code')}
                    helpText={errors.couponName && translate('coupons.new_coupon.input_coupon_code')}
                    className={classes.textFieldAlert}
                    rows={1}
                    size="semiX"
                  />
                )}
                rules={{ required: true }}
                control={control}
                name="couponName"
              />
              <Typography p="10px 0">{translate('coupons.new_coupon.input_coupon_rule')}</Typography>
              <Typography p="10px 0" variant="h6">{translate('coupons.new_coupon.value')}</Typography>
              <FlexBox p="10px 0" m="0px -7px" alignItems="center">
                <Switch onChange={(event, value) => {
                  setActiveSwitch(value);
                  setValue('cuponPercent', maxDiscountCoupon);
                }}
                />
                <Typography>{translate('coupons.new_coupon.switch_label')}</Typography>
                {activeSwitch && (
                <Typography m="0 0 0 5px">
                  (
                    {maxDiscountCoupon}
                  %)
                </Typography>
                )}
              </FlexBox>
              {!activeSwitch && (
              <Controller
                control={control}
                name="cuponPercent"
                defaultValue={0}
                rules={{
                  required: true,
                  validate: {
                    positive: (v) => parseInt(v, 10) > 0,
                  },
                }}
                render={({ field }) => (
                  <FlexBox flexDirection="column" m="0px 0px 20px 0px">
                    <FlexBox>
                      <Slider
                        value={field.value}
                        onChange={(event, value) => field.onChange(value)}
                        aria-labelledby="input-slider"
                        className={classes.slider}
                        max={Number(maxDiscountCoupon)}
                        min={Number(config?.general?.minimunDiscountCoupons)}
                        step={Number(config?.general?.stepCoupons)}
                      />
                      <div className={classes.percentDetail}>
                        {field.value}
                        %
                      </div>
                    </FlexBox>
                    {errors.cuponPercent && (
                      <Typography
                        m="0px 0px 20px 0px"
                        className="MuiFormHelperText-root"
                        color={theme.color.alert}
                      >
                        {translate('coupons.new_coupon.input_select_value')}
                      </Typography>
                    )}
                  </FlexBox>
                )}
              />
              )}
              <FlexBox flexDirection={isMobile && 'column'} justifyContent="space-between" flex="1">
                <Controller
                  control={control}
                  defaultValue={new Date()}
                  name="initialDate"
                  render={({ field }) => (
                    <DatePicker
                      disablePast
                      className={classes.calendar}
                      format="dd/MM/yyyy"
                      label={translate('coupons.new_coupon.input-1')}
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date);
                        setInitialDate(date);
                      }}
                      selected={field.value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="finalDate"
                  defaultValue={getFinalDateInitial()}
                  render={({ field }) => (
                    <DatePicker
                      className={
                              initialDate > field.value || field.value - initialDate > 2592000000
                                ? classes.calendarAlert
                                : classes.calendar
                            }
                      disablePast
                      style={isMobile ? { margin: '20px 0px' } : {}}
                      format="dd/MM/yyyy"
                      label={translate('coupons.new_coupon.input-2')}
                      value={field.value}
                      helperText={(initialDate > field.value || sameDay(initialDate, field.value))
                        ? translate('coupons.new_coupon.input-2-error')
                        : translate('coupons.new_coupon.input-2-help-text')}
                      onChange={(date) => field.onChange(date)}
                      selected={field.value}
                    />
                  )}
                />
                <Controller
                  control={control}
                  name="quantity"
                  defaultValue="1"
                  rules={{ min: 1, max: 10000 }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      id="cupon-date-3"
                      placeholder={field.value.toString()}
                      label={translate('coupons.new_coupon.input-3')}
                      onChange={(event) => {
                        if (Number(event.target.value) > 10000) {
                          // eslint-disable-next-line no-param-reassign
                          event.target.value = 10000;
                        }
                        // eslint-disable-next-line no-param-reassign
                        event.target.value = event.target.value.replace(/[^0-9]/gi, '');
                        field.onChange(event);
                      }}
                      helpText={translate('coupons.new_coupon.input-3-help-text')}
                      value={Number(field.value)}
                      className={errors.quantity ? classes.textFieldAlert : classes.textField}
                      rows={1}
                      size="semiX"
                    />
                  )}
                />
              </FlexBox>
              <FlexBox m="15px 0" alignItems="center" justifyContent="flex-end">
                <Button
                  style={{ marginRight: '10px' }}
                  variant="contained"
                  onClick={handleCancel}
                >
                  {translate('coupons.new_coupon.cancel')}
                </Button>
                <Button variant="contained" type="submit">{translate('coupons.new_coupon.submit')}</Button>
              </FlexBox>
            </form>
          </FlexBox>
        )}
        <Dialog
          maxWidth="sm"
          open={confirmModal.open}
          onClose={closeConfirmModal}
          onEscapeKeyDown={closeConfirmModal}
        >
          <DialogContent>
            {/* eslint-disable-next-line max-len */}
            {translate('coupons.new_coupon.confirm_modal_text', [confirmModal.couponData && confirmModal.couponData.couponName ? confirmModal.couponData.couponName : ''])}
          </DialogContent>
          <DialogActions>
            <Button
              color="primary"
              onClick={closeConfirmModal}
              variant="text"
            >
              {translate('cancel_button')}
            </Button>
            <Button
              color="primary"
              onClick={handleConfirmModal}
              variant="text"
            >
              {translate('confirm_button')}
            </Button>
          </DialogActions>
        </Dialog>
        {modalControl.open && <ShareCouponModal data={modalControl} toggleshareModal={toggleModal} />}
      </Card>
    </>
  );
}

export default NewCoupon;
