import { pushDataLayerError } from './reportError';

export const pushUserData = (payload) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'userData',
      ...payload,
    });
  } catch (e) {
    pushDataLayerError('user', e);
  }
};
