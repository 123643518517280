import { action, thunk } from 'easy-peasy';
import fetcher from '../../api/fetcher';
import { generateHash } from '../../utils/encrypt-utils';

const initialState = {
  sales: [],
  backToPageSales: false,
  originalData: [],
  customer: {},
  earningsCalendarData: null,
  loading: false,
  loadingEarningsCalendar: null,
  error: false,
  filter: {
    search: null,
  },
  firstLoad: true,
};

const salesModel = {
  ...initialState,
  resetStoreSales: action((state) => {
    Object.assign(state, initialState);
  }),
  setBackToPageSales: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.backToPageSales = payload;
  }),
  addSales: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.sales = payload;
    // eslint-disable-next-line no-param-reassign
    state.loading = false;
  }),
  addOriginalData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.originalData = payload;
  }),
  addEarningsCalendarData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.earningsCalendarData = payload;
  }),
  setLoading: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loading = payload;
  }),
  setLoadingEarningsCalendar: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loadingEarningsCalendar = payload;
  }),
  setError: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.error = payload;
  }),
  setFilter: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filter = payload;
  }),
  setFirstLoad: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.firstLoad = payload;
  }),
  filterSales: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.sales = state.originalData.orders;
    const { search } = state.filter;
    if (search && state.originalData && state.originalData.orders) {
      // eslint-disable-next-line no-param-reassign
      state.sales = state.originalData.orders.filter((item) => (
        item.customerName.toUpperCase().indexOf(search.toUpperCase()) > -1
        || item.orderId.toUpperCase().indexOf(search.toUpperCase()) > -1));
    }
  }),
  // eslint-disable-next-line no-unused-vars
  fetchSales: thunk(async (actions, payload) => {
    actions.setLoading(true);
    actions.setError(false);
    const { initialDate, finalDate, personId } = payload;

    // const data = {
    //   personId: 'a33931a9-d05d-4dc6-8819-230618f0b7db',
    //   orderEndDate: '17/08/2022',
    //   orderStartDate: '10/08/2022',
    //   orders: [

    //   ],
    // };
    // actions.addOriginalData(data);
    // actions.addSales(data.orders);

    // https://painelcnd-natura-exp-qa.us-e2.cloudhub.io/v1/sales?personId=b8c913cc-2ef4-46ae-acaa-fe27c1cf2fd3&startDate=2022-01-10&endDate=2022-01-17
    fetcher().get(`sales?personId=${personId}&startDate=${initialDate}&endDate=${finalDate}`, {
      headers: {
        personId,
        cnId: generateHash(personId),
      },
    }).json()
      .then((data) => {
        actions.setLoading(false);
        actions.addOriginalData(data);
        actions.addSales(data.orders);
        actions.setFirstLoad(false);
      })
      .catch((err) => {
        actions.setLoading(false);
        console.error('error: -> ', err.message);
      });
  }),
  fetchEarningsCalendar: thunk(async (actions, payload) => {
    actions.setLoadingEarningsCalendar(true);
    actions.setError(false);
    const { orders } = payload;
    fetcher().get(`naturapay/orders?ids=${orders}`).json()
      .then((data) => {
        actions.addEarningsCalendarData(data);
        actions.setLoadingEarningsCalendar(false);
      })
      .catch((err) => {
        console.error('error: -> ', err.message);
        actions.addEarningsCalendarData([]);
        actions.setLoadingEarningsCalendar(false);
      });
  }),
};
export default salesModel;
