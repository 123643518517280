import React, { useContext, useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';

import FlexBox from '../../components/flex-box/FlexBox';
import Card from '../../components/card/Card';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import DualText from '../../components/dual-text/DualText';
import { formatDateIso } from '../../utils/date-utils';
import Button from '../../components/button/Button';
import config from '../../config/config';

console.log('config.general', config.general);
const { changePasswordLink } = config.general;

function PersonalInfo() {
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const [personAge, setPersonAge] = useState();

  const {
    personFullName,
    personBirthday,
    personIdNumber,
    personCnCode,
    personGender,
    // personState,
  } = useStoreState((state) => state.main);

  const calculateAge = (date) => {
    const diffMs = Date.now() - new Date(date).getTime();
    const ageDt = new Date(diffMs);

    return Math.abs(ageDt.getUTCFullYear() - 1970);
  };

  const getGender = () => {
    if (personGender) {
      return personGender === 1 ? translate('profile.male') : translate('profile.female');
    }
    return '';
  };

  useEffect(() => {
    if (personBirthday) {
      setPersonAge(calculateAge(personBirthday));
    }
  }, [personBirthday]);

  const changePassword = () => {
    window.open(changePasswordLink, '_self');
  };

  return (
    <Card>
      <FlexBox>
        <Typography m="0px 0 10px 0" variant="h5" color="primary">{translate('profile.profile_info_title')}</Typography>
      </FlexBox>
      <FlexBox flexDirection={isMobile && 'column'}>
        <FlexBox flexDirection="column">
          <DualText
            text1={translate('profile.complete_name')}
            text2={personFullName}
          />
          <DualText
            text1={translate('profile.code')}
            text2={personCnCode}
          />
          <DualText
            text1={translate('profile.birthdate')}
            text2={formatDateIso(personBirthday)}
          />
          <DualText
            text1={translate('profile.age')}
            text2={personAge}
          />
        </FlexBox>
        <FlexBox flexDirection="column">
          <DualText
            text1={translate('profile.document')}
            text2={personIdNumber}
          />
          <DualText
            text1={translate('profile.gender')}
            text2={getGender()}
          />
          {/* <DualText
            text1={translate('profile.state')}
            text2={personState}
          /> */}
        </FlexBox>
      </FlexBox>
      {changePasswordLink && (
        <FlexBox flexDirection="column" alignItems="end" padding={isMobile && '10px 0px 0px 0px'}>
          <Button
            style={isMobile ? { width: '100%' } : { width: 'max-content' }}
            variant={isMobile ? 'outlined' : 'text'}
            color="primary"
            onClick={() => changePassword()}
          >
            {translate('profile.change_password')}
          </Button>
        </FlexBox>
      )}
    </Card>
  );
}

export default PersonalInfo;
