import React, { useContext, useEffect, useState } from 'react';
import { useTheme } from '@material-ui/styles';
import { makeStyles } from '@naturacosmeticos/natds-web';
import { useStoreState, useStoreActions } from 'easy-peasy';

import { I18nContext } from '../../../config/i18n';
import FlexBox from '../../../components/flex-box/FlexBox';
import Typography from '../../../components/typography/Typography';
import Button from '../../../components/button/Button';
import Card from '../../../components/card/Card';

export const componentStyle = makeStyles(() => ({
  popOver: {
    position: 'relative',
    top: '-40px',
    right: '0',
    zIndex: 1302,
    boxShadow: '0 12px 17px 0 rgb(0 0 0 / 14%)',
    width: '265px',
    'max-height': '200px',
    margin: 'auto',
    display: 'block',
    'overflow-x': 'hidden',
    maxHeight: '200px',
  },
  hide: {
    display: 'none',
  },
  hideButton: {
    position: 'fixed',
    backgroundColor: 'transparent',
    border: 'none',
    top: '0',
    left: '0',
    right: '0',
    height: '100%',
    width: '100%',
    zIndex: '1301',
  },
  itensDropDown: {
    maxWidth: '100%',
    height: '40px',
    display: 'inherit',
    cursor: 'pointer',
    '& > span': {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      marginLeft: '10px',
    },
    '&:hover': {
      background: ({ theme }) => (theme === 'dark' ? '#4b4b4b' : '#f5f5f5'),
    },
  },
}));

const ShowcaseData = () => {
  const themeMaterialUi = useTheme();
  const { setCategorySelected } = useStoreActions((actions) => actions.main);
  const { theme, showcaseList = [], categorySelected } = useStoreState((actions) => actions.main);
  const [openDropDown, setOpenDropDown] = useState(false);
  const { translate } = useContext(I18nContext);
  const color = themeMaterialUi.color.neutral400;
  const classes = componentStyle({ theme });
  const [isMobile] = useState(window.innerWidth < 1080);

  function handleChangeItem(item) {
    setCategorySelected(item);
    setOpenDropDown(false);
  }

  useEffect(() => {
    if (categorySelected && showcaseList && showcaseList.length > 0) {
      if (typeof categorySelected === 'string') {
        const elementFind = showcaseList.find((e) => e.id === categorySelected);
        if (elementFind) {
          setCategorySelected(elementFind);
        }
      }
    }
  }, [categorySelected, showcaseList]);

  const DropDown = () => (
    <>
      <div className={openDropDown ? classes.popOver : classes.hide}>
        <Card
          maxWidth={isMobile && '320px'}
          p="0"
          width="100%"
          height="100%"
          m="0"
        >
          {showcaseList.map((e, index) => (
            <div
              className={classes.itensDropDown}
              onClick={() => handleChangeItem(e)}
              aria-hidden="true"
              key={index}
            >
              <span>
                {e.id}
              </span>
            </div>
          ))}
        </Card>
      </div>
      {openDropDown && (
        <button
          className={classes.hideButton}
          type="button"
          onClick={() => setOpenDropDown(false)}
        />
      )}
    </>
  );

  return (
    <>
      <FlexBox p="30px" flexDirection="column">
        <FlexBox
          borderBottom={`2px solid ${color}`}
          height="200px"
          textAlign="center"
          margin="0 auto"
          display="block"
        >
          <div style={{ marginTop: '30px' }}>
            {!categorySelected ? (
              <>
                <Typography
                  m="10px 0"
                >
                  {translate('digital_space.showcase_tab.category_not_selected')}
                </Typography>
                <Button
                  width="max-content"
                  variant="outlined"
                  color="primary"
                  onClick={() => setOpenDropDown(true)}
                >
                  <Typography p="0 0 0 10px" color="primary">
                    {translate('digital_space.showcase_tab.category_select')}
                  </Typography>
                </Button>
                <DropDown />
              </>
            ) : (
              <>
                <FlexBox flexDirection="column" justifyContent="center">
                  <Typography
                    m="10px 0"
                    variant="subtitle2"
                  >
                    {translate('digital_space.showcase_tab.category_selected')}
                  </Typography>
                  <Typography variant="h6">
                    {categorySelected.id}
                  </Typography>
                </FlexBox>
                <FlexBox display="block" p="15px 0 0 10px">
                  <Button
                    width="max-content"
                    variant="outlined"
                    color="primary"
                    onClick={() => setCategorySelected()}
                  >
                    <Typography p="0 0 0 10px" color="primary">
                      {translate('digital_space.showcase_tab.remove_category')}
                    </Typography>
                  </Button>
                  <Button
                    margin="0px 15px"
                    width="max-content"
                    variant="outlined"
                    color="primary"
                    onClick={() => setOpenDropDown(true)}
                  >
                    <Typography p="0 0 0 10px" color="primary">
                      {translate('digital_space.showcase_tab.change_category')}
                    </Typography>
                  </Button>
                  <DropDown />
                </FlexBox>
              </>
            ) }
          </div>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default ShowcaseData;
