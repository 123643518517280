import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useTheme } from '@material-ui/styles';
import { useStoreState, useStoreActions } from 'easy-peasy';

import fetcher from '../../../api/fetcher';
import OrderDetails from './OrderDetails';
import Page from '../../../components/page/Page';
import FlexBox from '../../../components/flex-box/FlexBox';
import CustomerDetails from './CustomerDetails';
import CustomerOrderInformation from './CustomerOrderInformation';
import GoBackBtn from '../../../components/go-back-btn/GoBackBtn';
import { I18nContext } from '../../../config/i18n';
import ErrorScreen from '../../../components/error-screen/ErrorScreen';
import DataTracking from './DataTracking';
import { generateHash, getPersonId } from '../../../utils/encrypt-utils';

const paymentTypesTranslate = {
  boletoPayment: 'sales.order_details.payment_ticket',
  CREDIT_CARD: 'sales.order_details.payment_credit_card',
  DEBIT_CARD: 'sales.order_details.payment_debit_card',
  MercadoPago: 'sales.order_details.payment_mercado_pago',
  MercadoPagoDeb: 'sales.order_details.payment_mercado_pago_deb',
};

const SalesDetail = () => {
  const { orderNumber } = useParams();
  const { color } = useTheme();
  const [pageContent, setPageContent] = useState({ loading: true, data: null });
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const [error, setError] = useState(null);
  const { sales = [] } = useStoreState((state) => state.sales);
  const { setBackToPageSales } = useStoreActions((actions) => actions.sales);
  const [trackingStatus, setTrackingStatus] = useState({});
  const personId = getPersonId();

  const orderStatusTranslate = {
    sent_to_erp: {
      text: 'sales.order_status.settled',
      color: color.success,
    },
    BOLETO_PENDING: {
      text: 'sales.order_status.waiting_confirmation',
      color: color.warning,
    },
    PENDING_MERCHANT_ACTION: {
      text: 'sales.order_status.waiting_merchant_action',
      color: color.alert,
    },
    AUTHORIZED: {
      text: 'sales.order_status.authorized',
      color: color.success,
    },
  };
  useEffect(() => {
    setPageContent({ loading: true, data: [] });
    fetcher().get(`sales/${orderNumber}`, {
      headers: {
        personId,
        cnId: generateHash(personId),
      },
    }).json()
      .then((data) => {
        if (data.statusOrder) {
          setPageContent({ loading: false, data });
        } else {
          const elementFind = sales.find((e) => e.orderId === orderNumber);
          if (elementFind) {
            if (elementFind.statusOrder[0] === '6' || elementFind.statusOrder[0] === '8') {
              // eslint-disable-next-line no-param-reassign
              data.statusOrder = 'cancelled';
            } else if (elementFind.statusOrder[0] === '4' || elementFind.statusOrder[0] === '3') {
              if (elementFind.payment_status === 'paid') {
                // eslint-disable-next-line no-param-reassign
                data.statusOrder = 'approved';
              } else {
                // eslint-disable-next-line no-param-reassign
                data.statusOrder = 94;
              }
            }
          }
          setPageContent({ loading: false, data });
        }
        localStorage.setItem('customerId', data.customerId);
        setError(null);
      })
      .catch((err) => {
        setError(err);
        console.error('error: -> ', err.message);
      });
  }, [orderNumber]);

  useEffect(() => {
    if (pageContent && pageContent.data && pageContent.loading === false) {
      const findTrackingStatus = DataTracking[pageContent.data.statusOrder] || {};
      setTrackingStatus(findTrackingStatus);
    }
  }, [pageContent]);

  return (
    <Page>
      {!error && (
      <>
        <GoBackBtn
          to="/sales"
          title={translate('sales.order_details.back_button')}
          execFunctionBeforeBackToPage={() => setBackToPageSales(true)}
        />
        <FlexBox flexDirection={isMobile && 'column'}>
          <OrderDetails
            paymentTypesTranslate={paymentTypesTranslate}
            isMobile={isMobile}
            loading={pageContent.loading}
            data={pageContent.data}
            trackingStatus={trackingStatus}
          />
          <CustomerDetails loading={pageContent.loading} data={pageContent.data} />
        </FlexBox>
        <CustomerOrderInformation
          trackingStatus={trackingStatus}
          paymentTypesTranslate={paymentTypesTranslate}
          orderStatusTranslate={orderStatusTranslate}
          loading={pageContent.loading}
          data={pageContent.data}
        />
      </>
      )}
      {error && (
        <ErrorScreen />
      )}
    </Page>
  );
};

export default SalesDetail;
