export default {
  90: {
    step: '',
    canceled: false,
  },
  901: {
    step: '',
    canceled: false,
  },
  900: {
    step: 'bb',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_3',
    label: 'sales.order_status.state_label_3',
  },
  910: {
    step: '',
    canceled: false,
  },
  911: {
    step: '',
    canceled: false,
  },
  950: {
    step: '',
    canceled: false,
  },
  1001: {
    step: '',
    canceled: false,
  },
  1002: {
    step: '',
    canceled: false,
  },
  charged_back: {
    step: '',
    canceled: false,
  },
  pending: {
    step: 'bb',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_2',
    label: 'sales.order_status.state_sublabel_2',
  },
  in_process: {
    step: 'bb',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_2',
    label: 'sales.order_status.state_sublabel_2',
  },
  in_mediation: {
    step: 'bb',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_2',
    label: 'sales.order_status.state_sublabel_2',
  },
  94: {
    step: 'bb',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_2',
    label: 'sales.order_status.state_sublabel_2',
  },
  approved: {
    step: 'cc',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_3',
    label: 'sales.order_status.state_sublabel_3',
  },
  authorized: {
    step: 'cc',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_3',
    label: 'sales.order_status.state_sublabel_3',
  },
  91: {
    step: 'cc',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_3',
    label: 'sales.order_status.state_sublabel_3',
  },
  sent_to_erp: {
    step: 'cc',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_3',
    label: 'sales.order_status.state_sublabel_3',
  },
  12: {
    step: 'dd',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_4',
    label: 'sales.order_status.state_sublabel_4',
  },
  '01': {
    step: 'dd',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_4',
    label: 'sales.order_status.state_sublabel_4',
  },
  '02': {
    step: 'dd',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_4',
    label: 'sales.order_status.state_sublabel_4',
  },
  940: {
    step: 'dd',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_4',
    label: 'sales.order_status.state_sublabel_4',
  },
  920: {
    step: 'ee',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_5',
    label: 'sales.order_status.state_sublabel_5',
  },
  930: {
    step: 'ff',
    canceled: false,
    titleLabel: 'sales.order_status.state_label_6',
    label: 'sales.order_status.state_sublabel_6',
  },
  rejected: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  cancelled: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  refunded: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  '09': {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  10: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  11: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  93: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  92: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  998: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  error_sending_to_erp: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  failed_try_sending_to_erp: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
  999: {
    step: 'bb',
    canceled: true,
    titleLabel: 'sales.order_status.state_label_7',
    label: 'sales.order_status.state_sublabel_7',
  },
};
