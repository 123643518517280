import React from 'react';
import StyledContainer from './Container.styles';

const FlexBox = ({ children, ...props }) => (
  <StyledContainer className={props.className} props={props}>
    {children}
  </StyledContainer>
);

export default FlexBox;
