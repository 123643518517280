import { makeStyles } from '@naturacosmeticos/natds-web';

export const profilePopoverStyle = makeStyles(() => ({
  codeSpan: {
    fontSize: '10px',
  },
  popOver: {
    position: 'absolute',
    top: '46px',
    right: ({ isMobile }) => (isMobile ? '-15px' : '0'),
    zIndex: 1302,
    boxShadow: '0 12px 17px 0 rgb(0 0 0 / 14%)',
    width: ({ isMobile }) => (isMobile ? '95vw' : 'max-content'),
  },
  hide: {
    display: 'none',
  },
  hideButton: {
    position: 'fixed',
    backgroundColor: 'transparent',
    border: 'none',
    top: '0',
    left: '0',
    right: '0',
    height: '100%',
    width: '100%',
    zIndex: '1301',
  },
}));
