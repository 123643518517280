import React, { useContext, useState } from 'react';
import { I18nContext } from '../../config/i18n';
import Typography from '../../components/typography/Typography';
import FlexBox from '../../components/flex-box/FlexBox';
import Icon from '../../components/icon/Icon';
import ProgressIndicator from '../../components/progress-indicator/ProgressIndicator';

const KpiList = ({ dataBase, loading }) => {
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const kpis = [
    {
      title: translate('visits.kpis.visits'),
      value: dataBase.visits || 0,
      icon: 'outlined-action-check',
      color: 'rgb(86, 154, 50)',
    },
    {
      title: translate('visits.kpis.diff_visits'),
      value: dataBase.users || 0,
      icon: 'outlined-social-person',
      color: 'rgb(237, 128, 59)',
    },
    {
      title: translate('visits.kpis.order_placed'),
      value: dataBase.orders || 0,
      icon: 'outlined-content-handbag',
      color: 'rgb(220, 58, 55)',
    },
    {
      title: translate('visits.kpis.conversion_rate'),
      value: `${dataBase.conversionRate || 0}`,
      icon: 'outlined-finance-discount',
      color: 'rgb(118, 118, 118)',
    },
  ];

  return (
    <>
      <FlexBox
        alignItems="center"
        padding={isMobile && '0 10px'}
        flexDirection={isMobile && 'column'}
      >
        {kpis.map((kpi) => (
          <FlexBox
            flexDirection="column"
            borderRadius="4px"
            p="10px"
            m="10px"
            backgroundColor={kpi.color}
            maxWidth={!isMobile && '300px'}
            key={kpi.title}
          >
            <FlexBox flex="1" justifyContent="center">
              <Typography>{kpi.title}</Typography>
            </FlexBox>
            <FlexBox alignItems="center" justifyContent={loading ? 'center' : 'space-between'}>
              {!loading && (
              <>
                <Icon name={kpi.icon} />
                <Typography variant="h5">{kpi.value}</Typography>
              </>
              )}
              {loading && <ProgressIndicator size={32} />}
            </FlexBox>
          </FlexBox>
        ))}
      </FlexBox>
    </>
  );
};

export default KpiList;
