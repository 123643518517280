import { useStoreActions } from 'easy-peasy';

const useHandleLogout = () => {
  const { resetStoreMain } = useStoreActions((state) => state.main);
  const { resetStoreSales } = useStoreActions((state) => state.sales);
  const { resetStoreAbandonedOrders } = useStoreActions((state) => state.abandonedOrders);
  const { resetStoreVisits } = useStoreActions((state) => state.visits);
  const { resetStoreCoupons } = useStoreActions((state) => state.coupons);
  const { resetStoreCustomers } = useStoreActions((state) => state.customers);

  const resetAllStore = () => {
    resetStoreAbandonedOrders();
    resetStoreVisits();
    resetStoreMain();
    resetStoreSales();
    resetStoreCoupons();
    resetStoreCustomers();
  };

  const clearStorage = () => {
    localStorage.clear();
    sessionStorage.clear();
  };

  return { resetAllStore, clearStorage };
};

export default useHandleLogout;
