import React from 'react';
import Search from './Search';
import Card from '../../components/card/Card';
import SalesTable from './SalesTable';

const SalesData = ({ reloadFunction }) => (
  <Card>
    <Search />
    <SalesTable reloadFunction={reloadFunction} />
  </Card>
);

export default SalesData;
