import React from 'react';
import Typography from '../typography/Typography';
import FlexBox from '../flex-box/FlexBox';

const DualText = ({ text1, text2 }) => (
  <FlexBox alignItems="center">
    <Typography fontWeight="500" m="5px 5px 5px 0">{text1}</Typography>
    <Typography>{text2}</Typography>
  </FlexBox>
);

export default DualText;
