import React from 'react';
import StyledSelect from './Select.styles';

const Select = ({ children, ...props }) => (
  <StyledSelect
    {...props}
  >
    {children}
  </StyledSelect>
);

export default Select;
