import React, { useState, useContext, useEffect } from 'react';
import {
  TableSortLabel,
  makeStyles,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TableContainer,
} from '@naturacosmeticos/natds-web';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom';

import Intro from '../intro/Intro';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import { I18nContext } from '../../config/i18n';
import Typography from '../typography/Typography';
import FlexBox from '../flex-box/FlexBox';
import { pushInteraction } from '../../data-layer/interaction';
import { getPageName } from '../../data-layer/modeling';
import { exportData } from '../../utils/export-utils';
import ProgressIndicator from '../progress-indicator/ProgressIndicator';

const useStyles = makeStyles((theme) => ({
  hideBorderBottom: {
    borderBottom: 'none !important',
  },
  spacing: {
    maxHeight: '400px',
    overflowY: 'auto',
  },
  table: {
    overflowY: 'auto',
    border: '0px!important',
    '& tr:last-child': {
      '& td': {
        borderBottom: ({ tableDetails }) => tableDetails && 'none !important',
      },
    },
    '& td': {
      borderBottom: `1px solid ${theme.color.lowEmphasis}!important`,
      padding: `${theme.sizes.small}px`,
    },
    '& th': {
      borderBottom: `1px solid ${theme.color.neutral300}!important`,
      padding: `${theme.sizes.small}px`,
    },
    width: '100%',
  },
  tableBodyDescription: {
    wordBreak: 'break-all',
  },
  tablePagination: {
    display: 'flex',
    justifyContent: 'end',
    paddingTop: '20px !important',

    '& .MuiInputBase-input': {
      minHeight: '0px',
      lineHeight: '0px',
    },
  },
  backgroundColorTableDetails: {
    backgroundColor: `${theme.color.neutral100} !important`,
    '& table': {
      backgroundColor: 'transparent',
      '& tr': {
        backgroundColor: 'transparent !important',
      },
      '& th': {
        backgroundColor: 'transparent !important',
      },
    },
    '& td': {
      backgroundColor: 'transparent !important',
    },
  },
}));

const TableComponent = ({
  dataset = [],
  columns = [],
  dataTableConfig = {},
  title, titleDetail,
  linkBtn,
  button,
  fileName,
  tableDetails = false,
  loading = true,
}) => {
  const classes = useStyles({ tableDetails });
  const location = useLocation();
  const history = useHistory();
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const [columnsState, setColumnsState] = useState(columns);
  const [datasetState, setDatasetState] = useState(dataset);
  const [pageChange, setPageChange] = useState();
  const [rowsPerPage] = useState(tableDetails ? 9999 : 10);
  const [numberCurrencyPage, setNumberCurrencyPage] = useState(1);
  const [elementsPage, setElementsPage] = useState([]);
  const [totalPages, setTotalPages] = useState(0);

  function handleExport() {
    if (dataTableConfig.columnsDetail && dataTableConfig.datasetDetail) {
      pushInteraction('click', `${getPageName(location.pathname)} page`, 'export excel');
      exportData(dataTableConfig.datasetDetail, fileName, dataTableConfig.columnsDetail);
      return;
    }
    pushInteraction('click', `${getPageName(location.pathname)} page`, 'export excel');
    exportData(dataset, fileName, columns);
  }

  function handleClick() {
    pushInteraction('navigation', `${getPageName(location.pathname)} page`, 'create coupon');
    history.push(linkBtn.url);
  }

  useEffect(() => {
    setColumnsState(columns);
  }, [columns]);

  const gotoPage = (page) => {
    const listSize = datasetState.length;
    if (listSize > 0) {
      let totalPagesCalc = (Math.ceil(listSize / rowsPerPage));
      totalPagesCalc = totalPagesCalc === 0 ? 1 : totalPagesCalc;
      setTotalPages(totalPagesCalc);

      if (page <= totalPagesCalc) {
        const start = ((page - 1) * rowsPerPage);
        const end = rowsPerPage * page;
        const realEnd = (end >= listSize) ? listSize : end;
        setElementsPage(datasetState.slice(start, realEnd));
      }
    }
  };

  const TableBodyComponent = ({ row = {} }) => {
    const classesBody = useStyles({ showMoreDetails: dataTableConfig.showMoreDetails });
    const [rowState, setRowState] = useState({ ...row, showDetails: false });

    useEffect(() => {
      setRowState({ ...rowState, showDetails: false });
    }, [pageChange]);

    return (
      <>
        <TableRow>
          {columnsState.map((column, index) => (
            <TableCell
              className={classesBody.tableBodyDescription}
              key={`TableCell${index}`}
            >
              <Typography variant="body2" key={`Typography${index}`}>
                {column.renderCell && column.renderCell({ row })}
              </Typography>
            </TableCell>
          ))}
          {dataTableConfig.showMoreDetails && (
            <TableCell align="center">
              {dataTableConfig.componentButtonMoreDetails({
                click: () => {
                  setRowState({
                    ...rowState,
                    showDetails: !rowState.showDetails,
                  });
                },
                activedRow: rowState.showDetails,
              })}
            </TableCell>
          )}
        </TableRow>
        {rowState.showDetails && (
          <TableRow className={classesBody.backgroundColorTableDetails}>
            <TableCell colSpan="9999">
              {dataTableConfig.componentMoreDetails(rowState)}
            </TableCell>
          </TableRow>
        )}
      </>
    );
  };

  const sortData = (column) => {
    const dataToSort = [...datasetState];
    const sortDescription = {
      ASC: 'ASC',
      DESC: 'DESC',
    };

    const getSort = column.directionSort
    && column.directionSort.toLocaleUpperCase() === sortDescription.DESC ? sortDescription.ASC : sortDescription.DESC;

    if (getSort === sortDescription.DESC) {
      setDatasetState(
        dataToSort.sort((a, b) => a[column.field].toString().localeCompare(b[column.field].toString(), undefined, {
          numeric: true,
          sensitivity: 'base',
        })),
      );
    } else if (getSort === sortDescription.ASC) {
      setDatasetState(
        dataToSort.sort((a, b) => b[column.field].toString().localeCompare(a[column.field].toString(), undefined, {
          numeric: true,
          sensitivity: 'base',
        })),
      );
    }

    const columnIndexOf = columnsState.indexOf(columnsState.find((e) => e === column));
    if (columnIndexOf !== -1) {
      const columnsStateLocale = [...columnsState.map((e, index) => {
        if (columnIndexOf === index) {
          return e;
        }
        return {
          ...e,
          directionSort: undefined,
        };
      })];

      columnsStateLocale[columnIndexOf].directionSort = getSort.toLocaleLowerCase();
      setColumnsState(columnsStateLocale);
    }
    setNumberCurrencyPage(1);
  };

  useEffect(() => {
    gotoPage(numberCurrencyPage);
  }, [numberCurrencyPage, datasetState]);

  useEffect(() => {
    setDatasetState(dataset);
  }, [dataset]);

  const mountPagination = () => {
    if (dataTableConfig.showPagination) {
      return (
        <>
          <FlexBox p="10px 0px" flexDirection="row" alignItems="center" justifyContent="end" flexWrap="nowrap">
            <FlexBox justifyContent="end">
              <Typography m="10px 3px">
                {numberCurrencyPage}
              </Typography>
              <Typography m="10px 3px">
                {translate('table.description_link_between_page')}
              </Typography>
              <Typography m="10px 3px">
                {totalPages}
              </Typography>
            </FlexBox>

            <Button
              minWidth="0px"
              size="small"
              onClick={() => {
                setNumberCurrencyPage(numberCurrencyPage - 1);
                setPageChange(new Date().getTime());
              }}
              disabled={numberCurrencyPage <= 1}
            >
              <Icon
                size="tiny"
                name="outlined-navigation-arrowright"
              />
            </Button>
            <Button
              minWidth="0px"
              size="small"
              onClick={() => {
                setNumberCurrencyPage(numberCurrencyPage + 1);
                setPageChange(new Date().getTime());
              }}
              disabled={numberCurrencyPage >= totalPages}
            >
              <Icon
                size="tiny"
                name="outlined-navigation-arrowleft"
              />
            </Button>
          </FlexBox>
        </>
      );
    }
  };

  return (
    <>
      {!tableDetails && (
        <FlexBox
          alignItems="center"
          flexDirection={isMobile && 'column'}
          justifyContent="space-between"
          p="16px 0 5px 0"
        >
          {title && (
          <FlexBox>
            <Intro
              title={title}
              detail={titleDetail}
            />
          </FlexBox>
          )}
          <FlexBox
            p="5px 15px"
            justifyContent={dataTableConfig.earningText ? 'space-between' : 'flex-end'}
            gap="20px"
            alignItems="center"
          >
            {dataTableConfig.earningText && (
            <Typography p="0 10px" style={{ flex: 1 }} variant="h6" fz="17px">
              {translate('earnings_calendar.table.detalhes-text')}
            </Typography>
            )}
            {linkBtn && (
            <Button style={{ marginRight: '10px', minWidth: '170px' }} variant="contained" onClick={handleClick}>
              {translate(linkBtn.titleTag)}
            </Button>
            )}
            {button}
            {fileName && (
            <Button
              style={{ minWidth: '170px' }}
              variant="contained"
              onClick={handleExport}
            >
              {translate('default_export_excel_button')}
            </Button>
            )}
          </FlexBox>
        </FlexBox>
      )}
      <FlexBox m="15px" flexDirection="column" maxWidth="-webkit-fill-available">
        <TableContainer className={classes.table}>
          <Table dividers={false} size="small" className={classes.table} padding="none">
            <TableHead>
              <TableRow>
                {columnsState.map((e) => (
                  <TableCell style={{ minWidth: e.minWidth, width: e.width, flex: e.flex }}>
                    {e.sortable ? (
                      <TableSortLabel
                        active
                        direction={e.directionSort}
                        style={{ minWidth: e.minWidth, width: e.width, flex: e.flex }}
                        onClick={() => sortData(e)}
                        onFocusVisible={function noRefCheck() {}}
                      >
                        <Typography variant="subtitle1">
                          {e.headerName}
                        </Typography>
                      </TableSortLabel>
                    ) : (
                      <Typography variant="subtitle1">
                        {e.headerName}
                      </Typography>
                    )}
                  </TableCell>
                ))}
                {dataTableConfig.showMoreDetails && (
                <TableCell align="center" />
                )}
              </TableRow>
            </TableHead>
            <TableBody>
              {loading ? (
                <TableRow className={classes.backgroundColorTableDetails}>
                  <TableCell colSpan="9999">
                    <FlexBox alignItems="center" justifyContent="center" height="70px">
                      <ProgressIndicator size={24} />
                    </FlexBox>
                  </TableCell>
                </TableRow>
              ) : (
                <>
                  {elementsPage && elementsPage.length > 0 ? (
                    elementsPage.map((record, index) => (
                      <TableBodyComponent
                        pageChange={pageChange}
                        dataTableConfig={dataTableConfig}
                        row={record}
                        key={index}
                        columns={columnsState}
                      />
                    ))
                  ) : (
                    <TableRow className={classes.backgroundColorTableDetails}>
                      <TableCell colSpan="9999">
                        <FlexBox alignItems="center" justifyContent="center" height="70px">
                          <Typography>
                            {translate('no-data-table')}
                          </Typography>
                        </FlexBox>
                      </TableCell>
                    </TableRow>
                  )}
                </>
              )}

            </TableBody>
          </Table>
        </TableContainer>
        {mountPagination()}
      </FlexBox>
    </>
  );
};

export default TableComponent;
