import React from 'react';
import StyledDrawerBody from './DrawerBody.styles';

const DrawerBody = ({ children, ...props }) => (
  <StyledDrawerBody
    {...props}
  >
    {children}
  </StyledDrawerBody>
);

export default DrawerBody;
