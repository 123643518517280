import { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import config from '../config/config';

const useHandleAuth = () => {
  const ssoToken = sessionStorage.getItem('sso_token');
  const { personId } = useStoreState((state) => state.main);
  const {
    handleLogin, handleNaturaLogin,
  } = useStoreActions((actions) => actions.main);

  useEffect(() => {
    if (ssoToken && !personId) {
      if (config.auth.loginConfig === 'natura_login') {
        handleNaturaLogin();
      } else {
        handleLogin();
      }
    }
  }, []);

  return !!ssoToken;
};

export default useHandleAuth;
