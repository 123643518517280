import React, { useContext } from 'react';
import { I18nContext } from '../../config/i18n';
import Typography from '../typography/Typography';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import DialogTitle from '../dialog-title/DialogTitle';
import DialogContent from '../dialog-content/DialogContent';
import DialogActions from '../dialog-actions/DialogActions';

const ReactivationModal = ({ openModal = false, message = '', toClose }) => {
  const { translate } = useContext(I18nContext);

  return (
    <>
      <Dialog
        maxWidth="sm"
        onEscapeKeyDown={toClose}
        open={openModal}
      >
        <DialogTitle>
          <Typography>
            {message === 'error' ? translate('error-modal-title') : translate('success-modal-title')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {message === 'error' ? (
              <>
                {' '}
                {translate('error-reactivation-modal')}
                <a href="https://www.naturacosmeticos.com.ar/servicio-atencion-clientes">
                  {' '}
                  {translate('error-reactivation-modal-call-center')}
                </a>
              </>
            ) : translate('success-reactivation-modal')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={toClose}
            variant="text"
          >
            {translate('error-modal-close-button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReactivationModal;
