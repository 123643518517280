import React, { useContext } from 'react';

import { I18nContext } from '../../../config/i18n';
import FlexBox from '../../flex-box/FlexBox';
import Typography from '../../typography/Typography';
import Icon from '../../icon/Icon';
import Button from '../../button/Button';

const ButtonsAlert = ({ closeDropDown, alertAtived, setAlertAtived }) => {
  const { translate } = useContext(I18nContext);

  return (
    <>
      <FlexBox
        m="10px"
        flexDirection="row"
        justifyContent="space-between"
        maxWidth="-webkit-fill-available"
        alignItems="center"
      >
        <Typography variant="h6" m="0 auto">{translate('alerts.header')}</Typography>
        <Icon
          size="micro"
          name="outlined-navigation-close"
          onClick={closeDropDown}
          style={{ cursor: 'pointer' }}
        />
      </FlexBox>
      <FlexBox flexDirection="row" gap="4px">
        <Button
          onClick={() => setAlertAtived('announcements')}
          width="100%"
          m="10px 3px 10px 10px"
          variant="contained"
          p="20px"
          color={alertAtived === 'announcements' ? 'primary' : 'default'}
          startIcon={<Icon size="tiny" name="outlined-communication-speaker" />}
        >
          <Typography p="0px">{translate('alerts.announcements')}</Typography>
        </Button>
        <Button
          onClick={() => setAlertAtived('notifications')}
          color={alertAtived === 'notifications' ? 'primary' : 'default'}
          width="100%"
          variant="contained"
          m="10px 10px 10px 2px"
          p="20px"
          startIcon={<Icon size="tiny" name="outlined-alert-notification" />}
        >
          <Typography p="0px">{translate('alerts.notifications')}</Typography>
        </Button>
      </FlexBox>
    </>
  );
};

export default ButtonsAlert;
