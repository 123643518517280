import * as XLSX from 'xlsx';

export function exportData(data, fileName = 'data', columns) {
  const columnsToExport = [
    [...columns.map((e) => [e.headerName])],
    ...data.map((row) => {
    // eslint-disable-next-line array-callback-return
      const newRow = columns.map((column) => {
        if (column.exportValue) {
          return column.exportValue({ row });
        }
      });
      return newRow;
    }),
  ];

  const workbook = XLSX.utils.book_new();
  const worksheet = XLSX.utils.aoa_to_sheet(columnsToExport);
  XLSX.utils.book_append_sheet(workbook, worksheet, 'SheetJS');
  XLSX.writeFile(workbook, `${fileName}.xlsx`);
}
