import { pushDataLayerError } from './reportError';

export const pushRegisterCoupon = (payload) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'registerCoupon',
      code: payload.couponName,
      discountPercentage: payload.cuponPercent,
      initialDate: payload.initialDate.toDateString(),
      finalDate: payload.finalDate.toDateString(),
      quantity: payload.quantity,

    });
  } catch (e) {
    pushDataLayerError('coupon', e);
  }
};
