import React, { useContext, useState, useEffect } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import FlexBox from '../../components/flex-box/FlexBox';
import Page from '../../components/page/Page';
import Kpi from './Kpi';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import CouponsTable from './CouponsTable';

function Coupons() {
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const {
    fetchCouponsSummary,
    setLoading,
    addOriginalData,
    fetchCouponsByStatus,
  } = useStoreActions((actions) => actions.coupons);
  const { customerNo } = useStoreState((state) => state.main);

  const {
    originalData = { couponActive: 0, couponExpired: 0 },
    error,
    loadingSummary,
  } = useStoreState((state) => state.coupons);
  useEffect(() => {
    if (customerNo) {
      fetchCouponsSummary({ customerNo });
      fetchCouponsByStatus({ customerNo, status: 'active' });
    }
  }, [customerNo]);

  useEffect(() => {
    if (!customerNo) {
      setLoading(false);
      addOriginalData({
        couponActive: 0,
        couponExpired: 0,
        couponEnableList: [],
        couponUsed: 0,
      });
    }
  }, [error]);

  const kpis = [
    {
      textKey: 'coupons.kpis.active_coupons',
      values: [originalData.couponActive],
      color: 'rgb(86, 154, 50)',
      activeColor: 'rgb(86, 154, 50, 0.2)',
      activeKey: 'active',
    },
    {
      textKey: 'coupons.kpis.inactive_coupons',
      values: [originalData.couponExpired],
      color: 'rgb(220, 58, 55)',
      activeColor: 'rgb(220, 58, 55, 0.2)',
      activeKey: 'inative',
    },
    {
      textKey: 'coupons.kpis.used_coupons',
      values: [originalData.couponUsed],
      color: 'rgb(118, 118, 118)',
      activeColor: 'rgb(118, 118, 118, 0.2)',
      activeKey: 'used',
    },
  ];

  return (
    <Page>
      <FlexBox flexDirection="column">
        <FlexBox>
          <Typography p="0 10px" variant="h6">{translate('coupons.title')}</Typography>
        </FlexBox>
        <FlexBox flexDirection={isMobile ? 'column' : false}>
          {kpis && kpis.map(({
            textKey, values, color, activeKey, activeColor,
          }) => (
            <Kpi
              activeKey={activeKey}
              key={textKey + values}
              activeColor={activeColor}
              textKey={textKey}
              values={values}
              color={color}
              loading={loadingSummary}
            />
          ))}
        </FlexBox>
        <FlexBox>
          <CouponsTable />
        </FlexBox>
      </FlexBox>
    </Page>
  );
}

export default Coupons;
