import React, { useState } from 'react';
import Card from '../../components/card/Card';
import CalendarFilter from './CalendarFilter';
import LineChart from './LineChart';
import PieChart from './PieChart';
import FlexBox from '../../components/flex-box/FlexBox';

const Charts = ({ dataBase, loading }) => {
  const [isMobile] = useState(window.innerWidth < 1080);
  return (
    <Card>
      <CalendarFilter />
      <FlexBox flexDirection={isMobile && 'column'} margin="20px 0px 0px 0px">
        <LineChart loading={loading} dataBase={dataBase} isMobile={isMobile} />
        <PieChart loading={loading} dataBase={dataBase} isMobile={isMobile} />
      </FlexBox>
    </Card>
  );
};

export default Charts;
