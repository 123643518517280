import React from 'react';
import StyledPage from './Page.styles';

const Page = ({ children, ...props }) => (
  <StyledPage props={props}>
    {children}
  </StyledPage>
);

export default Page;
