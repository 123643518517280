import React, { useContext } from 'react';
import { useStoreState } from 'easy-peasy';
import { Rating } from '@naturacosmeticos/natds-web';

import FlexBox from '../../components/flex-box/FlexBox';
import Card from '../../components/card/Card';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';

function Evaluation() {
  const { translate } = useContext(I18nContext);
  const {
    evaluationRate,
    evaluationAmount,
  } = useStoreState((state) => state.main);

  return (
    <Card>
      <FlexBox m="0 0 10px 0" alignItems="center">
        <Typography variant="h5" color="primary">{translate('profile.digital_space.evaluation_average')}</Typography>
      </FlexBox>
      <FlexBox alignItems="center">
        <Rating size="standard" rate={evaluationRate} style={{ margin: '0px 0px 5px 0px' }} />
        <FlexBox flexDirection="column" width="auto" alignItems="center" p="0px 20px">
          {evaluationRate && (
            <Typography>
              {evaluationRate}
            </Typography>
          )}
          {evaluationAmount && (
            <Typography>
              (
              {evaluationAmount}
              )
            </Typography>
          )}
        </FlexBox>
      </FlexBox>
      <FlexBox flexDirection="column">
        <Typography
          variant="h6"
          m="15px 0 10px 0"
          color="textSecondary"
        >
          {translate('profile.digital_space.evaluation_average_description')}

        </Typography>
      </FlexBox>
    </Card>
  );
}

export default Evaluation;
