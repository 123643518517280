import React from 'react';
import StyledIntro from './Intro.styles';

const Intro = ({ children, ...props }) => (
  <StyledIntro
    {...props}
  >
    {children}
  </StyledIntro>
);

export default Intro;
