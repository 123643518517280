import { action, thunk } from 'easy-peasy';
import visitFetcher from '../../api/visitFetcher';
import { generateHash } from '../../utils/encrypt-utils';

const initialState = {
  visits: {},
  originalData: {},
  loading: true,
  openModal: false,
  error: false,
  search: null,
};

const visitsModel = {
  ...initialState,
  resetStoreVisits: action((state) => {
    Object.assign(state, initialState);
  }),
  addVisits: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.visits = payload;
    // eslint-disable-next-line no-param-reassign
    state.loading = false;
  }),
  toggleModal: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.openModal = !state.openModal;
  }),
  setSearch: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.search = payload;
  }),
  addOriginalData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.originalData = payload;
  }),
  setLoading: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loading = payload;
  }),
  setError: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.error = payload;
  }),
  // eslint-disable-next-line no-unused-vars
  fetchVisits: thunk(async (actions, payload) => {
    actions.setLoading(true);
    const { endDate, startDate, personId } = payload;
    actions.setError(false);
    // eslint-disable-next-line max-len
    visitFetcher().get(`cn-data/${personId}/reports?startDate=${startDate}&endDate=${endDate}`, {
      headers: {
        personId,
        cnId: generateHash(personId),
      },
    }).json()
      .then((data) => {
        const dataAdjusted = {
          conversionRate: data.conversionRate ? data.conversionRate : 0,
          orders: data.orders ? data.orders : 0,
          sourceMedium: data.sourceMedium ? data.sourceMedium : [],
          users: data.newUsers ? data.newUsers : 0,
          visits: data.users ? data.users : 0,
          visitsPerWeek: data.visitsPerWeek ? data.visitsPerWeek : 0,
        };
        actions.addOriginalData(dataAdjusted);
        actions.setLoading(false);
        actions.addVisits(dataAdjusted);
      })
      .catch((err) => {
        actions.addOriginalData([]);
        actions.setLoading(false);
        actions.addVisits([]);
        console.error('error: -> ', err.message);
      });
  }),

};
export default visitsModel;
