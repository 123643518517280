import { Container } from '@naturacosmeticos/natds-web';
import { styled } from '@material-ui/core/styles';

const StyledContainer = styled(Container)({
  display: ({ props }) => props.display || 'flex',
  padding: ({ props }) => props.padding || props.p || '0',
  flexWrap: ({ props }) => props.flexWrap,
  margin: ({ props }) => props.margin || props.m || '0',
  height: ({ props }) => props.height,
  maxWidth: ({ props }) => props.maxWidth || props.mw || 'unset',
  minWidth: ({ props }) => props.minWidth,
  overflow: ({ props }) => props.overflow,
  width: ({ props }) => props.w || props.width,
  minHeight: ({ props }) => props.minHeight,
  flexDirection: ({ props }) => props.flexDirection || 'unset',
  alignItems: ({ props }) => props.alignItems || 'unset',
  flex: ({ props }) => props.flex || 'unset',
  color: ({ props }) => props.color || props.c,
  justifyContent: ({ props }) => props.justifyContent || 'unset',
  zIndex: ({ props }) => props.zIndex,
  position: ({ props }) => props.position,
  backgroundColor: ({ props }) => props.backgroundColor,
  border: ({ props }) => props.border,
  borderRadius: ({ props }) => props.borderRadius,
  borderRight: ({ props }) => props.borderRight,
  borderLeft: ({ props }) => props.borderLeft,
  borderBottom: ({ props }) => props.borderBottom,
  borderTop: ({ props }) => props.borderTop,
  whiteSpace: ({ props }) => props.whiteSpace,
  backgroundImage: ({ props }) => props.backgroundImage && `url(${props.backgroundImage})`,
  backgroundSize: 'cover',
  backgroundRepeat: 'no-repeat',
  textAlign: ({ props }) => props.textAlign,
  gap: ({ props }) => props.gap,
  top: ({ props }) => props.top,
  '@media (max-width: 1145px)': {
    flexDirection: ({ props }) => props.mediaQ && 'column',
    borderRight: ({ props }) => props.mediaQ === 'totalGain' && '0',
    margin: ({ props }) => props.mediaQ === 'totalGain' && '0',
    borderBottom: ({ props }) => props.mediaQ === 'totalGain' && '1px solid rgb(187, 187, 187)',
    padding: ({ props }) => props.mediaQ === 'totalGain' && '10px 0',
  },
});

export default StyledContainer;
