import { makeStyles } from '@naturacosmeticos/natds-web';

export const couponsTableStyle = makeStyles(() => ({
  textField: {
    '& .MuiFormControl-root': {
      flex: '1',
      width: '100%',
    },
  },
}));
