import React from 'react';
import StyledDialogActions from './DialogActions.styles';

const DialogActions = ({ children, ...props }) => (
  <StyledDialogActions
    {...props}
  >
    {children}
  </StyledDialogActions>
);

export default DialogActions;
