import { styled } from '@material-ui/core/styles';

const StyledLabel = styled('label')(({ theme }) => ({
  padding: '10px 0',
  display: 'flex',
  alignItens: 'center',
  justifyContent: 'center',
  backgroundColor: theme.color.primary,
  borderRadius: '3px',
  color: theme.color.onPrimary,
  textTransform: 'uppercase',
  fontFamily: theme.typography.fontFamily,
  fontWeight: theme.typography.fontWeightMedium,
  marginTop: '5px',
  cursor: 'pointer',
  transition: 'background-color 250ms',
  '&:hover': {
    backgroundColor: theme.color.primaryDark,
  },
}));

export default StyledLabel;
