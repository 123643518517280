import React from 'react';
import StyledLabel from './Label.styles';

const InputFile = ({ children, ...props }) => (
  <form method="post" encType="multipart/form-data">
    {/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
    <StyledLabel htmlFor={props.idElement}>{props.text}</StyledLabel>
    <input
      type="file"
      hidden
      name="ImageCn"
      accept="image/jpeg"
      id={props.idElement}
      {...props}
    />
  </form>
);

export default InputFile;
