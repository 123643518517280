import React, {
  useCallback, useState, useRef, useEffect,
} from 'react';
import { Badge, makeStyles } from '@naturacosmeticos/natds-web';
import { useStoreState } from 'easy-peasy';

import Icon from '../../icon/Icon';
import ButtonsAlert from './ButtonsAlert';
import ContentAlert from './ContentAlert';
import FlexBox from '../../flex-box/FlexBox';
import Card from '../../card/Card';
import config from '../../../config/config';

const useStyles = makeStyles((theme) => ({
  codeSpan: {
    fontSize: '10px',
  },
  popOver: {
    top: '46px',
    right: (props) => (props.isMobile ? '-99px' : '-116px'),
    zIndex: '1302',
    position: 'absolute',
    minWidth: (props) => (props.isMobile ? '' : '350px'),
    boxShadow: '0 12px 17px 0 rgb(0 0 0 / 14%)',
    animation: '$myEffect 1000ms',
    width: (props) => (props.isMobile ? 'calc(100vw - 10px)' : 'auto'),
    maxWidth: (props) => (props.isMobile ? '400px' : 'auto'),

    '&::before': {
      position: 'absolute',
      transform: 'rotate(45deg)',
      height: '10px',
      width: '10px',
      content: "''",
      borderTop: '1px solid rgb(187, 187, 187)',
      borderLeft: '1px solid rgb(187, 187, 187)',
      top: '-5px',
      right: (props) => props.boundsLeftBadge,
      background: theme.color.surface,
    },
  },
  '@keyframes myEffect': {
    '0%': {
      opacity: 0,
      transform: 'translateY(-10px)',
    },
    '100%': {
      opacity: 1,
      transform: 'translateY(0)',
    },
  },
  hide: {
    display: 'none',
  },
  hideButton: {
    position: 'fixed',
    backgroundColor: 'transparent',
    border: 'none',
    top: '0',
    left: '0',
    right: '0',
    height: '100%',
    width: '100%',
    zIndex: '1301',
  },
}));

const Alerts = () => {
  const [isMobile] = useState(window.innerWidth < 1080);
  const [shoDropDown, setShoDropDown] = useState(false);
  const [alertAtived, setAlertAtived] = useState('announcements');
  const [dataAlertState, setDataAlertState] = useState([]);
  const [boundsLeftBadge, setBoundsLeftBadge] = useState();
  const { dataAlert = [] } = useStoreState((state) => state.main);
  const badgeRef = useRef();
  const classes = useStyles({ boundsLeftBadge, isMobile });
  const isNatura = config.general.company === 'natura';

  useEffect(() => {
    let newAlerts = [];
    if (alertAtived === 'announcements') {
      newAlerts = dataAlert.filter((e) => e.titleText === 'announcements');
    } else if (alertAtived === 'notifications') {
      newAlerts = dataAlert.filter((e) => e.titleText === 'notifications');
    }
    newAlerts = newAlerts.map((e) => {
      if (e.descriptionText
        && e.descriptionText.toLowerCase().includes('conteudo')
        && e.descriptionText.toLowerCase().includes('link')) {
        const arraySplit = e.descriptionText.split('link');
        return {
          descriptionText: arraySplit[0].substring(arraySplit[0].indexOf('{') + 1, arraySplit[0].indexOf('}')),
          link: arraySplit[1].substring(arraySplit[1].indexOf('{') + 1, arraySplit[1].indexOf('}')),
          titleText: e.titleText,
        };
      }
      return e;
    });

    setDataAlertState(newAlerts);
  }, [dataAlert, alertAtived]);

  useEffect(() => {
    if (badgeRef && badgeRef.current && badgeRef.current.getBoundingClientRect()) {
      const diffToMobile = isMobile ? -2 : 20;
      const calcBadge = window.innerWidth - badgeRef.current.getBoundingClientRect().right - diffToMobile;
      setBoundsLeftBadge(`${calcBadge}px`);
    }
  }, [shoDropDown]);

  const closeDropDown = useCallback(() => {
    setShoDropDown(false);
  }, []);

  return (
    <>
      {isNatura && (
        <div style={{ position: 'relative' }}>
          <Badge
            style={{ marginRight: '15px', cursor: 'pointer' }}
            anchorOrigin={{
              horizontal: 'right',
              vertical: 'top',
            }}
            badgeContent="999"
            color="primary"
            max={dataAlert.length}
            overlap="circle"
            variant="standard"
            onClick={() => setShoDropDown({ active: !shoDropDown })}
            ref={badgeRef}
          >
            <Icon name="filled-content-world" size="tiny" />
          </Badge>
          <div className={shoDropDown ? classes.popOver : classes.hide}>
            <Card m="0" p="0">
              <FlexBox flexDirection="column" gap="4px">
                <ButtonsAlert
                  closeDropDown={closeDropDown}
                  alertAtived={alertAtived}
                  setAlertAtived={setAlertAtived}
                />
                <ContentAlert dataAlert={dataAlertState} />
              </FlexBox>
            </Card>
          </div>
          {shoDropDown && (
          <button
            className={classes.hideButton}
            type="button"
            onClick={closeDropDown}
          />
          )}
        </div>
      )}
    </>
  );
};

export default Alerts;
