import React, { useContext } from 'react';
import { I18nContext } from '../../config/i18n';
import Typography from '../typography/Typography';
import Button from '../button/Button';
import Dialog from '../dialog/Dialog';
import DialogTitle from '../dialog-title/DialogTitle';
import DialogContent from '../dialog-content/DialogContent';
import DialogActions from '../dialog-actions/DialogActions';

const BlocklistModal = ({ openModal = false, toClose }) => {
  const { translate } = useContext(I18nContext);

  return (
    <>
      <Dialog
        maxWidth="sm"
        onEscapeKeyDown={toClose}
        open={openModal}
      >
        <DialogTitle>
          <Typography>
            {translate('blocklist-modal-title')}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <Typography>
            {translate('blocklist-modal-text')}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            onClick={toClose}
            variant="text"
          >
            {translate('forgotPassword.modal.button')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default BlocklistModal;
