import React, { useContext, useState } from 'react';
import { useStoreState } from 'easy-peasy';
import { IconButton } from '@naturacosmeticos/natds-web';

import Icon from '../../components/icon/Icon';
import FlexBox from '../../components/flex-box/FlexBox';
import Card from '../../components/card/Card';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import DualText from '../../components/dual-text/DualText';
import Address from './Address';

function PersonAddress() {
  const { translate } = useContext(I18nContext);
  const [isMobile] = useState(window.innerWidth < 1080);
  const [modeEditAddress, setModeEditAddress] = useState(false);
  const { personAddress } = useStoreState((state) => state.main);

  return (
    <Card>
      <FlexBox justifyContent="space-between">
        <Typography m="0 0 10px 0" variant="h5" color="primary">
          {modeEditAddress ? (
            translate('profile.edit_address_title')
          ) : (
            translate('profile.address_title')
          )}
        </Typography>
        {false && (
          <IconButton
            color="default"
            size="medium"
            onClick={() => setModeEditAddress(!modeEditAddress)}
          >
            <Icon
              name={modeEditAddress ? 'outlined-navigation-close' : 'outlined-action-edit'}
              size="tiny"
            />
          </IconButton>
        )}
      </FlexBox>
      <FlexBox flexDirection={isMobile && 'column'}>
        {!modeEditAddress ? (
          <>
            {
          personAddress && personAddress.length > 0 && (
            <FlexBox flexDirection="column">
              <DualText
                text1={translate('profile.address')}
                text2={personAddress[0].location}
              />
              <DualText
                text1={translate('profile.postal_code')}
                text2={personAddress[0].zipCode}
              />
            </FlexBox>
          )
        }
          </>
        ) : (
          <>
            <Address cancelEdit={() => setModeEditAddress(false)} />
          </>
        )}

      </FlexBox>
    </Card>
  );
}

export default PersonAddress;
