import React, {
  useContext, useState, useEffect,
} from 'react';
import EmojiAware from 'emoji-aware';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useTheme } from '@material-ui/styles';
import { Controller } from 'react-hook-form';
import { makeStyles } from '@naturacosmeticos/natds-web';

import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import InputFile from '../../components/input-file/InputFile';
import { handleChangeFile } from '../../utils/image-utils';
import TextField from '../../components/text-field/TextField';
import Typography from '../../components/typography/Typography';
import Icon from '../../components/icon/Icon';
import Button from '../../components/button/Button';
import { required } from '../../utils/form-utils';
import { handlePhoneContries, removeMaskPhone } from '../../utils/numbers-utils';
import config from '../../config/config';

const handlePhone = handlePhoneContries[config.general.country];
const DDI = config.general.DDIOfContry;

const custonStyle = makeStyles(() => ({
  textField: {
    '& .MuiInputBase-root': {
      height: 'auto',
    },
  },
}));

const DigitalSpaceData = ({
  control, formState, setValue, setError, getStatusInput, clearErrors,
}) => {
  const theme = useTheme();
  const color = theme.color.neutral400;
  const [isMobile] = useState(window.innerWidth < 1080);
  const [openEditPhoneWhats, setOpenEditPhoneWhats] = useState(false);
  const [image, setImage] = useState();
  const { translate } = useContext(I18nContext);
  const classes = custonStyle();
  // eslint-disable-next-line max-len
  const maxLengthWhatsappNumber = handlePhone && handlePhone.maxLengthWhatsappNumber ? handlePhone.maxLengthWhatsappNumber : 13;
  const {
    setShowAlert,
    setImageCN,
    setWhatsWappNumber,
    setNewImageCN,
  } = useStoreActions((actions) => actions.main);

  const {
    imageCN, nameTent, introMessage, whatsWappNumber,
  } = useStoreState((actions) => actions.main);

  useEffect(() => {
    if (nameTent) {
      setValue('imputName', nameTent);
    }
  }, [nameTent]);

  useEffect(() => {
    setValue('imputWelcomeMessage', introMessage);
  }, [introMessage]);

  useEffect(() => {
    setValue('imputWhatsappPhone', whatsWappNumber);
  }, [whatsWappNumber]);

  async function handleChange(e) {
    const callbackError = (typeError) => {
      setShowAlert({
        message: translate(typeError ? 'digital_space.invalid_file_type' : 'digital_space.invalid_file_size'),
        type: 'error',
        id: new Date().getTime(),
      });
    };

    const callbackSuccessful = (base64) => {
      setImageCN(base64);
      setNewImageCN(base64);
    };

    handleChangeFile(e, callbackSuccessful, callbackError);
  }

  const getBase64FromUrl = () => {
    if (imageCN) {
      if (imageCN.includes('http://')
          || imageCN.includes('https://')
          || imageCN.includes('images.rede.natura.net')
          || imageCN.includes('s3.amazonaws')) {
        setImage(`${imageCN}?v=${Math.random() * 16}`);
      } else {
        setImage(`data:image/jpeg;base64,/9j/${imageCN}`);
      }
    } else {
      setImage();
    }
  };

  useEffect(() => {
    getBase64FromUrl();
  }, [imageCN]);

  // Função para remover emojis do texto
  const removeEmojis = (text) => {
    const emojiAware = EmojiAware.withoutEmoji(text);
    return emojiAware.join('');
  };
  const handleInputNameChange = (e) => {
    const inputId = e.target.id;
    const valueWithoutEmojis = removeEmojis(e.target.value);
    setValue(inputId, valueWithoutEmojis);
  };

  return (
    <>
      <FlexBox p="0 30px" flexDirection="column">
        <FlexBox borderBottom={`2px solid ${color}`} p="30px 0" flexDirection="column">
          <Typography variant="h6">{translate('digital_space.name_title')}</Typography>
          <Typography m="10px 0">{translate('digital_space.name_label')}</Typography>
          <Controller
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                id="imputName"
                value={field.value}
                state={getStatusInput(field, fieldState)}
                style={{ minHeight: '40px' }}
                label={translate('digital_space.insert_name_label')}
                helpText={fieldState.error && translate(fieldState.error.message)}
                rows={1}
                size="semiX"
                type="text"
                onChange={handleInputNameChange}
              />
            )}
            rules={{
              required: required.message,
            }}
            control={control}
            name="imputName"
          />
        </FlexBox>
        <FlexBox borderBottom={`2px solid ${color}`} p="20px 0" flexDirection="column">
          <Typography m="0 0 10px 0" variant="h6">{translate('digital_space.photo_title')}</Typography>
          <Typography m="10px 0">{translate('digital_space.photo_text1')}</Typography>
          <Typography color="textSecondary">{translate('digital_space.photo_text2')}</Typography>
          <FlexBox flexDirection="column" alignItems="center">
            <FlexBox flexDirection="column" w="200px" borderRadius="5px" overflow="hidden" m="10px 0px">
              {image && (
                <img
                  height="200px"
                  width="200px"
                  src={image}
                  alt="cn"
                />
              )}
              <InputFile
                text={translate('digital_space.change_photo_button')}
                onChange={handleChange}
                idElement="DigitalSpaceData"
              />
            </FlexBox>
            <Typography color="textSecondary">{translate('digital_space.photo_spec1')}</Typography>
            <Typography color="textSecondary">{translate('digital_space.photo_spec2')}</Typography>
          </FlexBox>
        </FlexBox>
        <FlexBox
          p="20px 0"
          m="0 0 20px 0"
          borderBottom={`2px solid ${color}`}
          flexDirection="column"
          className={classes.textField}
        >
          <Typography m="0 0 10px 0" variant="h6">{translate('digital_space.welcome_message_title')}</Typography>
          <Typography m="0 0 10px 0">{translate('digital_space.welcome_message_text1')}</Typography>
          <Typography
            m="0 0 10px 0"
            color="textSecondary"
          >
            {translate('digital_space.welcome_message_text2')}

          </Typography>
          <Controller
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                id="imputWelcomeMessage"
                value={field.value}
                state={getStatusInput(field, fieldState)}
                style={{ minHeight: '200px' }}
                helpText={fieldState.error && translate(fieldState.error.message)}
                multiline
                rows={4}
                maxLength="4000"
                size="semiX"
                type="text"
                onChange={handleInputNameChange}
              />
            )}
            rules={{
              required: required.message,
            }}
            control={control}
            name="imputWelcomeMessage"
          />
        </FlexBox>
        <FlexBox p="0px 0" m="0 0 20px 0" flexDirection="column">
          <div
            aria-hidden
            style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }}
          >
            <Icon
              color="primary"
              name="filled-brand-whatsapp"
              size="tiny"
            />
            <Typography m="0 0px 10px 10px" variant="h6">{translate('digital_space.callme_whatsapp')}</Typography>
          </div>
          { !openEditPhoneWhats ? (
            <>
              {whatsWappNumber && (
              <FlexBox flexDirection="column">

                <FlexBox flexDirection="row">
                  <Icon
                    color="default"
                    name="outlined-action-phone"
                    size="tiny"
                  />
                  <Typography m="0 0px 10px 10px">{translate('digital_space.phone')}</Typography>
                </FlexBox>
                <Typography m="0 0px 20px 35px" whiteSpace="normal">
                  {handlePhone.normalize(whatsWappNumber)}
                </Typography>
              </FlexBox>
              )}
              <Button
                color="primary"
                variant="outlined"
                onClick={() => {
                  setOpenEditPhoneWhats(true);
                  setValue('imputWhatsappPhone', whatsWappNumber || '');
                }}
              >
                <Icon size="tiny" name="outlined-product-pencil" />
                <Typography p="0 0 0 10px">
                  {whatsWappNumber ? translate('digital_space.edit_phone') : translate('digital_space.create_phone')}
                </Typography>
              </Button>
            </>
          ) : (
            <FlexBox
              m="0 0 10px 0"
              alignItems={isMobile ? 'flex-start' : 'end'}
              flexDirection={isMobile ? 'column' : 'row'}
            >
              <FlexBox flexDirection="column" m={isMobile ? '0px' : '0px 20px 0px 0px'}>
                <Controller
                  validate={{
                    positive: (v1) => console.log('v1', v1),
                    lessThanTen: (v2) => console.log('v2', v2),
                  }}
                  render={({ field, fieldState }) => (
                    <>
                      <Typography p="10px 0" color="textSecondary" error={fieldState.error}>
                        {translate(('digital_space.social_tab.whatswapp_phone_label'))}
                      </Typography>
                      <TextField
                        id="imputWhatsappPhone"
                        value={field.value}
                        onChange={(event) => {
                          const valueWithoutMask = removeMaskPhone(event.target.value);
                          const valueLength = event.target.value ? valueWithoutMask.length : 0;
                          if (valueLength <= 2 && event.target.value.toString().substring(0, 2) !== DDI) {
                            if (event.target.value.toString().length <= (maxLengthWhatsappNumber - 2)) {
                              // eslint-disable-next-line no-param-reassign
                              event.target.value = `${DDI}${valueWithoutMask}`;
                            } else {
                              const valueEnd = removeMaskPhone(event.target.value.toString().substring(2, valueLength));
                              // eslint-disable-next-line no-param-reassign
                              event.target.value = `${DDI}${valueEnd}`;
                            }
                          }
                          field.onChange(event);
                        }}
                        state={getStatusInput(field, fieldState)}
                        style={{ minHeight: '40px' }}
                        placeholder={handlePhone.placeholder}
                        helpText={fieldState.error && translate(fieldState.error.message)}
                        rows={1}
                        size="semiX"
                        type="text"
                        onBlur={(e) => {
                          const phoneNumberWithoutMask = removeMaskPhone(e.target.value);
                          if (phoneNumberWithoutMask.length < maxLengthWhatsappNumber) {
                            setError('imputWhatsappPhone', {
                              type: 'manual',
                              message: translate('digital_space.social_tab.whatswapp_phone_invalid'),
                            });
                          } else {
                            clearErrors('imputWhatsappPhone');
                          }
                        }}
                       // eslint-disable-next-line max-len
                        mask={handlePhone.mask || ''}
                      />
                    </>
                  )}
                  rules={{
                    minLength: { value: maxLengthWhatsappNumber, message: required.message },
                  }}
                  control={control}
                  name="imputWhatsappPhone"
                />
              </FlexBox>
              <Button
                width={isMobile ? '100%' : 'max-content'}
                variant="outlined"
                style={{
                  marginBottom: !isMobile && formState.errors.imputWhatsappPhone ? '23px' : 'unset',
                  marginTop: isMobile ? '5px' : '0px',
                }}
                color="primary"
                onClick={() => {
                  setOpenEditPhoneWhats(false);
                  setValue('imputWhatsappPhone', '');
                  clearErrors('imputWhatsappPhone');
                  setWhatsWappNumber('');
                }}
              >
                <Typography p="0px 20px" color="secondary">
                  {translate('digital_space.social_tab.remove')}
                </Typography>
              </Button>
            </FlexBox>
          )}

          <Typography
            m="15px 0 10px 0"
            color="textSecondary"
          >
            {translate('digital_space.enable_phone')}
          </Typography>
        </FlexBox>
      </FlexBox>
    </>
  );
};

export default DigitalSpaceData;
