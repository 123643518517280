import { makeStyles } from '@naturacosmeticos/natds-web';

export const orderStatusCardStyle = makeStyles((theme) => ({
  styledCard: {
    backgroundColor: theme.color.background,
  },
  xCancel: {
    position: 'absolute',
    fontSize: '17px',
    top: '-6px',
    fontFamily: 'monospace',
    fontWeight: '600',
    left: '-6px',
  },
}));
