import { styled } from '@material-ui/core/styles';
import { Container } from '@naturacosmeticos/natds-web';

const StyledBackground = styled(Container)({
  display: 'flex',
  position: 'fixed',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  padding: 0,
  margin: 0,
  maxWidth: '100%',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  zIndex: '1300',
});

export default StyledBackground;
