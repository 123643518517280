import React from 'react';
import StyledIcon from './Icon.styles';

const Icon = ({ children, ...props }) => (
  <StyledIcon
    {...props}
  >
    {children}
  </StyledIcon>
);

export default Icon;
