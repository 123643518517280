import React from 'react';
import StyledTabItem from './TabItem.styles';

const TabItem = ({ children, ...props }) => (
  <StyledTabItem
    className={props.className}
    alt={props.alt}
    fallback={props.alt}
    src={props.alt}
    {...props}
  >
    {children}
  </StyledTabItem>
);

export default TabItem;
