import React, { useEffect } from 'react';
import { useStoreState, useStoreActions } from 'easy-peasy';
import Page from '../../components/page/Page';
import DescriptionCard from './DescriptionCard';
import TotalAbandonedOrders from './TotalAbandonedOrders';
import AbandonedOrdersTable from './AbandonedOrdersTable';
import ErrorScreen from '../../components/error-screen/ErrorScreen';
import config from '../../config/config';

const AbandonedOrders = () => {
  const {
    error, loading, originalData, abandonedOrders,
  } = useStoreState((state) => state.abandonedOrders);
  const { personId, customerNo } = useStoreState((state) => state.main);
  const { fetchAbandonedOrders } = useStoreActions((state) => state.abandonedOrders);

  useEffect(() => {
    const indentifier = config.general.company === 'natura' ? customerNo : personId;
    if (!indentifier) return;
    fetchAbandonedOrders({ indentifier });
  }, [customerNo, personId]);

  function refetchAbandoned() {
    const indentifier = config.general.company === 'natura' ? customerNo : personId;
    fetchAbandonedOrders({ indentifier });
  }

  return (
    <>
      <Page>
        {error && <ErrorScreen payload={personId} action={refetchAbandoned} />}
        {!error && (
        <>
          <DescriptionCard />
          <TotalAbandonedOrders loading={loading} data={originalData} />
          <AbandonedOrdersTable loading={loading} data={abandonedOrders} />
        </>
        )}
      </Page>
    </>
  );
};

export default AbandonedOrders;
