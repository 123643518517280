import React, { useContext } from 'react';
import { makeStyles, TextField } from '@naturacosmeticos/natds-web';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { useForm } from 'react-hook-form';
import Card from '../../components/card/Card';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import { I18nContext } from '../../config/i18n';
import Icon from '../../components/icon/Icon';
import Switch from '../../components/switch/Switch';
import Button from '../../components/button/Button';
import { pushInteraction } from '../../data-layer/interaction';

const useStyles = makeStyles(() => ({
  textField: {
    '& .MuiFormControl-root': {
      flex: '1',
      width: '100%',
    },
  },
}));

const SearchCard = () => {
  const { translate } = useContext(I18nContext);
  const { filter } = useStoreState((state) => state.customers);
  const classes = useStyles();
  const { register, handleSubmit } = useForm();
  const { filterCustomers, setFilter } = useStoreActions((actions) => actions.customers);

  function handleFilter({ searchValue }) {
    setFilter({ ...filter, search: searchValue });
    filterCustomers();
    pushInteraction('search', 'customer');
  }
  function handleBirthdays(event) {
    setFilter({ ...filter, birthday: event.target.checked });
    filterCustomers();
    pushInteraction('filter', 'customer', 'birthday');
  }

  return (
    <>
      <Card>
        <FlexBox padding="10px 0" alignItems="center">
          <FlexBox alignItems="center">
            <Icon name="outlined-social-addcontact" color="primary" size="small" />
            <Typography padding="0 0 0 10px">{translate('customers.my.customers')}</Typography>
          </FlexBox>
          <FlexBox justifyContent="flex-end" alignItems="center">
            <Icon name="outlined-social-birthday" color="primary" size="small" />
            <Switch checked={filter.birthday} onClick={handleBirthdays} />
          </FlexBox>
        </FlexBox>
        <Typography padding="5px 0">{translate('customers.quick.search')}</Typography>
        <Typography padding="5px 0" color="textSecondary" variant="caption">{translate('customers.name')}</Typography>
        <form onSubmit={handleSubmit(handleFilter)}>
          <FlexBox className={classes.textField} alignItems="center">
            <TextField
              {...register('searchValue')}
              style={{ marginRight: '10px', flex: '1' }}
              placeholder={translate('customers.input.search')}
              rows={1}
              size="semiX"
            />
            <Button
              variant="contained"
              size="huge"
              color="primary"
              style={{ marginLeft: '10px' }}
              onClick={handleSubmit(handleFilter)}
            >
              {translate('search')}
            </Button>
          </FlexBox>
        </form>
      </Card>
    </>
  );
};

export default SearchCard;
