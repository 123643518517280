import React, { useState } from 'react';
import { Tooltip } from '@naturacosmeticos/natds-web';

const TooltipComponent = ({ title, children }) => {
  const [show, setShow] = useState(false);

  return (
    <Tooltip
      title={title}
      open={show}
      onOpen={() => setShow(true)}
      onClose={() => setShow(false)}
      arrow
    >
      {children}
    </Tooltip>
  );
};

export default TooltipComponent;
