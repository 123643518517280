import React, { useContext } from 'react';
import Card from '../../components/card/Card';
import { I18nContext } from '../../config/i18n';
import Typography from '../../components/typography/Typography';
import FlexBox from '../../components/flex-box/FlexBox';
import { descriptionCardStyle } from './DescriptionCard.style';

const DescriptionCard = () => {
  const { translate } = useContext(I18nContext);
  const { dot } = descriptionCardStyle();
  const list = [
    translate('abandoned_orders.description_4'),
    translate('abandoned_orders.description_5'),
    translate('abandoned_orders.description_6'),
    translate('abandoned_orders.description_7'),
  ];
  return (
    <>
      <Card>
        <Typography variant="h5">{translate('abandoned_orders.description_card_title')}</Typography>
        <Typography>{translate('abandoned_orders.description_1')}</Typography>
        <Typography>{translate('abandoned_orders.description_2')}</Typography>
        <Typography>{translate('abandoned_orders.description_3')}</Typography>
        <FlexBox m="10px 0 0 15px" flexDirection="column">
          {list.map((item) => (
            <FlexBox key={item} alignItems="center" m="2px 0">
              <div className={dot} />
              <Typography>{item}</Typography>
            </FlexBox>
          ))}
        </FlexBox>
      </Card>
    </>
  );
};

export default DescriptionCard;
