import React, {
  memo, useContext, useCallback, useState,
} from 'react';
import { useStoreActions } from 'easy-peasy';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { TextField } from '@naturacosmeticos/natds-web';
import { ForgotPasswordStyle } from './ForgotPassword.style';
import Typography from '../../../components/typography/Typography';
import FlexBox from '../../../components/flex-box/FlexBox';
import { I18nContext } from '../../../config/i18n';
import Button from '../../../components/button/Button';
import Card from '../../../components/card/Card';
import { required, validateEmail } from '../../../utils/form-utils';
import ConfirmationDialog from './ConfirmationDialog';

const ForgotPassword = () => {
  const { pathname } = useLocation();
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { requestBffPasswordReset } = useStoreActions(
    (actions) => actions.main,
  );
  const {
    control, handleSubmit, watch, reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: {
      email: '',
    },
  });
  const classes = ForgotPasswordStyle();
  const { translate } = useContext(I18nContext);
  const history = useHistory();

  const onSubmit = useCallback((data) => {
    requestBffPasswordReset({ email: data.email });
    setIsDialogOpen(true);
  }, []);

  const handleDialogClose = useCallback(() => {
    setIsDialogOpen(false);
    reset({
      email: '',
    });
    history.push('/login');
  }, []);

  const email = watch('email');

  return (
    <>
      <FlexBox
        className={classes.container}
        flexDirection="column"
        justifyContent="center"
      >
        <Card p="20px" border="none">
          <div>
            {pathname === '/first-access' ? (
              <>
                <Typography variant="h5">
                  {translate('forgotPassword.my_first_access')}
                </Typography>
                <Typography m="20px 0">
                  {translate('forgotPassword.my_first_access_subtitle')}
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h5">
                  {translate('forgotPassword.title')}
                </Typography>
                <Typography m="20px 0">
                  {translate('forgotPassword.subtitle')}
                </Typography>
              </>
            )}
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <Controller
              rules={{
                required: required.message,
                pattern: validateEmail,
              }}
              control={control}
              name="email"
              render={({ field, fieldState }) => (
                <>
                  <Typography p="10px 0" error={fieldState.error}>
                    {translate('forgotPassword.label')}
                  </Typography>
                  <TextField
                    {...field}
                    state={fieldState.error ? 'error' : undefined}
                    style={{ marginRight: '10px' }}
                    placeholder={translate('forgotPassword.placeholder')}
                    helpText={
                      fieldState.error && translate(fieldState.error.message)
                    }
                  />
                </>
              )}
            />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              type="submit"
            >
              {translate('forgotPassword.button')}
            </Button>
          </form>
        </Card>
      </FlexBox>
      <ConfirmationDialog
        isOpen={isDialogOpen}
        email={email}
        handleClose={handleDialogClose}
      />
    </>
  );
};

export default memo(ForgotPassword);
