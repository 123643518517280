import { makeStyles } from '@naturacosmeticos/natds-web';

export const infoPopoverStyle = makeStyles(({ color }) => ({
  infoButton: {
    backgroundColor: 'transparent',
    border: 'none',
    color: color.link,
    cursor: 'pointer',
    padding: '0',
    margin: '0 7px',
    display: 'flex',
    alignItems: 'center',
  },
  closePopover: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: 'transparent',
    zIndex: '9',
    width: '100%',
    border: 'none',
  },
  popover: {
    zIndex: '10',
  },
}));
