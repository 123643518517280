import React from 'react';
import StyledLogo from './Logo.styles';

const Logo = ({ children, ...props }) => (
  <StyledLogo
    {...props}
  >
    {children}
  </StyledLogo>
);

export default Logo;
