import React, { useState, useEffect } from 'react';
import { useStoreState } from 'easy-peasy';
import { Snackbar } from '@naturacosmeticos/natds-web';
import { Alert } from '@material-ui/lab';

const autoHideDuration = 4000;
const AlertComponent = () => {
  const [visible, setVisible] = useState(false);
  const [isMobile] = useState(window.innerWidth < 1080);
  const { showAlert } = useStoreState((actions) => actions.main);

  useEffect(() => {
    if (showAlert && showAlert.message) {
      setVisible(true);
    }
  }, [showAlert]);

  return (
    <>
      <Snackbar
        open={visible}
        anchorOrigin={{
          horizontal: isMobile ? 'center' : 'right',
          vertical: 'bottom',
        }}
        autoHideDuration={autoHideDuration}
        message={{}}
        onClose={() => setVisible(false)}
        onEnter={function noRefCheck() {}}
        onEntered={function noRefCheck() {}}
        onEntering={function noRefCheck() {}}
        onExit={function noRefCheck() {}}
        onExited={function noRefCheck() {}}
        onExiting={function noRefCheck() {}}
      >
        <Alert
          style={{ width: 'auto', maxWidth: '400px' }}
          severity={showAlert && showAlert.type ? showAlert.type : 'info'}
          onClose={() => setVisible(false)}
        >
          {showAlert && showAlert.message ? showAlert.message : ''}

        </Alert>
      </Snackbar>
    </>
  );
};

export default AlertComponent;
