export const pushDataLayerError = (local, message) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'dataLayerError',
      errorLocation: local,
      errorMessage: message,
    });
  } catch (e) {
    console.error('Receive a error trying to send a data layer error report: ', e);
  }
};
