import { pushDataLayerError } from './reportError';

export const pushVirtualPageView = (pathname) => {
  try {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'virtualPageView',
      pathname,
    });
  } catch (e) {
    pushDataLayerError('virtualPageView', e);
  }
};
