import React, { useContext, useState } from 'react';
import { DatePicker } from '@material-ui/pickers';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { I18nContext } from '../../config/i18n';
import FlexBox from '../../components/flex-box/FlexBox';
import Typography from '../../components/typography/Typography';
import { filterBarStyle } from './FilterBar.style';
import { formatDateTimeIso } from '../../utils/date-utils';
import Button from '../../components/button/Button';
import Icon from '../../components/icon/Icon';
import Intro from '../../components/intro/Intro';
import Drawer from '../../components/drawer/Drawer';
import { pushInteraction } from '../../data-layer/interaction';
import { modelDateInterval } from '../../data-layer/modeling';

function FilterBar() {
  const { translate } = useContext(I18nContext);
  const [initialDate, setInitialDate] = useState(new Date());
  const [finalDate, setFinalDate] = useState(new Date());
  const [openDrawer, setOpenDrawer] = useState(false);
  const classes = filterBarStyle();
  const { filter } = useStoreState((state) => state.customers);
  const { filterCustomers, setFilter } = useStoreActions((actions) => actions.customers);
  const dateFormat = translate('format.date');

  function applyPeriod() {
    if (initialDate.getFullYear() !== finalDate.getFullYear() || initialDate > finalDate) return;
    setFilter({
      ...filter,
      period: {
        initialDate, finalDate,
      },
    });
    filterCustomers();
    pushInteraction('filter', 'customer', modelDateInterval(initialDate, finalDate));
  }
  function clear() {
    setFilter({ ...filter, period: null });
    filterCustomers();
    pushInteraction('filter', 'customer', 'clear');
  }

  return (
    <>
      <FlexBox>
        <FlexBox p="0 0 0 10px" alignItems="center" flex="1">
          {filter.period
            && `${translate('customers.filter_bar.period-label')}
             ${formatDateTimeIso(filter.period.initialDate, dateFormat)}
              - ${formatDateTimeIso(filter.period.finalDate, dateFormat)}`}
          {filter.period && (
          <Button className={classes.clearBtn} onClick={clear}>
            <Icon size="small" name="outlined-navigation-close" />
          </Button>
          )}
        </FlexBox>
        <Button onClick={() => setOpenDrawer(!openDrawer)}>
          <Icon name="outlined-action-filter" />
        </Button>
      </FlexBox>
      <Drawer onClose={() => setOpenDrawer(false)} anchor="right" open={openDrawer}>
        <FlexBox width="220px" maxWidth="220px" p="10px 0" flexDirection="column">
          <Intro title={translate(('customers.filter.label'))} />
        </FlexBox>
        <FlexBox width="220px" flexDirection="column" p="10px 10px">
          <Typography>{translate('customers.filter_bar.period_label')}</Typography>
          <FlexBox p="10px 5px">
            <DatePicker
              format="dd/MM/yyyy"
              className={initialDate.getFullYear() !== finalDate.getFullYear() && classes.periodButton}
              label={translate('customers.filter_bar.from')}
              value={initialDate}
              onChange={(date) => setInitialDate(date)}
              helperText={initialDate.getFullYear() !== finalDate.getFullYear()
                && translate('customers.filter_bar.from_error')}
            />
          </FlexBox>
          <FlexBox p="10px 5px">
            <DatePicker
              format="dd/MM/yyyy"
              className={initialDate > finalDate && classes.periodButton}
              label={translate('customers.filter_bar.to')}
              value={finalDate}
              onChange={(date) => setFinalDate(date)}
              helperText={initialDate > finalDate
                    && translate('customers.filter_bar.to_error')}
            />
          </FlexBox>
          <Button
            variant="contained"
            onClick={applyPeriod}
            disabled={initialDate === finalDate}
            className={classes.bottomButton}
            color="primary"
          >
            {translate('customers.filter_bar.apply-label')}
          </Button>
        </FlexBox>
        <div className={classes.bottomButtonHolder}>
          <Button color="primary" variant="contained" onClick={clear} className={classes.bottomButton}>
            {translate('customers.filter_bar.clear-label')}
          </Button>
        </div>
      </Drawer>
    </>
  );
}

export default FilterBar;
