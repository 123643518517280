import React from 'react';
import StyledDialogTitle from './DialogTitle.styles';

const DialogTitle = ({ children, ...props }) => (
  <StyledDialogTitle
    {...props}
    className={props.className}
    alt={props.alt}
    fallback={props.alt}
    src={props.alt}
    props={props}
  >
    {children}
  </StyledDialogTitle>
);

export default DialogTitle;
