import React from 'react';

function Callback() {
  return (
    <div className="container">
      <h4>Loading...</h4>
    </div>
  );
}

export default Callback;
