import { action, computed, thunk } from 'easy-peasy';
import fetcher from '../../api/fetcher';
import { sameMonth, betweenDates } from '../../utils/date-utils';
import { generateHash } from '../../utils/encrypt-utils';

const initialState = {
  customers: [],
  originalData: [],
  customer: {},
  loading: false,
  error: false,
  filter: {
    birthday: false,
    search: null,
    period: null,
  },
};

const customersModel = {
  ...initialState,
  resetStoreCustomers: action((state) => {
    Object.assign(state, initialState);
  }),
  addCustomers: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.customers = payload;
    // eslint-disable-next-line no-param-reassign
    state.loading = false;
  }),

  addOriginalData: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.originalData = payload;
  }),

  setLoading: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.loading = payload;
  }),

  filterBirthdaysByPeriod: action((state, payload) => {
    if (payload) {
      // eslint-disable-next-line no-param-reassign
      state.customers = state.originalData.filter(
        (item) => betweenDates(new Date(item.birthDate), payload.initialDate, payload.finalDate),
      );
    } else {
      // eslint-disable-next-line no-param-reassign
      state.customers = state.originalData;
    }
  }),

  setError: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.error = payload;
  }),

  customersCount: computed((state) => state.customers.length),

  setFilter: action((state, payload) => {
    // eslint-disable-next-line no-param-reassign
    state.filter = payload;
  }),

  // eslint-disable-next-line no-unused-vars
  fetchCustomers: thunk(async (actions, payload) => {
    const { personId } = payload;
    actions.setLoading(true);
    actions.setError(false);
    fetcher().get(`customers?personId=${personId}`, {
      headers: {
        personId,
        cnId: generateHash(personId),
      },
    }).json()
      .then((data) => {
        actions.addOriginalData(data.customer);
        actions.addCustomers(data.customer);
      })
      .catch((err) => {
        actions.setError(true);
        console.error('error: -> ', err.message);
      });
  }),

  filterCustomers: action((state) => {
    // eslint-disable-next-line no-param-reassign
    state.customers = state.originalData;
    const { search, birthday, period } = state.filter;
    if (search) {
      // eslint-disable-next-line no-param-reassign
      state.customers = state.customers.filter((item) => {
        if (!item.fullName) return false;
        return (item.fullName.toUpperCase().indexOf(search.toUpperCase()) > -1);
      });
    }
    if (birthday) {
      // eslint-disable-next-line no-param-reassign
      state.customers = state.customers.filter((item) => sameMonth(item.birthDate, new Date()));
    }
    if (period) {
      // eslint-disable-next-line no-param-reassign
      state.customers = state.customers.filter(
        (item) => betweenDates(new Date(item.birthDate), period.initialDate, period.finalDate),
      );
    }
  }),
};

export default customersModel;
