import React from 'react';
import {
  Toolbar, AppBarHighlight, AppBar,
} from '@naturacosmeticos/natds-web';
import { Link } from 'react-router-dom';
import ProfilePopover from './profile-popover/ProfilePopover';
import DropdownLanguages from './dropdown-languages/DropdownLanguages';
import Icon from '../icon/Icon';
import Button from '../button/Button';
import Logo from '../logo/Logo';
import { pushInteraction } from '../../data-layer/interaction';
import Alerts from './alerts';

const Header = ({ menuActive, setMenuActive, hasUser }) => (
  <AppBar style={{ zIndex: '1300' }} color="default" position="fixed">
    <Toolbar style={{ padding: hasUser ? '0px 25px 0px 0px' : '0px 15px 0px 20px' }}>
      <AppBarHighlight style={{ display: 'flex' }}>
        {hasUser && (
          <Button onClick={() => setMenuActive(!menuActive)}>
            <Icon
              name="outlined-navigation-menu"
              size="small"
            />
          </Button>
        )}
        <Link to="/" onClick={() => pushInteraction('navigation', 'header logo')}>
          <Logo size="hugexx" color="primary" />
        </Link>
      </AppBarHighlight>
      {hasUser && <Alerts />}
      <DropdownLanguages />
      {hasUser && <ProfilePopover />}
    </Toolbar>
  </AppBar>
);

export default Header;
