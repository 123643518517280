import React from 'react';
import { Link } from '@naturacosmeticos/natds-web';

const LinkComponent = ({ children, ...props }) => (
  <Link
    ref={props.btnref}
    {...props}
  >
    {children}
  </Link>
);

export default LinkComponent;
