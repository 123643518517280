import { Card } from '@naturacosmeticos/natds-web';
import { styled } from '@material-ui/core/styles';

const StyledCard = styled(Card)({
  padding: ({ props }) => props.p || props.padding || '16px',
  paddingTop: ({ props }) => props.p || props.paddingTop || '16px',
  flex: 1,
  border: ({ props, theme }) => props.border || `1px solid ${theme.color.lowEmphasis}`,
  margin: ({ props }) => props.m || props.margin || '10px',
  width: ({ props }) => props.width,
  maxWidth: ({ props }) => props.maxWidth,
  minHeight: ({ props }) => props.minHeight,
  height: ({ props }) => props.height,
  position: ({ props }) => props.position,
  overflow: ({ props }) => props.overflow,
  alignItems: ({ props }) => props.alignItems,
  justifyContent: ({ props }) => props.justifyContent,
  boxShadow: ({ props }) => (props.noShadow ? 'unset' : '0 1px 1px 0 rgb(0 0 0 / 14%)'),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: ({ props }) => props.backgroundColor,
});

export default StyledCard;
