import React, { useEffect, useContext, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import { I18nContext } from '../../config/i18n';
import Page from '../../components/page/Page';
import { formatDateTimeIso } from '../../utils/date-utils';
import Resume from './Resume';
import SalesData from './SalesData';
import ErrorScreen from '../../components/error-screen/ErrorScreen';
import { pushInteraction } from '../../data-layer/interaction';
import { modelDateInterval } from '../../data-layer/modeling';

const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

const Sales = () => {
  const { fetchSales, setFilter } = useStoreActions((actions) => actions.sales);
  const { error, filter, firstLoad } = useStoreState((state) => state.sales);
  const { personId } = useStoreState((state) => state.main);
  const { translate } = useContext(I18nContext);
  const formatDate = translate('format.date');
  const initialDateFormated = formatDateTimeIso(new Date(localStorage.getItem('initialDate')).getTime(), formatDate);
  const finalDateFormated = formatDateTimeIso(new Date(localStorage.getItem('finalDate')).getTime(), formatDate);
  const finalFormat = formatDateTimeIso(today.getTime(), formatDate);
  const initialFormat = formatDateTimeIso(today.getTime() - (86400000 * 30), formatDate);
  // eslint-disable-next-line max-len
  const [storedInitialDate] = useState(localStorage.getItem('initialDate') ? initialDateFormated : initialFormat);
  // eslint-disable-next-line max-len
  const [storedFinalDate] = useState(localStorage.getItem('finalDate') ? finalDateFormated : finalFormat);

  function action() {
    if (!personId) return;
    fetchSales({
      personId,
      initialDate: storedInitialDate,
      finalDate: storedFinalDate,
    });
  }

  useEffect(() => {
    if (!personId) return;

    const savedInitialDate = localStorage.getItem('initialDate');
    const savedFinalDate = localStorage.getItem('finalDate');

    if (savedInitialDate && savedFinalDate) {
      const initialDate = formatDateTimeIso(new Date(savedInitialDate).getTime(), formatDate);
      const finalDate = formatDateTimeIso(new Date(savedFinalDate).getTime(), formatDate);
      // eslint-disable-next-line max-len
      setFilter({ ...filter, period: { initialDate: new Date(savedInitialDate), finalDate: new Date(savedFinalDate) } });

      const shouldFetchSales = (
        initialDate !== storedInitialDate
        || finalDate !== storedFinalDate
      );

      if (shouldFetchSales || firstLoad) {
        fetchSales({ initialDate, finalDate, personId });
        pushInteraction('filter', 'order', modelDateInterval(initialDate, finalDate));
      }
    } else if (!storedInitialDate || !storedFinalDate) {
      fetchSales({ initialDate: storedInitialDate, finalDate: storedFinalDate, personId });
      pushInteraction('filter', 'order', modelDateInterval(storedInitialDate, storedFinalDate));
    }
  }, [personId]);

  return (
    <>
      <Page>
        {error && <ErrorScreen action={action} />}
        {!error && (
        <>
          <Resume />
          <SalesData reloadFunction={action} />
        </>
        )}

      </Page>
    </>
  );
};

export default Sales;
