import { Container } from '@naturacosmeticos/natds-web';
import { styled } from '@material-ui/core/styles';

const StyledPage = styled(Container)({
  display: 'flex',
  flexDirection: 'column',
  padding: window.innerWidth < 1080 ? 10 : 20,
  maxWidth: 'unset',
});

export default StyledPage;
