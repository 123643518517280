import React, { useEffect, useState } from 'react';
import { useStoreActions, useStoreState } from 'easy-peasy';
import Page from '../../components/page/Page';
import Presentation from './Presentation';
import KpiList from './KpiList';
import Charts from './Charts';
import ErrorScreen from '../../components/error-screen/ErrorScreen';
import { formatDateIsoToFetch, getAWeekBeforeFrom } from '../../utils/date-utils';

const Visits = () => {
  const { fetchVisits } = useStoreActions((state) => state.visits);
  const [flag, setFlag] = useState(false);
  const { personId } = useStoreState((state) => state.main);
  const {
    visits, loading, error,
  } = useStoreState((state) => state.visits);
  useEffect(() => {
    if (!personId) return;
    if (flag) return;
    setFlag(true);
    fetchVisits({
      personId,
      endDate: formatDateIsoToFetch(new Date()),
      startDate: formatDateIsoToFetch(getAWeekBeforeFrom(new Date())),
    });
  }, [personId, flag]);

  function fetchVisitsOnError() {
    fetchVisits({
      personId,
      endDate: formatDateIsoToFetch(new Date()),
      startDate: formatDateIsoToFetch(getAWeekBeforeFrom(new Date())),
    });
  }

  return (
    <>
      <Page>
        {error && <ErrorScreen action={fetchVisitsOnError} />}
        {!error && (
        <>
          <Presentation />
          <KpiList loading={loading} dataBase={visits} />
          <Charts loading={loading} dataBase={visits} />
        </>
        )}

      </Page>
    </>
  );
};

export default Visits;
