import React from 'react';
import StyledTextField from './TextField.styles';

const TextField = ({ children, ...props }) => (
  <StyledTextField
    {...props}
  >
    {children}
  </StyledTextField>
);

export default TextField;
