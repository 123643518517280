/* eslint-disable max-len */
import React, { useContext } from 'react';
import { makeStyles } from '@naturacosmeticos/natds-web';
import { useTheme } from '@material-ui/styles';
import { I18nContext } from '../../../config/i18n';
import Card from '../../../components/card/Card';
import Typography from '../../../components/typography/Typography';
import ResumeTable from './ResumeTable';
import FlexBox from '../../../components/flex-box/FlexBox';
import InfoPopover from '../../../components/info-popover/InfoPopover';
import { formatCurrency } from '../../../utils/numbers-utils';

const useStyles = makeStyles((theme) => ({
  styledCard: {
    backgroundColor: theme.color.background,
  },
}));

const OrderResume = ({ data }) => {
  const { translate } = useContext(I18nContext);
  const { styledCard } = useStyles();
  const { color } = useTheme();
  const discountValue = Math.abs((data.subtotaProductlValue - data.cnValue - data.cnProfitValue) * (-1));
  const totalValueUpdate = Math.abs((data.subtotaProductlValue - discountValue + data.freightValue));
  return (
    <Card m="5px 0" className={styledCard} noShadow>
      <Typography variant="h5">{translate('sales.customer_history.title')}</Typography>
      <ResumeTable data={data} />
      <FlexBox alignItems="flex-end" flexDirection="column">
        <FlexBox m="5px 0" justifyContent="space-between" mw="400px">
          <Typography color="textSecondary">{translate('sales.order_resume.detail.subtotal')}</Typography>
          <Typography>{formatCurrency(data.subtotaProductlValue)}</Typography>
        </FlexBox>
        <FlexBox m="5px 0" p="0 0 0 0px" justifyContent="space-between" mw="400px">
          <Typography m="0 10px" color="textSecondary">{translate('sales.order_resume.detail.products')}</Typography>
          <Typography color="textSecondary">{formatCurrency(data.cnValue)}</Typography>
        </FlexBox>
        <FlexBox m="5px 0" p="0 0 0 0px" justifyContent="space-between" mw="400px">
          <Typography m="0 10px" color="textSecondary">{translate('sales.order_resume.detail.discount')}</Typography>
          <Typography color="textSecondary">{formatCurrency(discountValue)}</Typography>
        </FlexBox>
        <FlexBox m="5px 0" p="0 0 0 0px" justifyContent="space-between" mw="400px">
          <FlexBox>
            <Typography m="0 10px" color="textSecondary">{translate('sales.order_resume.detail.consultancy')}</Typography>
            <InfoPopover text={translate('sales.order_resume.detail.consultancy_info')} />
          </FlexBox>
          <Typography whiteSpace="nowrap" color="textSecondary">{formatCurrency(data.cnProfitValue)}</Typography>
        </FlexBox>
        {data.cnGratification && (
        <FlexBox m="5px 0" justifyContent="space-between" mw="400px">
          <FlexBox>
            <Typography color="textSecondary">{translate('sales.order_resume.detail.gratification')}</Typography>
            <InfoPopover text={translate('sales.order_resume.detail.gratification_info')} />
          </FlexBox>
          <Typography whiteSpace="nowrap">{formatCurrency(data.cnGratification)}</Typography>
        </FlexBox>
        )}
        <FlexBox m="7px 0" justifyContent="space-between" mw="400px">
          <Typography color="textSecondary">{translate('sales.order_resume.detail.shipping')}</Typography>
          <Typography>
            {
            data.freightValue <= 0
              ? translate('sales.order_resume.detail.free_shipping') : formatCurrency(data.freightValue)
            }
          </Typography>
        </FlexBox>
        <FlexBox
          p="10px 0 0 0"
          borderTop={`1px solid ${color.mediumEmphasis}`}
          justifyContent="space-between"
          mw="400px"
        >
          <Typography variant="h5">{translate('sales.order_resume.detail.total')}</Typography>
          <Typography variant="h5">{formatCurrency(totalValueUpdate)}</Typography>
        </FlexBox>
        {data.orderEconomy > 0 && (
        <FlexBox justifyContent="space-between" mw="400px">
          <FlexBox>
            <Typography c={color.success}>{translate('sales.order_resume.detail.economy')}</Typography>
            <InfoPopover text={translate('sales.order_resume.detail.economy_info')} />
          </FlexBox>
          <Typography whiteSpace="nowrap" c={color.success}>{formatCurrency(data.orderEconomy)}</Typography>
        </FlexBox>
        )}
      </FlexBox>
    </Card>
  );
};

export default OrderResume;
