export const validateEmail = {
  value: /^[A-Z0-9._%-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
  message: 'email.invalid',
};

export const validateLinkFacebook = {
  // eslint-disable-next-line no-useless-escape
  value: /(?:http:\/\/)?(?:www\.)?facebook\.com\/(?:(?:\w)*#!\/)?(?:pages\/)?(?:[\w\-]*\/)*([\w\-]*)/,
  message: 'digital_space.social_tab.link_facebook.invalid',
};

export const validateLinkInstagram = {
  // eslint-disable-next-line no-useless-escape
  value: /(?:(?:http|https):\/\/)?(?:www\.)?(?:instagram\.com|instagr\.am)\/([A-Za-z0-9-_\.]+)/im,
  message: 'digital_space.social_tab.instagran.invalid',
};

export const validateLinkYoutube = {
  // eslint-disable-next-line no-useless-escape
  value: /(?:https|http)\:\/\/(?:[\w]+\.)?youtube\.com\/(?:c\/|channel\/|user\/)?([a-zA-Z0-9\-]{1,})/,
  message: 'digital_space.social_tab.youtube.invalid',
};

export const required = {
  message: 'required',
};
