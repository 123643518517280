import { makeStyles } from '@naturacosmeticos/natds-web';

export const descriptionCardStyle = makeStyles((theme) => ({
  dot: {
    width: '7px',
    height: '7px',
    backgroundColor: () => theme.color.highEmphasis,
    borderRadius: '50px',
    margin: '0 5px',
  },
}));
