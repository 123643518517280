import config from '../../config/config';

function setSession(props) {
  props.history.replace('/');
}

export function handleAuthentication(props, handleLogin, handleNaturaLogin) {
  console.log('handle auth');
  const param = props.location.search || props.location.hash;

  if (param?.startsWith(config.auth.callbackParam)) {
    const ssoToken = param.replace(config.auth.callbackParam, '');
    sessionStorage.setItem('sso_token', ssoToken);
    if (config.auth.loginConfig === 'natura_login') {
      handleNaturaLogin(ssoToken);
    } else {
      handleLogin();
    }
    setSession(props, ssoToken);
  } else {
    props.history.replace('/login');
    console.log('Login error...');
  }
}
