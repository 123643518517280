import React, { useContext } from 'react';
import { useHistory } from 'react-router-dom';

import Button from '../button/Button';
import Typography from '../typography/Typography';
import { I18nContext } from '../../config/i18n';
import FlexBox from '../flex-box/FlexBox';
import Icon from '../icon/Icon';

function GoBackBtn({ title, to, execFunctionBeforeBackToPage }) {
  const history = useHistory();
  const { translate } = useContext(I18nContext);

  function handleClick() {
    if (execFunctionBeforeBackToPage && typeof execFunctionBeforeBackToPage === 'function') {
      execFunctionBeforeBackToPage();
    }

    if (to) {
      history.push(to);
      return;
    }
    history.goBack();
  }

  return (
    <>
      <FlexBox p="0 10px">
        <Button onClick={handleClick}>
          <Icon size="tiny" name="outlined-navigation-directionright" />
          <Typography p="0 0 0 10px">{title || translate('go_back_button')}</Typography>
        </Button>
      </FlexBox>
    </>
  );
}

export default GoBackBtn;
