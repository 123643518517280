import React, { useContext } from 'react';
import { makeStyles } from '@naturacosmeticos/natds-web';
import { I18nContext } from '../../../config/i18n';
import DataTable from '../../../components/datatable/DataTable';
import FlexBox from '../../../components/flex-box/FlexBox';
import Typography from '../../../components/typography/Typography';
import { formatCurrency } from '../../../utils/numbers-utils';

const URLBase = process.env.REACT_APP_URL_BASE_IMAGE;

const useStyles = makeStyles((theme) => ({
  styledCard: {
    backgroundColor: theme.color.background,
  },
}));

const ResumeTable = ({ data }) => {
  const { translate } = useContext(I18nContext);
  const { styledCard } = useStyles();

  const columns = [
    {
      field: 'productName',
      headerName: translate('sales.order_resume.table.header_product'),
      sortable: false,
      minWidth: 250,
      disableColumnMenu: true,
      renderCell: ({ row }) => (
        <FlexBox>
          <FlexBox justifyContent="center" minWidth="70px">
            <img
              width="70px"
              alt={row.productName}
              src={URLBase + row.imageUrl}
            />
          </FlexBox>
          <FlexBox p="5px" whiteSpace="normal" alignItems="center" justifyContent="center" flexDirection="column">
            <Typography variant="h7" lineHeight="1.2">{row.productName}</Typography>
          </FlexBox>
        </FlexBox>
      ),
    },
    {
      field: 'productQuantity',
      headerName: translate('sales.order_resume.table.header_quantity'),
      minWidth: 135,
      type: 'number',
      disableColumnMenu: true,
      renderCell: ({ row }) => row.productQuantity,
    },
    {
      field: 'unitProductPrice',
      headerName: translate('sales.order_resume.table.header_unity_value'),
      disableColumnMenu: true,
      type: 'number',
      minWidth: 150,
      renderCell: ({ row }) => formatCurrency(row.unitProductPrice),
    },
    {
      field: 'discountValue',
      headerName: translate('sales.order_resume.table.header_discount'),
      sortable: false,
      minWidth: 130,
      type: 'number',
      disableColumnMenu: true,
      renderCell: ({ row }) => formatCurrency(row.discountValue),
    },
    {
      field: 'productValue',
      headerName: translate('sales.order_resume.table.header_total_value'),
      sortable: false,
      minWidth: 130,
      type: 'number',
      disableColumnMenu: true,
      renderCell: ({ row }) => formatCurrency(row.productValue),
    },
  ];
  return (
    <FlexBox flexDirection="column" m="0" className={styledCard} p="0" maxWidth={false} noShadow>
      <DataTable
        rowId="productName"
        dataset={data.items}
        columns={columns}
        tableMargin="0"
      />
    </FlexBox>
  );
};

export default ResumeTable;
